<template>
  <div>
    <button class="return-button" @click="goBack">Retour</button>
    <TitleComponent :Title="Title" />
    <DiagramComponent
      :format="Format"
      :nodes="nodes"
      :linkData="linkData"
      :componentList="componentList"
    />
  </div>
</template>
    
  <script>
import DiagramComponent from "@/components/Diagram.vue";
import TitleComponent from "@/components/TitlePage.vue";

export default {
  name: "ContactView",
  data() {
    return {
      Format: "vertical",

      Title: {
        mainTitle: "Cadre Légal et Réglementaire",
        subTitle: "Chartes et Conventions",
        subSubTitle: "Processus: Partenariat",
        supervisor: "Présidence, Secrétaire général, Trésorier",
      },

      // Exemple de données pour les nœuds
      nodes: [
        {
          key: "0",
          text: "Volonté de développement des relations extérieurs ",
          type: "Start",
        },
        {
          key: "1",
          text: "Identification des objectifs",
          component: "0",
        },
        {
          key: "2",
          text: "Recherche d'entreprise ou de JE possible",
          supervisor: "President",
        },
        {
          key: "3",
          text: "Préparation d'une offre",
          component: "1",
        },
        {
          key: "4",
          text: "Prise de contact avec la stucture ciblée",
          component: "2",
        },
        {
          key: "5",
          text: "Réunion de présentation",
          link: "reunion_presentation",
          supervisor: "President",
        },
        { key: "6", text: "Négociation des modalités de la collaboration" },
        {
          key: "7",
          text: "Modification de l'offre",
          direction: "left",
          noLinkauto: true,
          supervisor: "Sec Gen",
        },
        {
          key: "8",
          text: "Signature d'un accord de partenariat",
          indPrec: 6,
          noLinkauto: true,
        },
        { key: "9", text: "Mise en oeuvre de la collaboration" },
        { key: "10", text: "Evaluation et suivi", link: "evaluation_suivi" },
        { key: "11", text: "Partenariat durable établie", type: "End" },
        {
          key: "12",
          text: "L'entreprise refuse le partenariat",
          type: "End",
          indPrec: 4,
          direction: "right",
          noLinkauto: true,
        },
        {
          key: "13",
          text: "L'entreprise refuse le partenariat",
          type: "End",
          indPrec: 6,
          direction: "right",
          noLinkauto: true,
        },
      ],

      linkData: [
        {
          from: "2",
          to: "1",
          Emplacement: 1,
          label: "Manque de pertinance des entreprises ou JE identifiées",
          bySide: true,
          Espace: "grand",
        },
        {
          from: "4",
          to: "3",
          Emplacement: 1,
          label: "Manque d'intérêt de l'entreprise ou JE ",
          bySide: true,
          Espace: "grand",
        },
        {
          from: "4",
          to: "4",
          label: "Abscence de réponse de l'entreprise ou JE",
          fromSpot: "bottom",
          toSpot: "left",
          Emplacement: 2,
        },
        {
          from: "4",
          to: "12",
          label: "Refus de l'entreprise",
          Espace: "petit",
        },
        { from: "6", to: "13", label: "Aucun accord trouvé", Espace: "petit" },
        { from: "6", to: "8", label: "Accord trouvé", Emplacement: 1 },
        {
          from: "6",
          to: "7",
          label: "Désaccord sur les modalités de la collaboration",
          Emplacement: 1,
          Espace: "petit",
        },

        {
          from: "7",
          to: "8",
          label: "Modifications acceptées",
          Espace: "petit",
        },
      ],
      componentList: [
        {
          number: "0",
          Name: "Identification des objectifs",
          infosList: {
            items: [
              " Clarifier les objectifs de la Junior Entreprise",
              " S'assurer que les objectifs sont alignés avec ceux de l'entreprise ciblé",
            ],
          },
        },
        {
          number: "1",
          Name: "Préparation d'une offre",
          infosList: {
            items: [
              " Analyser les besoins spécifiques de l'entreprise cible",
              " Mettre en avant les avantages d'un partenariat avec notre Junior-Entreprise",
              " Créer une offre claire et concise qui répond aux besoins identifiés",
            ],
            
          },
        },
        {
          number: "2",
          Name: "Préparation d'une offre",
          texteMarkdown: `Le wiki Présidence servira à la fois de Book de Passation pour les futurs Président de la Junior AEI  mais aussi de support procédurale pour quiconque souhaite se renseigner sur les actions qu'entreprend un Président de notre Junior.
# Book de Passation 


## Minimum à avoir fait avant ou juste après être entré en mandat
* [TestLien](#/clr)



## Acteurs 
• Nini – président 07-08
• Paul – président 08-09 
• Christelle – présidente 09-10 ,
Le wiki Présidence servira à la fois de Book de Passation pour les futurs Président de la Junior AEI  mais aussi de support procédurale pour quiconque souhaite se renseigner sur les actions qu'entreprend un Président de notre Junior.
# Book de Passation 


## Minimum à avoir fait avant ou juste après être entré en mandat
* [TestLien](/clr)
* [Planning de Pré-mandat](/presidence/premandat)



## Acteurs 
• Nini – président 07-08
• Paul – président 08-09 
• Christelle – présidente 09-10 `,
        },
      ],
    };
  },
  components: {
    DiagramComponent,
    TitleComponent,
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script> 