<!-- DON'T TOUCH -->
<template>
    <div>
      <button class="return-button" @click="goBack">Retour</button>
      <TitleComponent :Title="Title" />
      <DiagramComponent
        :format="Format"
        :nodes="nodes"
        :linkData="linkData"
        :componentList="componentList"
      />
    </div>
  </template>
      
  
  
    <script>
  // DON'T TOUCH
  import DiagramComponent from "@/components/Diagram.vue";
  import TitleComponent from "@/components/TitlePage.vue";
  
  export default {
    name: "PageProcessus", // Nom à changer
    data() {
      return {
        //Texte à remplir
        Title: {
          mainTitle:
            "Activité Commerciale",
          subTitle: "Avant Etude",
          subSubTitle: "Processus: Prospection",
          supervisor: "Pôle Activité Commerciale",
          derniere_modif: "01/04/2024 par Enzo CHAMPAGNE",
        },
  
        // Exemple des différents type de nœuds
        nodes: [
          {
            key: "0",
            text: 'Plusieurs moyens de prospection',
            type: "Start", // Met en vert et de forme elliptique (genre ovale)
          },
          {
            key: "1",
            text: "Démarchage physique",
          },
          {
            key: "2",
            text: "Aborder le prospect", addDecY: -50,
          },
          { key: "3", text: "L'écouter afin de percevoir/générer le besoin (questions ouvertes)", addDecY: -50 },
          {
            key: "4",
            text: "Enoncer le besoin et proposer une solution (AEI - Domaines de compétences)",
            addDecY: -50,
          },
          { key: "5", text: "Demander le contact pour donner suite à la discussion (le contacter sous 48h). \n Si très intéressé, convenir d'un RDV Client.", addDecY: -50, },
          {
            key: "6",
            text: "Démarchage téléphonique",
            link: 'call_center',
            indPrec: 1,
            direction: "left",
            noLinkauto: true,
          },
          {
            key: "7",
            text: "Avoir une idée précise de l'offre que l'on peut proposer + speech de présentation \n (Si le Client ne répond pas, laisser un message vocal)",
          },
          { key: "8", 
          text: "Démarchage via LinkedIn", indPrec: 1, direction: "right", noLinkauto: true,},
  
  
          { key: "9", text: "Message type à adapter en fonction de l'interlocuteur et du besoin"},
          { key: "10", text: "Dans le cas de l'obtention de contacts, mettre l'ensemble des informations propres au Client dans le CRM \n (Cliquer ici)",
            external_link: "https://docs.google.com/spreadsheets/d/10BR4CxbuoxZyDyqLEglg5ZUSLNEEh6c8fo7c5kfJjvk/edit#gid=0", indPrec: 5, addDecY: 100},

          {
            key: "11",
            text: "Réponse du Client",
          },

          {
            key: "12",
            text: "Convenir d'une date de RDV Client et du format du RDV si ce n'est pas déjà fait",
            noLinkauto: true,
            type: "End",
          },

          {
            key: "13",
            text: "Mise à jour du CRM",
            indPrec: 11,
            direction: "left",
            noLinkauto: true,
            type: "End",
          },

          {
            key: "14",
            text: "Relance par un autre moyen ou le même (mail, téléphone, LinkedIn)",
            indPrec: 11,
            direction: "right",
            noLinkauto: true,
          },
          {
            key: "15",
            text: "Abandon et mise à jour du CRM",
            direction: "right",
            noLinkauto: true,
            type: "End",
            addDecX: 70,
          },

        ],
  
        linkData: [
  
          {
            from: "0",
            to: "6",
          },

          {
            from: "0",
            to: "8",
          },

          {
            from: "7",
            to: "10",
          },

          {
            from: "9",
            to: "10",
          },
          {
            from: "11",
            to: "12",
            label: "Réponse \n positive",
          },

          {
            from: "11",
            to: "13",
            label: "Réponse \n négative",
          },
          {
            from: "11",
            to: "14",
            label: "Absence \n de réponse",
          },
          {
            from: "14",
            to: "15",
            label: "Réponse négative ou pas de réponse sous 48h",
          },
          {
            from: "14",
            to: "12",
            label: "Réponse positive",
          },
        ],
        componentList: [
        ],
      };
    },
    components: {
      DiagramComponent,
      TitleComponent,
    },
    methods: {
      goBack() {
        this.$router.go(-1);
      },
    },
  };
  </script> 