<!-- DON'T TOUCH -->
<template>
  <div>
    <button class="return-button" @click="goBack">Retour</button>
    <TitleComponent :Title="Title" />
    <DiagramComponent :format="Format" :nodes="nodes" :linkData="linkData" :componentList="componentList" />
  </div>
</template>



<script>
// DON'T TOUCH
import DiagramComponent from "@/components/Diagram.vue";
import TitleComponent from "@/components/TitlePage.vue";

export default {
  name: "dadsProcessus", // Nom à changer
  data() {
    return {
      //Texte à remplir
      Title: {
        mainTitle:
          "Trésorerie",
        subTitle: "Transversal",
        subSubTitle: "Processus: Actualisation du Budget et Atterissage Budgétaire",
        supervisor: "Trésorier",
        derniere_modif: "25/03/2024",
      },

      // Exemple des différents type de nœuds
      nodes: [
        {
          key: "0",
          text: 'Points de Dépense ou Produit importante changé ou payé/reçu',
          type: "Start", // Met en vert et de forme elliptique (genre ovale)
          addDecX : -300
        },
        {
          key: "1",
          text: "Changement pour les 3 hypothèses du montant de cette dépense ou ce produit (Atterissage)",
        },
        {
          key: "0",
          text: ' CA prévisionné au vu des études signées pas en cohérence avec les hypothèses',
          type: "Start", // Met en vert et de forme elliptique (genre ovale)
          direction : "right",
          indPrec : 0
        },
        {
          key: "1",
          text: "Calculer avec les CE signé le CA déjà prévu sur le mandat (ATTENTION aux cut-offs) \n A partir de cela essayer de prévoir combien on aura en fin de mandat",
          
        },
        {
          key: "1",
          text: "Modifier le CA prévisionnel des 3 hypothèses (Rappel : Basse (en dessous de l'estimation), Moyenne : l'estimation, Haute : (Au dessus de l'estimation) avec une différence logique entre chaque)",
          addDecY : 50
        },
        {
          key: "0",
          text: 'Dernière actualisation il y a plus de 2 mois',
          type: "Start", // Met en vert et de forme elliptique (genre ovale)
          direction : "right",
          indPrec : 2
        },
        {
          key: "1",
          text: "Vérifier si des postes de dépenses ont été réalisés et si oui modifié en fonction les lignes du budget sur les 3 hypothèses (Atterissage Budgétaire : Toutes les hypothèses égales à la fin)",
        },
        {
          key: "2",
          text: "Après les modifications, réajustez les produits et dépenses pour réatteindre le résultat visé avec les 3 hypothèses (Suivre la stratégie pour quand des choix sont à faire)\n Si impossible nouveau résultat à voter lors de l'actualisation ",
          supervisor: "Trésorier",
          indPrec : 4,
          addDecY : 75
        },
        {
          key: "2",
          text: "Relecture du Vice-Trésorier",
          supervisor: "Vice-Trésorier",
          component : 1,
          addDecY : 75
        },

        {
          key: "6",
          text: "Présentation de l'actualisation du Budget au prochain CA  (Conseil en cliquant)",
          component: 2,
          supervisor: "Trésorier Entrant",
        }, {
          key: "7",
          text: "Budget Validé",
          type: "End",

        },
        {
          key: "6",
          text: "Rajouter au CR de CA la page budget en PDF exporter depuis le Tableau de Suivi et l'archiver sur le Drive",
          supervisor: "Trésorier Entrant et Sec Gen ",
          
        },
        {
          key: "7",
          text: "Budget  Non Validé",
          type: "End",
          direction: "right",
          indPrec: 10,
          noLinkauto: true

        },
        {
          key: "6",
          text: "Faire les modifications",
          supervisor: "Trésorier Entrant",
          addDecY: -300
        },



      ],

      linkData: [
        {
          from: 13,
          to: 9
        },
        {
          from: 9,
          to: 12
        },
        {
          from: 1,
          to: 4
        },
        {
          from: 6,
          to: 4
        },
      ],

      componentList: [
        {

        },

        {
          number: "1",
          Name: "Choses à vérif en  tant que Vice-Tresorier",
          texteMarkdown: `**Tache importante pour permettre un bon départ du pole Entrant**`,
          textes: ["1 - Vérifier le respect des consignes (objectif égaux, lignes précises) voir Création Budget",
            "2 - Vérifier que les calculs sont égaux (pas de formule Excel cassé)",
            "3 - Surveiller que l'ensemble des dépenses nécessaires au bon fonctionnement de AEI sont présentes",
            "4 - Vérifier que ce budget est sécur et permettra une continuité financière de AEI",
          ]
        },
        {
          number: "1",
          Name: "Conseil pour présenter",
          texteMarkdown: `**Il est très important que l'ensemble du CA (respo et bureau) soient au courant de l'évolution du budget (au moins de leur pole)**`,
          textes: ["1 - Faire un diapo propre (Exemple dispo sur le Drive dans 23-24->Trésorerie)",
            "2 - Faire vraiment participer les autres membres du CA dans la discussion il est pas grave si il y a besoin de 2 CA pour actualisé le Budget, ça sera toujours mieux que de laisser le sentiment d'avoir modifié tout seul"
          ]
        },

      ],
    };
  },
  components: {
    DiagramComponent,
    TitleComponent,
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};

</script>