<!-- Copyright (C) 2023 Nesrine ABID, Nadjime BARTEAU, Mathieu DUPOUX, Léo-Paul MAZIÈRE, Maël PAUL, Antoine RAOULT, Lisa VEILLAT, Marine VOVARD

Authors: Nesrine ABID, Nadjime BARTEAU, Mathieu DUPOUX, Léo-Paul MAZIÈRE, Maël PAUL, Antoine RAOULT, Lisa VEILLAT, Marine VOVARD
Maintainer: contact@junior-aei.com

This file is part of LATIME.

LATIME is free software: you can redistribute it and/or modify it under the terms of the GNU Affero General Public License as published by the Free Software Foundation, either version 3 of the License, or (at your option) any later version.

LATIME is distributed in the hope that it will be useful, but WITHOUT ANY WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License along with LATIME. If not, see <https://www.gnu.org/licenses/>. -->

<template>
  <nav id="sidebar">
    <div >
      <PanelMenu :model="items">
        <template #item="{ item }">
          <router-link
            v-if="item.route"
            v-slot="{ href, navigate }"
            :to="item.route"
            custom
          >
            <a v-ripple class="itemCss" :href="href" @click="navigate">
              <span
                v-if="item.items"
                class="pi pi-angle-down text-primary ml-auto"
                :class="{
                  'pi-angle-down': !item.open,
                  'pi-angle-up': item.open,
                }"
              ></span>


              <span v-if="item.icon" :class="item.icon"></span>
              <img v-if="item.image" :src="item.image" class="menu-image" />


              <span class="ml-2 text-color">{{ item.label }}</span>
            </a>
          </router-link>
          <a
            v-else
            v-ripple
            class="itemCss"
            :href="item.url"
            :target="item.target"
          >
            <span :class="item.icon" />
            <span class="ml-2">{{ item.label }}</span>
            <span
              v-if="item.items"
              class="pi pi-angle-down text-primary ml-auto"
            />
          </a>
        </template>
      </PanelMenu>

      <footer class="footer-menu">
        <PanelMenu :model="links">
        <template #item="{ item }">
          <router-link
            v-if="item.route"
            v-slot="{ href, navigate }"
            :to="item.route"
            custom
          >
            <a v-ripple class="itemCss" :href="href" @click="navigate">
              <span
                v-if="item.items"
                class="pi pi-angle-down text-primary ml-auto"
                :class="{
                  'pi-angle-down': !item.open,
                  'pi-angle-up': item.open,
                }"
              ></span>


              <span v-if="item.icon" :class="item.icon"></span>
              <img v-if="item.image" :src="item.image" class="menu-image" />


              <span class="ml-2 text-color">{{ item.label }}</span>
            </a>
          </router-link>
          <a
            v-else
            v-ripple
            class="itemCss"
            :href="item.url"
            :target="item.target"
          >
            <span :class="item.icon" />
            <span class="ml-2">{{ item.label }}</span>
            <span
              v-if="item.items"
              class="pi pi-angle-down text-primary ml-auto"
            />
          </a>
        </template>
      </PanelMenu>
      </footer>
    </div>


  </nav>
</template>


<script>

export default {
  name: "PanelMenuComponent",
  props:{
    items:{
      type:Array,
      default: () => []
    },
    links:{
      type:Array,
      default: () => []
    }
  },
};
</script>

<style scoped>
#sidebar {
  flex: 1;
  background-color: #3369e7;
  color: white;
  padding-top: 20px;
  max-width: 15vw;
}
.itemCss {
  color: white;
  font-size: 1.2em; /* Adjust the font size as needed */

}

.menu-image {
  width: 20px; /* Adjust as needed */
  height: 20px; /* Adjust as needed */
  filter: invert(100%);
  /* Add any other styles as needed */
}

.footer-menu {
  position: fixed; /* ou 'sticky' */
  bottom: 0;
  left: 0;
  width: 15vw; /* couleur de fond */
  box-sizing: border-box;
  border-top: 1px solid white; /* bordure en haut */
  z-index: 100; /* s'assurer qu'il reste au-dessus des autres éléments */
  margin-bottom: 60px;
}
</style>
