<!-- DON'T TOUCH -->
<template>
    <div>
      <button class="return-button" @click="goBack">Retour</button>
      <TitleComponent :Title="Title" />
      <DiagramComponent :format="Format" :nodes="nodes" :linkData="linkData" :componentList="componentList" />
    </div>
  </template>
  
  
  
  <script>
  // DON'T TOUCH
  import DiagramComponent from "@/components/Diagram.vue";
  import TitleComponent from "@/components/TitlePage.vue";
  
  export default {
    name: "rgpd_View", // Nom à changer
    data() {
      return {
        //Texte à remplir
        Title: {
          mainTitle:
            "Gestion Associative",
          subTitle: "RGPD",
          subSubTitle: "Processus: Création / Modification d'une BDD",
          supervisor: "Secrétaire Général",
          derniere_modif: "12/04/2024 par Enzo CHAMPAGNE",
        },
  
        // Exemple des différents type de nœuds
        nodes: [
          {
            key: "0",
            text: "Création / Modification d'une Base de Données",
            type: "Start", // Met en vert et de forme elliptique (genre ovale)
          },
          {
            key: "1",
            text: "Créer / Actualiser la Base de Données",
            supervisor: "Responsable du pôle concerné"
          },
          {
            key: "2",
            text: "La BDD est-elle nouvelle ?",
          },
          {
            key: "3",
            text: "Actualiser le Registre des BDD et la Cartographie d'Archivage",
            supervisor: "Responsable RGPD (SecGen)",
            noLinkauto: true,
            direction: "right",
          },
          { key: "4", text: "Une donnée sensible est-elle en jeu ?", indPrec: 2, noLinkauto: true},
          {
            key: "5",
            text: "Restreindre l'accès à la BDD au Responsable de Pôle et au Responsable RGPD",
            supervisor: "Responsable RGPD (SecGen)",
            direction: "right",
            noLinkauto: true,
          },
          { key: "6", text: "S'assurer régulièrement que les données sensibles physiques sont protégées à clé", supervisor: "Responsable RGPD (SecGen)" },
          {
            key: "7",
            text: "Une personne extérieure veut faire valoir ses droits",
            component: "0",
            indPrec: 4,
            addDecY: 100,
            noLinkauto: true,
          }
        ],
  
        linkData: [
          {
            from: "2",
            to: "3",
            Emplacement: 1,
            label: "Oui",
            Espace: "grand",
          },
          {
            from: "2",
            to: "4",
            Emplacement: 1,
            label: "Non",
            Espace: "grand",
          },
          {
            from: "4",
            to: "5",
            label: "Oui"
          },
          {
            from: "3",
            to: "4",
          },
          {
            from: "6",
            to: "7",
          },
          {
            from: "4",
            to: "7",
            label: "Non",
          },
        ],
        componentList: [
          {
            number: "0",
            Name: "Cette personne à alors le droit de :",
            infosList: {
              items: [
                "Savoir si on détient des infos sur elle, demander à ce qu'on lui communique l'intégralité de ces données",
                "Rectifier des informations inexactes qui la concernent",
                "Effacer ses données personnelles de nos fichiers",
                "S'opposer pour des motifs légitimes à figurer dans nos fichiers. Peut s'opposer à ce que ses données soient diffusées, transmises ou conservées",
                "Récupérer les données qu'elle nous a communiquées et les transmettre à une autre entité",
              ],
            },
          },
          
        ],
      };
    },
    components: {
      DiagramComponent,
      TitleComponent,
    },
    methods: {
      goBack() {
        this.$router.go(-1);
      },
    },
  };
  </script>