<template>
  <!-- on affiche les catégories -->
  <categories-component :items="items" />
</template>

<script>
import CategoriesComponent from '@/components/Categories.vue';


export default {
  name: "chartes_conventions",
  data() {
    return { items : [
    {
        title: 'Partenariat',
        link: '/chartes_conventions/partenariat',
        responsables : "Présidence, Secrétaire Général, Trésorier"
    },
    {
        title: 'Stages',
        link: '/chartes_conventions/stage',
        responsables : "Présidence, Secrétaire Général, Trésorier"
    },
    {
        title: 'Parrainage entre Junior',
        link: '/chartes_conventions/parrainage',
        responsables : "Présidence, Secrétaire Général, Trésorier"
    }
    ],
    };
  },
  components: {
    CategoriesComponent,
  },
};
</script>
