<!-- DON'T TOUCH -->
<template>
    <div>
      <button class="return-button" @click="goBack">Retour</button>
      <TitleComponent :Title="Title" />
      <DiagramComponent
        :format="Format"
        :nodes="nodes"
        :linkData="linkData"
        :componentList="componentList"
      />
    </div>
  </template>
      
  
  
    <script>
  // DON'T TOUCH
  import DiagramComponent from "@/components/Diagram.vue";
  import TitleComponent from "@/components/TitlePage.vue";
  
  export default {
    name: "das2Processus", // Nom à changer
    data() {
      return {
        //Texte à remplir
        Title: {
          mainTitle:
            "Trésorerie",
          subTitle: "Fiscal",
          subSubTitle: "Processus: TVA",
          supervisor: "Trésorier",
          derniere_modif: "20/03/2024 par Mathieu CHAILLON",
        },
  
        // Exemple des différents type de nœuds
        nodes: [
          {
            key: "0",
            text: 'TVA à faire (avant le 25 du mois exclu)',
            type: "Start", // Met en vert et de forme elliptique (genre ovale)
          },
          {
            key: "1",
            text: "Le Tableau de suivi des Factures de ventes et d'achats doit être complet et à jour",
          },
          {
            key: "2",
            text: "Remplir le tableau de Suivi de la TVA (Rappel en cliquant sur la case) grâce au TS",
            supervisor: "Trésorier ou Vice Trésorier",
            component: 4

          },
          {
            key: "3",
            text: "Relecture avec les GL des comptes 44566, 44567, 44577, la dropbox et les relevés bancaires",
            link: "relectureTreso",
            supervisor: "Trésorier ou Vice Trésorier",
          },
          { key: "4", 
            text: "Aller sur impots.gouv.fr dans Mes services -> Déclarer -> TVA ",
            supervisor: "Trésorier",
        },
          { key: "4", 
            text: "Remplissage de la grille de déclaration",
            supervisor: "Trésorier",
            component : 2
        },
        { key: "4", 
            text: "ATTENTION : Si TVA à payer aller dans Mes services-> Payer -> TVA et confirmer le prélévement",
            supervisor: "Trésorier",
        },

          {
            key: "5",
            text: "Archivage ",
            supervisor: "Pole Trésorerie",
            component : 0
          },
         
          {
            key: "7",
            text: "La déclaration a été faite",
            type: "End",

          },
          {
            key: "8",
            text: "Comment réguler une \n erreur",
            type: "End",
            indPrec : 0,
            direction : "right",
            noLinkauto :true ,
            component : 1


          },
          {
            key: "8",
            text: "Comment gérer les cas particuliers (Essence, International,Subvention)",
            type: "End",
            indPrec : 9,
            noLinkauto :true ,
            component : 3


          },

        ],
  
        linkData: [
       {

       }
        ],
        
        componentList: [
        {
          number: "0",
          Name: "A archiver",
          infosList: {
            items: [
              " Le récapitulatif de déclaration",
              " Le justificatif de paiement si payement",
              " L'avis de réception",
              " GL du compte 44566, 44567, 44577",
              " Les différentes versions du Tableau de suivi de TVA"
            ],
            
          },
        },
        {
          number: "1",
          Name: "Régle pour régulariser une erreur de TVA",
          textes : [
              " **Trop déclaré de TVA déductible :** remplir la ligne 15 'TVA antérieurement déduite à reverser' avec la TVA déduite en trop.",
              " **Oubli/pas assez déclaré de TVA déductible :** inscrire le montant de TVA oublié dans la ligne 21 'autre TVA à déduire'",
              " **Trop déclaré de TVA collectée :** inscrire le montant de TVA collectée en trop dans la ligne 21 'autre TVA à déduire'.",
              " **Oubli/pas assez déclaré de TVA collectée :** inclure le montant oublié avec la TVA collectée du prochain déclaratif.",
            ],
           
        },
        
        {
          number: "2",
          Name: "Comment remplir la grille du déclaratif de TVA",
          texteMarkdown : "**Voici pour chaque partie les différentes choses à mettre dans les cases**",
          textes: [
              `Ligne A1 : Sommes des factures de Ventes du mois et refacturations

Ligne A2 : Cotisations pour une étude donc imposables (12€)

Ligne A3 : Achats faits dans l'UE (même TVA auto-liquidée)
`,
              ` Ligne E2 : Cotisations classiques donc non imposables (10€)

Ligne 03 : Achats faits dans l'UE (même TVA auto-liquidée)
`,
              ` Ligne 09-10-11 : Somme de toutes nos lignes en TVA colectée avec un taux de 20, 10 ou 5,5 %`,
              `Ligne en cas de TVA en Corse ou DOM-TOM

Ligne 17 : Somme des achats faits à l'étranger en auto-liquidation de TVA
`,
              `TVA déductible :

- Ligne 19 : Si immobilisations genre achats de nouveau mobilier ou imprimante
- Ligne 20 : Tout le reste des achats
- Ligne 22 : Report du crédit présent sur la dernière déclaration

Ligne 25 et TD à vérifier avec le tableau de préparation (ULTRA IMPORTANT)
`
            ],
          images : [require("@/assets/tva1.png"),
          require("@/assets/tva2.png"),
          require("@/assets/tva3.png"),
          require("@/assets/tva4.png"),
          require("@/assets/tva5.png"),]
           
        },
        {
          number: "3",
          Name: "Cas Particuliers",
          textes:  [`#### Achat de marchandise à l'étranger (Hors UE)

ex: SignNow, AES Audio Engineering society

Lors d'achats à l'étranger, hors UE, la TVA éventuelle à déclarer sera définie lors du passage en douane si cela a lieu. Vous serez dans ce cas informé d'un document à la réception du colis et le formulaire TVA sur impots.gouv sera prérempli.

En l'absence de cela, ces achats ne devront tout simplement pas apparaître sur le formulaire TVA.`,
          `#### Achat de marchandise à l'étranger (UE)

ex : commande de Stickers chez Stickermule, Vistaprint

Lorsqu'on achète des marchandises à l'étranger, **la facture sera en HT** et ne comporte pas de TVA.
Il faut donc **autoliquider** la TVA en la déclarant à la fois en déductible et en collectée. Pour cela, on calcule le montant de la TVA nous-même (au taux en vigueur, 20% en 2018). 
Puis, sur le formulaire, **pour l'achat d'un bien** :

  * En ligne 3 (Acquisitions intra-communautaires) : le montant HT des achats réalisés
  * En ligne 8 (base HT total TVA collectée): On ajoute la ligne 3 à la ligne 1 (et autres si d'autres cas particuliers)
  * En ligne 17 : On indique le montant de la TVA sur l'achat réalisé
  * En ligne 20 : On ajoute le montant de la TVA à autoliquider au montant de la TVA déductible sur les achats
  
Pour **l'achat d'une prestation de service** : 
* En ligne 2A (Achat de prestations de services intra) : compléter la ligne 2A au lieu de la ligne 3 pour le montant HT
* En ligne 8 : base HT total TVA collectée incluant le montant de la ligne 2A
* En ligne 20 : On ajoute le montant de la TVA à autoliquider au montant de la TVA déductible sur l'achat de la prestation de service
  
Le montant de la déclaration doit être le même avec et sans la prise en compte de l'achat à l'étranger.`,
          `#### Ventes clients étrangers

Dans le cas de clients étrangers, les règles en matière de TVA peuvent être différentes et dépendent du pays du client et de l'assujettissement ou non du client.
Consulter **Kiwi Legal** dans cette situation.`,
          `#### Tickets de péages

Pour pouvoir déduire la TVA sur les tickets de péage, il faut veiller à ce que le formulaire au dos du ticket soit rempli avec les bonnes informations.`,
          `#### Carburant

La TVA peut être en partie déductible en fonction du type de carburant et du véhicule utilisé. Consulter Kiwi Legal pour connaître les barèmes.

*Par exemple, en 2022, la TVA est déductible à 80% sur le gazole pour un véhicule non utilitaire : 
On calcule ce montant de TVA (ARRONDI.INF(TVAx0,8)) qui sera déductible et le HT englobera la portion non déductible.
On indiquera un taux de 20% sur une NdF en décorrélant ce taux des calculs du tableau Excel.*`,
`#### Subventions commerciales et non commerciales

* Subvention commerciale : Subvention donnant lieu à une contrepartie (ex : la subvention de la BNP), c'est une opération imposable.
	La subvention reçue sera en TTC, il faudra calculer le HT et le montant de TVA collectée (taux de 20%) à déclarer.
  Il faudra éditer une **facture** où figurera la TVA.
  La TVA sera déclarée de la même manière que pour une étude.
  
* Subvention non commerciale : Subvention ne donnant pas lieu à une contrepartie, c'est une opération non imposable à déclarer en **ligne 5**.
  Éditer une facture n'est pas nécessaire, il faudra archiver des preuves (mails, convention de partenariat, ...) pour justifier la comptabilité.`,


          
            ],
           
        },
        {
          number: "4",
          Name: "Rappel TVA",
          textes : [
              " **TVA déductible :** Date référence = Date de Facturation ",
              " **TVA déductible :** Date référence = Date de Payement ",
              " **Crédit de TVA :** A trouver dans la déclaration du mois Précédent ",
              " **Attention :** Si les calculs donnent sur certaines lignes une différence en centime avec les montants de la facture Mettre ceux de la facture soit même à la main ",
            ],
           
        },
        
      ],
      };
    },
    components: {
    DiagramComponent,
    TitleComponent,
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};

</script> 