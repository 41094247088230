<!-- DON'T TOUCH -->
<template>
    <div>
      <button class="return-button" @click="goBack">Retour</button>
      <TitleComponent :Title="Title" />
      <DiagramComponent
        :format="Format"
        :nodes="nodes"
        :linkData="linkData"
        :componentList="componentList"
      />
    </div>
  </template>
      
  
  
    <script>
  // DON'T TOUCH
  import DiagramComponent from "@/components/Diagram.vue";
  import TitleComponent from "@/components/TitlePage.vue";
  
  export default {
    name: "clotureView", // Nom à changer
    data() {
      return {
        //Texte à remplir
        Title: {
          mainTitle:
            "Clôture comptable",
          subTitle: "Processus : Clôture comptable",
          supervisor: "Pôle Trésorerie",
          derniere_modif: "11/03/2024 par Enzo CHAMPAGNE",
        },
  
        // Exemple des différents type de nœuds
        nodes: [
          {
            key: "0",
            text: 'Avant la clôture comptable',
            type: "Start", // Met en vert et de forme elliptique (genre ovale)
          },
          {
            key: "1",
            text: "S'assurer d'avoir toutes les pièces comptables",
            component: "0",
          },
          {
            key: "2",
            text: "Contacter l'Expert-comptable pour convenir de la procédure de clôture",
          },
          {
            key: "3",
            text: "Mettre à disposition de l'Expert tous les documents nécessaires à la clôture",
            component: "1",
          },
          { key: "4", text: "Préparer toutes les questions à poser à l'Expert-comptable"},
          {
            key: "5",
            text: "Réunir, si possible, les pôles Trésorerie entrant et sortant pour assister l'Expert-comptable",
            noLinkauto: true
          },
          { key: "6", text: "Effectuer une sauvegarde informatique de la comptabilité" },
          {
            key: "7",
            text: "Prendre des notes lors de l'entretien afin de rédiger un compte-rendu qui sera archivé sur le Drive",
          },
          {
            key: "8",
            text: "Vérifier que l'Expert-comptable a bien tenu compte du tableau cut-off qui lui a été transmis",
            noLinkauto: true
          },
          { key: "9", 
          text: "Imprimer et archiver le Grand Livre, les journaux, la balance, la liste des immobilisations"},
  
  
          { key: "10", text: "Envoyer la liasse fiscale au Trésor Public et l'uploader sur Kiwi, puis s'acquitter de l'impot sur les sociétés", noLinkauto: true},
          { key: "11", text: "Si l'Expert-comptable ne l'a pas fait, effectuer la clôture de l'exercice sur le logiciel comptable"},
          {
            key: "12",
            text: "Ouvrir un nouvel exercice comptable sur le logiciel de comptabilité et saisir les reports à nouveau"
          },
          {
            key: "13",
            text: "Archiver les documents à travers un dossier de clôture",
            component: "2"
          },
          {
            key: "14",
            text: "Clôture terminée",
            type: "End"
          }
        ],
  
        linkData: [
          {
            from: "4",
            to: "5",
            Emplacement: 1,
            label: "Le jour de la clôture comptable",
            Espace: "grand",
          },
          {
            from: "7",
            to: "8",
            Emplacement: 1,
            label: "Après la clôture comptable",
            Espace: "grand",
          },
          {
            from: "9",
            to: "10",
            label: "Dans les 3 mois après la date de clôture",
            Emplacement: 2,
          },
        ],
        componentList: [
          {
            number: "0",
            Name: "S'assurer d'avoir :",
            infosList: {
              items: [
                "Entré toutes les écritures en comptabilité",
                "Tous les documents imputables à l'exercice à clôturer (Factures d'achat et de vente, BV, NDF, BRC, TR, TVA, CFE, etc)",
                "Les pièces comptables associées (preuves de paiement, bordereaux de remise de chèques, etc)",
                "Les différents déclaratifs réalisés sur l'exercice comptable"
              ],
            },
          },
          {
            number: "1",
            Name: "Documents nécessaires à la clôture",
            infosList: {
              items: [
                "Déclaratifs de TVA",
                "BRC",
                "Relevé bancaire de tous nos comptes du dernier mois",
                "ERB",
                "Tableau de Suivi et cut-offs",
                "Toutes les pièces comptables"
              ],
              
            },
          },
          {
            number: "2",
            Name: "Dossier de clôture comptable",
            infosList: {
              items: [
                "Fichier F.E.C.",
                "Balance",
                "Grand livre",
                "Tableau de suivi (cut offs, amortissements, dettes et créances)",
                "Ecritures de clôture (dans le journal OD)",
                "Liasse fiscale (bilan, compte de résultat)",
                "Impôt sur les sociétés",
                "Retour d'expérience, compte rendu"
              ],
              
            },
          },
        ],
      };
    },
    components: {
      DiagramComponent,
      TitleComponent,
    },
    methods: {
      goBack() {
        this.$router.go(-1);
      },
    },
  };
  </script> 