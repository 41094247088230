


<template>
    <ListCocheComponent :infos="infos" :Title="Title" @Click-close="goBack" />
  </template>
     
     <script>
  import ListCocheComponent from "@/components/ListeValidation.vue";
  
  export default {
    name: "list_example",
    components: {
      ListCocheComponent,
    },
    data() {
      return {
        Title: {
          mainTitle: "Cadre Légal et Réglementaire",
          subTitle: "Gestion Associative",
          subSubTitle: "Procédure: Gestion du Dossier de Suivi ",
          supervisor: "Secrétaire Général",
          derniere_modif: "27/02/24 par Bastien HUET",
          image :require("@/assets/clr.png")
        },
        infos: {
          items: [
            " Garder tous les documents dans l'ordre chronologique des mandats ",
            " Archive les Statuts et ses modifications",
            " Archive le Règlement intérieur et ses modifications ",
            " Archive tous les récipissés",
            " Archive l'extrait du journal officiel",

          ],
          texte_if_all: "Bravo à toi tu es un génie",
          link_if_all:
            "https://docs.google.com/presentation/d/1Nuk0xAWRt1VpCjENFtEoXKJUuYKVm6btvSVDb-fGhW4/edit?usp=sharing",
          texte_link: "Cliquez ici",
      
        },
      };
    },
    methods: {
      goBack() {
        this.$router.go(-1);
      },
    },
  };
  </script>