<!-- DON'T TOUCH -->
<template>
    <div>
      <button class="return-button" @click="goBack">Retour</button>
      <TitleComponent :Title="Title" />
      <DiagramComponent
        :format="Format"
        :nodes="nodes"
        :linkData="linkData"
        :componentList="componentList"
      />
    </div>
  </template>
      
  
  
    <script>
  // DON'T TOUCH
  import DiagramComponent from "@/components/Diagram.vue";
  import TitleComponent from "@/components/TitlePage.vue";
  
  export default {
    name: "redaction_armView", // Nom à changer
    data() {
      return {
        //Texte à remplir
        Title: {
          mainTitle:
            "Activité Commerciale",
          subTitle: "Pendant étude",
          subSubTitle: "Processus : Rédaction ACE",
          supervisor: "Pôle Activité Commerciale, Trésorerie, Présidence",
          derniere_modif: "23/03/2024 par Enzo CHAMPAGNE",
        },
  
        // Exemple des différents type de nœuds
        nodes: [
          {
            key: "0",
            text: 'Document type téléchargé \n (cliquer ici)',
            external_link : "https://drive.google.com/drive/folders/12er519cJMoNO036EeG2EB3RD79_BRVPW",
            type: "Start",
          },
          {
            key: "1",
            text: "Si ce n'est pas fait, installer la police Inter avant d'éditer le document",
          },
          {
            key: "2",
            text: "Utiliser le document type en remplaçant tout ce qui est surligné en gris par les données propres à l'étude",
          },
          {
            key: "3",
            text: "Vérifier que tout est conforme",
            component: "0",
          },
          {
            key: "4",
            text: "ARM rédigé et envoyé à la Qualité pour relecture",
            type: "End",
          },
        ],
  
        linkData: [
        ],
        componentList: [
        {
            number: "0",
            Name: "Tous les points suivants doivent être vérifiés :",
            infosList: {
              items: [
              "Référence du document correcte",
              "Textes justifiés",
              "Client écrit avec une majuscule",
              "Pagination correcte",
              "Nom et adresse de l'étudiant corrects",
              "Nom et adresse de l'entreprise corrects",
              "Nom du Président d'AEI correct",
              "Date et références à la CE cohérentes",
              "Phrases simples et compréhensibles par tous avec un vocabulaire adapté",
              "Toutes les modifications de la CE apparaissent dans les alinéas",
              "Alinéas bien numérotés",
              "Cohérence de la méthode de réalisation, des JEH attribués ainsi que du temps imparti",
              "Exhaustivité des informations afin d'éviter tout malentendu",
              ],
            },
          },
        ],
      };
    },
    components: {
      DiagramComponent,
      TitleComponent,
    },
    methods: {
      goBack() {
        this.$router.go(-1);
      },
    },
  };
  </script> 