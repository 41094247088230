import reunion_presentation from '../views/clr/chartes_conventions/partenariat/reunion_presentation.vue';


import evaluation_suivi from '../views/clr/chartes_conventions/partenariat/evaluation_suivi.vue';


import partenariat from '../views/clr/chartes_conventions/partenariat/partenariat.vue';

import chartes_conventions from '../views/clr/chartes_conventions/chartes_conventions.vue';

import gestion_associative from '../views/clr/gestion_associative/gestion_associative.vue';

import gestion_reunion from '../views/clr/gestion_associative/gestion_reunion/gestion_reunion.vue';

import assemblee_generale from '../views/clr/gestion_associative/gestion_reunion/assemblee_generale/assemblee_generale.vue';

import conseil_administration from '../views/clr/gestion_associative/gestion_reunion/conseil_administration/conseil_administration.vue';

import reunion_hebdomadaire from '../views/clr/gestion_associative/gestion_reunion/reunion_hebdomadaire/reunion_hebdomadaire.vue';

import archivage from '../views/clr/gestion_associative/archivage/archivage.vue';

import gestion_documentaire from '../views/clr/gestion_associative/gestion_documentaire/gestion_documentaire.vue';

import statuts from '../views/clr/gestion_associative/gestion_documentaire/statuts/statuts.vue';

import reglement_interieur from '../views/clr/gestion_associative/gestion_documentaire/reglement_interieur/reglement_interieur.vue';

import dossier_suivi from '../views/clr/gestion_associative/gestion_documentaire/dossier_suivi/dossier_suivi.vue';

import classeur_association from '../views/clr/gestion_associative/gestion_documentaire/classeur_association/classeur_association.vue';

import cadeaux_concours from '../views/clr/cadeaux_concours/cadeaux_concours.vue';

import stage from '../views/clr/chartes_conventions/stage/stage.vue'

import rgpd from '../views/clr/gestion_associative/rgpd/rgpd.vue';
const routes = [

    { path: '/chartes_conventions', name: 'chartes_conventions', component: chartes_conventions },

    { path: '/chartes_conventions/partenariat', name: 'partenariat', component: partenariat },
    {
        path: '/chartes_conventions/partenariat/evaluation_suivi',
        name: 'evaluation_suivi',
        component: evaluation_suivi
    },
    {
        path: '/chartes_conventions/partenariat/reunion_presentation',
        name: 'reunion_presentation',
        component: reunion_presentation
    },

    {
        path: '/gestion_associative',
        name: 'gestion_associative',
        component: gestion_associative
    },
    {
        path: '/gestion_reunion',
        name: 'gestion_reunion',
        component: gestion_reunion
    },
    {
        path: '/gestion_reunion/assemblee_generale',
        name: 'assemblee_generale',
        component: assemblee_generale
    },
    {
        path: '/gestion_reunion/conseil_administration',
        name: 'conseil_administration',
        component: conseil_administration
    },
    {
        path: '/gestion_reunion/reunion_hebdomadaire',
        name: 'reunion_hebdomadaire',
        component: reunion_hebdomadaire
    },
    {
        path: '/archivage',
        name: 'archivage',
        component: archivage
    },
    {
        path: '/gestion_documentaire',
        name: 'gestion_documentaire',
        component: gestion_documentaire
    },
    {
        path: '/gestion_documentaire/statuts',
        name: 'statuts',
        component: statuts
    },
    {
        path: '/gestion_documentaire/reglement_interieur',
        name: 'reglement_interieur',
        component: reglement_interieur
    },
    {
        path: '/gestion_documentaire/dossier_suivi',
        name: 'dossier_suivi',
        component: dossier_suivi
    },
    {
        path: '/gestion_documentaire/classeur_association',
        name: 'classeur_association',
        component: classeur_association
    },
    {
        path: '/cadeaux_concours',
        name: 'cadeaux_concours',
        component: cadeaux_concours
    },
    {
        path: '/chartes_conventions/stage',
        name: 'stage',
        component: stage
    },
    {
        path: '/gestion_associative/rgpd',
        name: 'rgpd',
        component: rgpd
    },
];

export default routes;
