<!-- DON'T TOUCH -->
<template>
    <div>
      <button class="return-button" @click="goBack">Retour</button>
      <TitleComponent :Title="Title" />
      <DiagramComponent
        :format="Format"
        :nodes="nodes"
        :linkData="linkData"
        :componentList="componentList"
      />
    </div>
  </template>
      
  
  
    <script>
  // DON'T TOUCH
  import DiagramComponent from "@/components/Diagram.vue";
  import TitleComponent from "@/components/TitlePage.vue";
  
  export default {
    name: "das2Processus", // Nom à changer
    data() {
      return {
        //Texte à remplir
        Title: {
          mainTitle:
            "Trésorerie",
          subTitle: "Social",
          subSubTitle: "Processus: CFE",
          supervisor: "Trésorier",
          derniere_modif: "05/04/2024 par Mathieu CHAILLON",
        },
  
        // Exemple des différents type de nœuds
        nodes: [
          {
            key: "0",
            text: 'CFE',
            type: "Start", // Met en vert et de forme elliptique (genre ovale)
          },
          {
            key: "1",
            text: "Tenter d'être éxonéré (infos à récup auprès des anciens Trésorier)",
            supervisor : "Trésorier"
          },
          {
            key: "2",
            text: "Aller sur net-entreprises",
            supervisor: "Trésorier et Ancien Trésorier",
            external_link : "https://net-entreprises.fr/"
          },
          {
            key: "3",
            text: "Se connecter à net-entreprises.fr et s'identifier (Compte à créer via celui du Trésorier précédent)",
            supervisor: "Trésorier",
            external_link : "https://net-entreprises.fr/"
          },
          {
            key: "4",
            text: "Payer la CFE",
          },
          { key: "6", 
          text: "Conserver le Reçu de Payement sur la Dropbox et dans les classeurs correspondants" ,
          supervisor: "Trésorier",
          },
         
          {
            key: "7",
            text: "La CFE a été payé",
            type: "End",

          },

        ],
  
        linkData: [
       {

       }
        ],
        
        componentList: [
      ],
      };
    },
    components: {
    DiagramComponent,
    TitleComponent,
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};

</script> 