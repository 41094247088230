<template>
  <TexteComponent
    :markdownContent="Texte"
    :list_image = "images"
    :list_markdown = "textes"
    :Title="Title"
    @Click-close="goBack"
  />
</template>

<script>
import TexteComponent from "@/components/TexteMarkdown.vue";
export default {
  name: "preparation_offre_list",
  components: {
    TexteComponent,
  },
  data() {
    return {
      //Le titre avec les différentes lignes
      Title: {
        mainTitle:
          "Example de Processus de l'ancien Wiki transféré en markdown",
        subSubTitle: "Procédure: Book passa Président",
        supervisor: "Présidence",
        derniere_modif: "16/02/24 par Mathieu CHAILLON",
      },
      images : [require("@/assets/roue_archivage.png"),
      require("@/assets/roue_archivage.png"),
      require("@/assets/roue_archivage.png"),
        ],
        textes: [
          " Je ne commit pas si ça compile pas",
          " Je ne touche pas au endroit où y a écrit DO NOT TOUCH ",
          " Je ne m'appele pas Bastien ",
          " Treso >> ",
        ],
      //Le Texte en Markdown (Genre comme dans Discord ou Telegram il existe plein de tranformateur texte Markdown sur internet)
      Texte: `Le wiki Présidence servira à la fois de Book de Passation pour les futurs Président de la Junior AEI  mais aussi de support procédurale pour quiconque souhaite se renseigner sur les actions qu'entreprend un Président de notre Junior.
# Book de Passation 
      





## Acteurs 
• Nini – président 07-08
• Paul – président 08-09 
• Christelle – présidente 09-10 
• Nicolas – président 10-11 
• Maël –président 11`,
    };
  },

  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
