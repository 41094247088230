<!-- DON'T TOUCH -->
<template>
    <div>
      <button class="return-button" @click="goBack">Retour</button>
      <TitleComponent :Title="Title" />
      <DiagramComponent
        :format="Format"
        :nodes="nodes"
        :linkData="linkData"
        :componentList="componentList"
      />
    </div>
  </template>
      
  
  
    <script>
  // DON'T TOUCH
  import DiagramComponent from "@/components/Diagram.vue";
  import TitleComponent from "@/components/TitlePage.vue";
  
  export default {
    name: "rdv_clientView", // Nom à changer
    data() {
      return {
        //Texte à remplir
        Title: {
          mainTitle:
            "Activité Commerciale",
          subTitle: "Avant Etude",
          subSubTitle: "Procédure : RDV Client",
          supervisor: "Pôle Activité Commerciale",
          derniere_modif: "03/04/2024 par Enzo CHAMPAGNE",
        },
  
        // Exemple des différents type de nœuds
        nodes: [
          {
            key: "0",
            text: 'Client ajouté à la liste des prospects',
            type: "Start", // Met en vert et de forme elliptique (genre ovale)
          },
          {
            key: "1",
            text: "Se préparer en amont sur le projet (termes techniques de base, exemples déjà existants, parler à des gens qui s'y connaissent). \n Faire attention aux clauses de confidentialité !",
          },
          {
            key: "2",
            text: "Créer un dossier avec le nom du Client",
            external_link : "https://drive.google.com/drive/folders/1InwGb3l6Y5WcX2_0brWb9EmPD3c8L1Pg",
          },
          {
            key: "3",
            text: "Remplir avec ses recherches une copie du document type pour les RDV Client",
            external_link: "https://docs.google.com/document/d/1oen0pvMeegB38ja7VWTldTQSuvxFQK5zpf-gyxhJA1I/edit",
          },
          { key: "4", text: "Possibilités de RDV Client : possibilité de faire le RDV au local AEI (penser à le réserver en mettant un message sur le groupe mandat), en visio, au téléphone" },
          {
            key: "5",
            text: "Etre très bien habillé",
            noLinkauto: true,
          },
          { key: "6", text: "Avoir bien nettoyé le petit local et disposer de l'eau, des stylos et des feuilles" },
          {
            key: "7",
            text: "Escorter le Client depuis le parking/tram jusqu'au local, lui demander comment s'est passé le trajet, et lui proposer un café",
          },
          {
            key: "8",
            text: "Etre très bien habillé et dans un endroit calme",
            indPrec: 5,
            noLinkauto: true,
            direction: "right"
          },
          { key: "9", 
          text: "Etre dans un endroit calme", direction: "right", noLinkauto: true, },
  
  
          { key: "10", text: "Déroulement du RDV", indPrec: 7, component: "0" },
          { key: "11", text: "Pour conclure, reprendre les points globaux et s'engager à envoyer le devis dans la semaine qui suit (très important de tenir cet engagement !) \n Dans le cas d'un RDV au local, raccompagner le Client et lui serrer la main", type: "End" },
        ],
  
        linkData: [
          {
            from: "4",
            to: "5",
            label: "En présentiel",
          },
          {
            from: "4",
            to: "8",
            label: "En visio",
          },
          {
            from: "4",
            to: "9",
            label: "Au téléphone",
          },
          {
            from: "8",
            to: "10",
          },
          {
            from: "9",
            to: "10",
          },
        ],
        componentList: [
          {
            number: "0",
            Name: "Points à respecter :",
            infosList: {
              items: [
                "Faire attention à la forme (gestuelle du Client, ...) et au contenu (ce que le Client veut vraiment) pour être au plus proche de la réalité",
                "Reformuler pour vérifier que tout est bien clair",
                "Guider le Client pour qu'il ne s'éparpille pas : ne pas le laisser faire",
                "Proposer des solutions via les recherches faites en amont ou des projets réalisés",
                "Prendre des notes sur le DT RDV Client",
                "Ne pas hésiter à utiliser des feuilles et des stylos pour dessiner le design du projet",
                "Si le Client emploie des termes techniques que l'on ne comprend pas, ne pas paniquer et ne surtout pas dire que l'on ne sait pas ! Dire que l'on ira se renseigner",
              ],
            },
          },
        ],
      };
    },
    components: {
      DiagramComponent,
      TitleComponent,
    },
    methods: {
      goBack() {
        this.$router.go(-1);
      },
    },
  };
  </script> 