<!-- Ce document est un exmple pour une vue de menu avec les magnifiques cases bleus 
Genre c'est pour faire un menu -->

<!-- DO NOT TOUCH -->
<template>
    <categories-component :items="items" />
  </template>
  
  <script>
  // DO NOT TOUCH
  import CategoriesComponent from "@/components/Categories.vue";
  
  
  //Votre partie (vous avez le droit de remplir ;)
  export default {
    name: "templateMenu", // Nom de la Page
  
    data() {
      return {
        //Infos des différentes cases du menu 
        items: [
          {
            title: "Statuts", // Titre affiché
            subtitle: "Gestion des Statuts", // Sous titres en plus petit en dessous (optional)
            link: "/gestion_documentaire/statuts", //Lien vers la page que vous souhaitez rejoindre (path dans les routers)
            responsables: "Secrétaire Général",// Responsables des process de la catégorie (optional)
          },
          {
            title: "Règlement intérieur",
            subtitle: "Gestion du Règlement Intérieur",
            link: "/gestion_documentaire/reglement_interieur",
            responsables: "Secrétaire Général",
          },
          {
            title: "Dossier de Suivi",
            subtitle: "Gestion du Dossier de Suivi",
            link: "/gestion_documentaire/dossier_suivi",
            responsables: "Secrétaire Général",
          },
          {
            title: "Classeur d'association ",
            subtitle: "Gestion du classeur d'association",
            link: "/gestion_documentaire/classeur_association",
            responsables: "Secrétaire Général",
          },
        ],
      };
    },
    components: {
      CategoriesComponent,
    },
  };
  </script>
  