<!-- DON'T TOUCH -->
<template>
    <div>
      <button class="return-button" @click="goBack">Retour</button>
      <TitleComponent :Title="Title" />
      <DiagramComponent
        :format="Format"
        :nodes="nodes"
        :linkData="linkData"
        :componentList="componentList"
      />
    </div>
  </template>
      
  
  
    <script>
  // DON'T TOUCH
  import DiagramComponent from "@/components/Diagram.vue";
  import TitleComponent from "@/components/TitlePage.vue";
  
  export default {
    name: "dadsProcessus", // Nom à changer
    data() {
      return {
        //Texte à remplir
        Title: {
          mainTitle:
            "Trésorerie",
          subTitle: "Ventes",
          subSubTitle: "Processus: Factures Client",
          supervisor: "Trésorier",
          derniere_modif: "29/03/2024 par Mathieu CHAILLON",
        },
  
        // Exemple des différents type de nœuds
        nodes: [
          {
            key: "0",
            text: 'Facture demandé par le Pole Etude',
            type: "Start", // Met en vert et de forme elliptique (genre ovale)
          },
          {
            key: "1",
            text: "Vérif des informations de l'étude niveau Budget et infos du client",
            component : 2,
            supervisor: "Trésorier ou Vice Trésorier",
          },
          {
            key: "2",
            text: "Prendre le DT de la Facture sur le Drive ",
            supervisor: "Trésorier ou Vice Trésorier",
          },
          {
            key: "3",
            text: "Remplir le DT avec les infos de la CE, des avenants et des PVRF/PVRI \n N° à la suite du précédent du même type de Facture",
            supervisor: "Trésorier ou Vice Trésorier",
          },
          { key: "4", 
            text: "Relecture",
            link : "relectureTreso",
            supervisor: "Trésorier ou Vice Trésorier",
        },
          {
            key: "5",
            text: "Renseigner le TS et mettre la Facture sur la DropBox ",
            supervisor: "Trésorier ou Vice Trésorier",
          },
          { key: "6", 
          text: "L'envoyer au chargé d'étude" ,
          },
          { key: "6", 
          text: "Rentrer en comptabilité" ,
          component : 3
          },
          { key: "7", 
          text: "Passage au processus de suivi des créances" ,
          link : "creances"
          },
          {
            key: "7",
            text: "Facture client émise",
            type: "End",

          },
          {
            key: "7",
            text: "Types de Factures",
            type: "End",
            direction : "right",
            component: 1,
            indPrec : 0,
            noLinkauto : true

          },

        ],
  
        linkData: [
       {

       }
        ],
        
        componentList: [
          {

          },
        {
          number: "4",
          Name: "Types de Factures",
          texteMarkdown: `### Facture d'Avoir, Facture Pro-Forma, Facture d'Annulation

**Facture d'Avoir et d'Annulation :** Utilisées pour modifier ou annuler une facture. Il existe différents cas de figure disponibles sur Kiwi Legal.

**Facture Pro-Forma :** Équivalent d'un devis sans aucune valeur comptable ni juridique. Elle peut être réalisée à la demande d'un client qui souhaite payer une étude en avance. Il suffit d'inscrire "pro-forma" dans le titre de la FI ou FS et de ne pas la comptabiliser. La FI ou FS remplacera alors cette facture, avec le même montant et une mention "acquittée" y figurera en gros.

### Facture d'Acompte (FA)

**Quand :** Lorsqu'une nouvelle Convention d'Étude est signée, le Trésorier reçoit une demande sur Trello de la part du chargé d'étude. Cette facture permet d'engager le client pécuniairement et de se prémunir contre les changements d'avis inopinés. Elle peut également permettre d'éviter à la Junior d'avancer les frais potentiels. Il est conseillé d'attendre le paiement de cette facture avant d'effectuer des commandes importantes pour une étude.

### Facture Intermédiaire (FI)

**Quand :** Lorsqu'un PVRI est signé pour une étude en cours, le Trésorier reçoit une demande sur Trello de la part du chargé d'étude. Cette facture diminue le risque d'impayé en étalant le paiement de l'étude et en faisant valider une partie des phases par le client.

### Facture de Solde (FS)

**Quand :** Lorsqu'un PVRF est signé à la fin d'une étude, le Trésorier reçoit une demande sur Trello de la part du chargé d'étude. La facture de solde demande le paiement du complément, une fois l'étude terminée et que le client a bien reçu les livrables et en est satisfait.
`,
          
        
        },
        {
          number: "2",
          Name: "Vérification à faire avant d'emettre une Facture",
          infosList: {
              texte_before : `**Il est important de surveiller malgrè le passage avant de la Quali** \n 
              Si problème prévenir rapidement l'étude pour correction `,
              items: [
                " JEH entre 450 et 80 €",
                " ACE ou ARCE signé par le client",
                " Continuité du budget au travers des différents ACE",
                " PVRF signé ou PVRI pour FI et FS",
                " Si ARCE vérifier que ça soit bien les bons prix",
              ],
              texte_if_all : "C'est Bon tu peux faire la Facture"
            },
        
        },
        {
          number: "4",
          Name: "Compta d'une Facture de Vente",
          images : [require("@/assets/ComptaFact1.png"),
          require("@/assets/ComptaFact2.png"),],
          
        
        },
      ],
      };
    },
    components: {
    DiagramComponent,
    TitleComponent,
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};

</script> 