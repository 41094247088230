<!-- Ce document est un exmple pour une vue de menu avec les magnifiques cases bleus 
Genre c'est pour faire un menu -->

<!-- DO NOT TOUCH -->
<template>
    <categories-component :items="items" />
  </template>
  
  <script>
  // DO NOT TOUCH
  import CategoriesComponent from "@/components/Categories.vue";
  
  
  //Votre partie (vous avez le droit de remplir ;)
  export default {
    name: "gestion_associative", // Nom de la Page
  
    data() {
      return {
        //Infos des différentes cases du menu 
        items: [
          {
            title: "Archivage", // Titre affiché
            subtitle: "Roue de l'archivage", // Sous titres en plus petit en dessous (optional)
            link: "/archivage",
            responsables: "Secrétaire Général",// Responsables des process de la catégorie (optional)
          },
          {
            title: "Gestion des réunions",
            subtitle: "Gestion des Assemblées Générales (AG), Conseils d'Administration,etc ...",
            link: "/gestion_reunion",
            responsables: "Conseil d'Administration",
          },
          {
            title: "Gestion documentaire",
            subtitle: "Classeur d'association, Status, Règlement Intérieur,Dossier de suivi",
            link: "/gestion_documentaire",
            responsables: "Secrétaire Général",
          },
          {
            title: "RGPD",
            subtitle: "Création / Modification d'une Base de Données",
            link: "/gestion_associative/rgpd",
            responsables: "Secrétaire Général",
          },

        ],
      };
    },
    components: {
      CategoriesComponent,
    },
  };
  </script>
  