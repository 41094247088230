<!-- DON'T TOUCH -->
<template>
    <div>
      <button class="return-button" @click="goBack">Retour</button>
      <TitleComponent :Title="Title" />
      <DiagramComponent
        :format="Format"
        :nodes="nodes"
        :linkData="linkData"
        :componentList="componentList"
      />
    </div>
  </template>
      
  
  
    <script>
  // DON'T TOUCH
  import DiagramComponent from "@/components/Diagram.vue";
  import TitleComponent from "@/components/TitlePage.vue";
  
  export default {
    name: "virementView", // Nom à changer
    data() {
      return {
        //Texte à remplir
        Title: {
          mainTitle:
            "Trésorerie",
          subTitle: "Achats",
          subSubTitle: "Processus: Faire un virement",
          supervisor: "Trésorier",
          derniere_modif: "05/04/2024 par Mathieu CHAILLON",
        },
  
        // Exemple des différents type de nœuds
        nodes: [
          {
            key: "0",
            text: 'Virement à faire',
            type: "Start", // Met en vert et de forme elliptique (genre ovale)
          },
          {
            key: "1",
            text: "Aller sur la BNP",
          },
          {
            key: "2",
            text: "Aller dans la partie virement",
            supervisor: "Trésorier et Ancien Trésorier",
          },
          {
            key: "3",
            text: "Cliquer sur le compte courant",
            supervisor: "Trésorier",
          },
          {
            key: "4",
            text: "Chercher le bénéficiaire",
          },
          { key: "5", 
            text: "Ajouter le bénéficiaire (Attention ça prend un jour)",
            direction: "right",
            link : "ajoutBeneficiaire"
          },
          {
            key: "6",
            text: "Remplir les infos pour le virements (Attention au montant et à mettre un texte clair)",
            indPrec : 4,
          },
          { key: "7", 
          text: "Archiver la preuve de virement si BV ou NFG" ,
          supervisor: "Trésorier",
          },
         
          {
            key: "7",
            text: "Virement effectué",
            type: "End",

          },

        ],
  
        linkData: [
       {

       }
        ],
        
        componentList: [
      ],
      };
    },
    components: {
    DiagramComponent,
    TitleComponent,
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};

</script> 