<!-- Ce document est un exmple pour une vue de menu avec les magnifiques cases bleus 
Genre c'est pour faire un menu -->

<!-- DO NOT TOUCH -->
<template>
    <categories-component :items="items" />
  </template>
  
  <script>
  // DO NOT TOUCH
  import CategoriesComponent from "@/components/Categories.vue";
  
  
  //Votre partie (vous avez le droit de remplir ;)
  export default {
    name: "comView", // Nom de la Page
  
    data() {
      return {
        //Infos des différentes cases du menu 
        items: [
          {
            title: "Envoi de communications", // Titre affiché
            subtitle: "Processus et procédures d'envoi de communications", // Sous titres en plus petit en dessous (optional)
            link: "/envoi_com", //Lien vers la page que vous souhaitez rejoindre (path dans les routers)
            responsables: "Responsable Qualité, Responsable Communication",
          },
          {
            title: "Organisation d'évènements",
            subtitle: "Processus d'organisation d'évènements",
            link: "/organisation_event",
            responsables: "Responsable Qualité, Responsable Communication, Responsable Event, Présidence",
          },
        ],
      };
    },
    components: {
      CategoriesComponent,
    },
  };
  </script>