<!-- DON'T TOUCH -->
<template>
    <div>
      <button class="return-button" @click="goBack">Retour</button>
      <TitleComponent :Title="Title" />
      <DiagramComponent
        :format="Format"
        :nodes="nodes"
        :linkData="linkData"
        :componentList="componentList"
      />
    </div>
  </template>
      
  
  
    <script>
  // DON'T TOUCH
  import DiagramComponent from "@/components/Diagram.vue";
  import TitleComponent from "@/components/TitlePage.vue";
  
  export default {
    name: "processus_generalView", // Nom à changer
    data() {
      return {
        //Texte à remplir
        Title: {
          mainTitle:
            "Activité Commercial",
          subTitle: "Avant étude",
          subSubTitle: "Processus: Réponse à un Appel d'Offre",
          supervisor: "Co-Responsables Activité Commerciale, Responsable Qualité",
          derniere_modif: "24/03/2024 par Enzo CHAMPAGNE",
        },
  
        // Exemple des différents type de nœuds
        nodes: [
          {
            key: "0",
            text: "Aller sur Kiwi X, dans Business puis Appels d'Offres \n (Cliquer ici)",
            external_link: "https://kiwix.junior-entreprises.com/business/offer/all/",
            type: "Start",
          },
          {
            key: "1",
            text: "Filtrer les offres selon nos offres de prestation. \n Privilégier les derniers AO proposés (moins de 10 jours)"
          },
          {
            key: "2",
            text: 'Processus Général',
            link: "processus_general",
          },
          {
            key: "3",
            text: "Déclarer l'étude sur Kiwi X (la CNJE peut prélever 6% du montant HT de l'étude)",
            type: "End",
          }
        ],
  
        linkData: [
        ],
        componentList: [
        ],
      };
    },
    components: {
      DiagramComponent,
      TitleComponent,
    },
    methods: {
      goBack() {
        this.$router.go(-1);
      },
    },
  };
  </script> 