<!-- DON'T TOUCH -->
<template>
    <div>
      <button class="return-button" @click="goBack">Retour</button>
      <TitleComponent :Title="Title" />
      <DiagramComponent
        :format="Format"
        :nodes="nodes"
        :linkData="linkData"
        :componentList="componentList"
      />
    </div>
  </template>
      
  
  
    <script>
  // DON'T TOUCH
  import DiagramComponent from "@/components/Diagram.vue";
  import TitleComponent from "@/components/TitlePage.vue";
  
  export default {
    name: "PageProcessus", // Nom à changer
    data() {
      return {
        //Texte à remplir
        Title: {
          mainTitle:
            "Activité Commerciale",
          subTitle: "Fin de l'étude",
          supervisor: "Pôle Activité Commerciale, Pôle Qualité, Trésorerie, Présidence, Secrétaire Général",
          derniere_modif: "07/03/2024 par Zhaniya NURKHANOVA",

        },
  
        // Exemple des différents type de nœuds
        nodes: [
          {
            key: "0",
            text: 'Fin de la réalisation du projet',
            type: "Start",
          },
          {
            key: "1",
            text: "Vérification des livrables",
            component: "0",
            supervisor: "Pôle Activité Commerciale",
          },
          {
            key: "2",
            text: "Emission du PVRF",
          },
          {
            key: "3",
            text: "Emission de la FS",
          },
       
          {
            key: "4",
            text: "Fidélisation du Client",


          },
          {
            key: "5",
            text: "Emission du BV",
            noLinkauto: true,

          },
          {
            key: "6",
            text: "Relance de l'étude",

          },
          {
            key: "7",
            text: "Mini audit de l'étude",
            component: "1",
            supervisor: "Respo Activité Commerciale/Etude, Respo Qualité, Chargé Activité Commerciale"

          },
          {
            key: "8",
            text: "Archivage de l'étude",
            component: "2",
            supervisor: "Secrétaire Général, Pôle Activité Commerciale"

          },
          {
            key: "9",
            text: "Archivage de l'étude",
            type: "End",

          },
        ],
  
        linkData: [
           {
            from: "0",
            to: "1",
            Espace: "grand",
          },
           {
            from: "1",
            to: "2",
            Espace: "grand",
          },
           {
            from: "2",
            to: "3",
            Espace: "grand",
          },
          {
            from: "2",
            to: "3",
            Espace: "grand",
          },
          {
            from: "3",
            to: "4",
            Espace: "grand",
          },
          {
            from: "4",
            to: "5",
            Espace: "grand",
          },
          {
            from: "5",
            to: "7",
            Emplacement: 2,
            label: "1 mois après la signature du PVRF",
            bySide: true,
            // decalageSup : 100,
            Espace: "grand"
          },
          {
            from: "5",
            to: "6",
            label: "Si réclamation",
            Espace: "grand",
          },
          {
            from: "6",
            to: "7",
            Espace: "grand",
          },
          {
            from: "7",
            to: "8",
            Espace: "grand",
          },
          {
            from: "8",
            to: "9",
            Espace: "grand",
          },


          
       
        ],
        componentList: [
          // Vérification des livrables
          {
        number: "0",
        Name: "Procédure: Vérification des livrables",
        infosList: {
          items: [
            "Reprendre chaque point du CDC et le tester.",
            "Si livrable non conforme, correction du livrable par l'intervenant.",
          ],
        },
        },
        //Mini-audit
        {
          number: "1",
          Name: "Procédure: Mini-audit",
          infosList:{
            items:[
              "Pagination correcte.",
              "Les dates sont exactes.",
              "Le bon nombre de JEH a bien été attribué.",
              "On reparcourt le processus d'étude et on vérifie son exactitude.",
            ],
          },
        },
        //Archivage de l'étude
        {
          number:"2",
          Name: "Procédure: Archivage de l'étude",
          infosList:{ 
            items:[ 
              "Convention d'étude et Avenants (le cas échéant).",
              "Accord de confidencialité (le cas échéant).",
              "Procès verbaux de recette.",
              "Factures.",
              "Récapitulatif de Mission et Avenants (le cas échéant). ",
              "Rapport pédagogique (si l'étude a débuté avant le changement).",
            ],
          },
        },
      ],
      };
    },
    components: {
      DiagramComponent,
      TitleComponent,
    },
    methods: {
      goBack() {
        this.$router.go(-1);
      },
    },
  };
  </script> 