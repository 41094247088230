<!-- DON'T TOUCH -->
<template>
    <div>
      <button class="return-button" @click="goBack">Retour</button>
      <TitleComponent :Title="Title" />
      <DiagramComponent
        :format="Format"
        :nodes="nodes"
        :linkData="linkData"
        :componentList="componentList"
      />
    </div>
  </template>
      
  
  
    <script>
  // DON'T TOUCH
  import DiagramComponent from "@/components/Diagram.vue";
  import TitleComponent from "@/components/TitlePage.vue";
  
  export default {
    name: "redaction_ce_ccView", // Nom à changer
    data() {
      return {
        //Texte à remplir
        Title: {
          mainTitle:
            "Activité Commerciale",
          subTitle: "Avant Etude",
          subSubTitle: "Procédure : Rédaction CE ou CC",
          supervisor: "Pôle Activité Commerciale",
          derniere_modif: "26/03/2024 par Enzo CHAMPAGNE",
        },
  
        // Exemple des différents type de nœuds
        nodes: [
          {
            key: "0",
            text: 'Document type téléchargé \n (cliquer ici)',
            external_link : "https://drive.google.com/drive/folders/12er519cJMoNO036EeG2EB3RD79_BRVPW",
            type: "Start",
          },
          {
            key: "1",
            text: "Si ce n'est pas fait, installer la police Inter avant d'éditer le document",
          },
          {
            key: "2",
            text: "Utiliser le document type en remplaçant tout ce qui est surligné en gris par les données propres à l'étude",
          },
          {
            key: "3",
            text: "Vérifier que tout est conforme",
            component: "0",
          },
          {
            key: "4",
            text: "CE ou CC rédigée et uploadée sur l'ERP pour relecture \n (Cliquer ici)",
            type: "End",
            link: 'document_erp',
          },
        ],
  
        linkData: [
        ],
        componentList: [
        {
            number: "0",
            Name: "Tous les points suivants doivent être vérifiés :",
            infosList: {
              items: [
              "Textes justifiés",
              "Client écrit avec une majuscule",
              "Pagination correcte",
              "Dates cohérentes",
              "Sommaire cohérent",
              "Nom et adresse du Client corrects",
              "Nom et coordonnées du chef de projet corrects",
              "Nom du président d'AEI correct",
              "Phrases simples et compréhensibles par tous avec un vocabulaire adapté",
              "Alinéas bien numérotés",
              "Période de garantie correcte",
              "Respect des textes encadrant le mouvement",
              "2 JEH supplémentaires pour les phases d'analyse",
              "Exhaustivité des informations afin d'éviter tout malentendu",
              "Cohérence de la méthode de réalisation, des JEH attribués ainsi que du temps imparti",
              "Si la commande de pièces est nécessaire, ajouter une évaluation du prix des composants au devis sans augmenter le nombre de JEH",
              "Si la commande de pièces est nécessaire, ajouter 2-3 semaines supplémentaires dans la durée totale de l'étude pour le délai de livraison des composants",
              ],
            },
          },
        ],
      };
    },
    components: {
      DiagramComponent,
      TitleComponent,
    },
    methods: {
      goBack() {
        this.$router.go(-1);
      },
    },
  };
  </script> 