<!-- DON'T TOUCH -->
<template>
    <div>
      <button class="return-button" @click="goBack">Retour</button>
      <TitleComponent :Title="Title" />
      <DiagramComponent
        :format="Format"
        :nodes="nodes"
        :linkData="linkData"
        :componentList="componentList"
      />
    </div>
  </template>
      
  
  
    <script>
  // DON'T TOUCH
  import DiagramComponent from "@/components/Diagram.vue";
  import TitleComponent from "@/components/TitlePage.vue";
  
  export default {
    name: "BvProcessus", // Nom à changer
    data() {
      return {
        //Texte à remplir
        Title: {
          mainTitle:
            "Social",
          subTitle: "Emettre et Payer un Bulettin de Versement (BV)",
          supervisor: "Pole Trésorerie et Pole Actico",
          derniere_modif: "21/03/24 par Mathieu CHAILLON",
        },
  
        // Exemple des différents type de nœuds
        nodes: [
          {
            key: "0",
            text: 'PVRI signé \n FI payé',
            type: "Start", // Met en vert et de forme elliptique (genre ovale)
          },
          {
            key: "1",
            text: 'PVRF signé \n FS payé',
            direction: "left",
            type: "Start", // Met en vert et de forme elliptique (genre ovale)
            noLinkauto : true
          },
          {
            key: "2",
            text: "Demande par le chargé de l'Etude d'un BV",
            supervisor: "Chargé Actico",
            addDecX : 150,
            
            
          },
          {
            key: "3",
            text: "Vérif par le chargé de l'ensemble des infos de l'intervenant + doc études intervenant (Pour + d'infos cliquez)",
            supervisor: "Chargé Actico",
            component: "0",
          },
          {
            key: "4",
            text: "Infos de l'intervenant ajouté au TS",
            supervisor: "Trésorier ou Vice-Trésorier",
            component: "1",
          },
          {
            key: "5",
            text: "Ajouté le RIB de l'intervenant comme bénéficiare sur le compte BNP",
            supervisor: "Trésorier ou Vice-Trésorier",
            link: "ajoutBeneficiaire",

          },
          
          {
            key: "6",
            text: "Relire doc études en rapport avec l'intervenant (RM/ARM/ARRM) pour vérifier les infos",
            supervisor: "Trésorier ou Vice-Trésorier",
            component: "2",
          },
          {
            key: "7",
            text: "Vérifier informations pour BV (Smic, Taux, BV Type)",
            supervisor: "Trésorier ou Vice-Trésorier",
            component: "3",
          },
          {
            key: "8",
            text: "Remplir l'onglet BV du TS",
            supervisor: "Trésorier ou Vice-Trésorier",
            component: "4",
          },
          {
            key: "9",
            text: "Remplir le DT du BV avec les infos rassemblé dans le TS",
            supervisor: "Trésorier ou Vice-Trésorier",
            component: "5",
          },
          {
            key: "10",
            text: "Relecture du BV et Validation",
            link: "relectureTreso",
            supervisor: "Trésorier ou Vice-Trésorier",
          },
          {
            key: "11",
            text: "Payement du BV",
            link: "virement",
            supervisor: "Trésorier",
          },
          {
            key: "12",
            text: "Rajout du numéro de virement et date de payement dans le TS et le BV",
            component: "6",
            supervisor: "Trésorier",
          },
          {
            key: "13",
            text: "Rentrer en comptabilité",
            supervisor: "Comptable",
            component: 8
          },
          {
            key: "13",
            text: "Envoyez Tutoriel et BV au chargé ActiCo de l'étude (Lien du Tuto en cliquant)",
            component: "7",
            supervisor: "Trésorier",
          },
          {
            key: "14",
            text: "Envoyez Tutoriel et BV à l'intervenant",
            supervisor: "Chargé ActiCo",
          },
          {
            key: "15",
            text: "Etude Finie (1 mois après Mini audit)",
            type: "End",
          },
        ],
  
        linkData: [
          {
            from: "0",
            to: "2",
          },
        ],
        componentList: [
          {
            number: "0",
            Name: "Informations et Document à vérifier",
            infosList: {
              texte_before : "Informations et Documents à Vérifier et Récupérer avant toute demande",
              items: [
                " RM et ARM correct",
                " PVRF ou PVRI signé",
                " FS ou FI demandée",
                " RIB de l'intervenant récupéré",
                " N° de Secu récupéré",
                " Adresse de l'intervenant  récupéré",
                " Données suplémentaires de l'intervenant récupéré tel que nationalité, date de naissance, lieu de naissance",
              ],
              texte_if_all : "Contactez le Trésorier ou Vice Trésorier"
            },
          },
          {
          number: "1",
          Name: "Renseigner les informations sur le TS",
          texteMarkdown: `Informations très importantes pour la DADS chaque année`,
          images : [require("@/assets/intervenant.png")]
        
        },
        {
          number: "2",
          Name: "Vérification à faire avant d'emettre un BV",
          infosList: {
              texte_before : `**Il est important de surveiller malgrè le passage avant de la Quali** \n 
              Si problème prévenir rapidement l'étude pour correction `,
              items: [
                " Retribution inférieure à 300€ par JEH",
                " ARM signé par l'intervenant ",
                " RM et ARM correspondant à la CE et les ACE (Vérifier au moment de la FS normalement)",
                " Continuité du budget au travers des différents ARM",
                " PVRF signé",
                " Si ARRM vérifié qu'il soit équivalent au phase payé lors de la FS ou FI",
              ],
              texte_if_all : "C'est Bon tu peux faire le BV"
            },
        
        },
        {
          number: "3",
          Name: "Voilà où les infos sont trouvables",
          infosList: {
              texte_before : `**Il est important de faire cette récup d'informations surtout pour le premier BV de l'année civile**`,
              items: [
                " BV type de la CNJE avec les Taux hors AT dispo à ce lien : [Ici](https://kiwix.junior-entreprises.com/document/document/32053)",
                " Taux AT présent sur la lettre CARSAT (Procédure pour la récup : [Ici](/#/tauxAT))",
                " SMIC horaire dispo sur internet (En cas de changement en cours d'année se référer au infos de la CNJE)",
              ],
              texte_if_all : "C'est Bon tu peux faire le BV"
            },
        
        },
        {
          number: "4",
          Name: "Renseigner les informations sur le TS",
          texteMarkdown: `Informations très importantes pour les BRC/TR et la relecture du BV en cours d'émission \n 
          Info sur l'intervenant (Récup automatiquement depuis l'onglet Intervenants avec des formules)`,
          images : [require("@/assets/ongletBV1.png"),
          require("@/assets/ongletBV2.png"),
          require("@/assets/ongletBV3.png"),],
          textes : [ "Remplir les infos présents sur le RM",
          "Infos calculés automatiquement (Attention d'avoir mis le taux actuel parmi les taux A,B,C,D) \n Ces infos nous permettront de savoir si on a les bons chiffres sur le BV"

          
        ]
        
        },
        {
          number: "5",
          Name: "Récupérer et Renseigner le DT",
          texteMarkdown: `  Le DT est présent sur le Drive Commun de AEI dans ce dossier : [Ici](https://drive.google.com/drive/folders/1fXZWO1eWgK72EuV7moSJQuNgjkvQI2Ib?usp=sharing)  
  Attention à vérifier les taux présents et à les mettre à jour sur le Drive en cas de changement.  
  **Infos Utiles :**  
  La colonne Taux de la Junior est masquée. C'est normal, c'est pour ne pas embrouiller l'intervenant avec des informations inutiles.  
  Donc on la remasque si on a besoin de la modifier.`,
        
        },
        {
          number: "6",
          Name: "Où rajouter le numéro et date de payement ",
          images : [require("@/assets/Nvirement.png"),
          require("@/assets/Nvirement2.png")],
          textes : [ "Ici sur le TS",
          "Et ici sur le BV"
          ]
        
        },
        {
          number: "7",
          Name: "Récupérer le Tutoriel",
          texteMarkdown: `  Le Tutoriel est présent sur Kiwix  [Ici](https://kiwix.junior-entreprises.com/document/document/26639)  
  A conserver dans le dossier des BVs sur la Dropbox `,
        
        },
        {
          number: "8",
          Name: "Rentrer en compta le BV",
          texteMarkdown: `Informations sur les entrés comptables lors d'un BV`,
          images : [require("@/assets/comptaBV1.png"),
          require("@/assets/comptaBV2.png"),]
        
        },
        
        ],
      };
    },
    components: {
      DiagramComponent,
      TitleComponent,
    },
    methods: {
      goBack() {
        this.$router.go(-1);
      },
    },
  };
  </script> 