<!-- DON'T TOUCH -->
<template>
    <div>
      <button class="return-button" @click="goBack">Retour</button>
      <TitleComponent :Title="Title" />
      <DiagramComponent
        :format="Format"
        :nodes="nodes"
        :linkData="linkData"
        :componentList="componentList"
      />
    </div>
  </template>
      
  
  
    <script>
  // DON'T TOUCH
  import DiagramComponent from "@/components/Diagram.vue";
  import TitleComponent from "@/components/TitlePage.vue";
  
  export default {
    name: "PageProcessus", // Nom à changer
    data() {
      return {
        //Texte à remplir
        Title: {
          mainTitle:
            "Cadre Légal et Réglementaire",
          subTitle: "Gestion Réunion",
          subSubTitle: "Processus Assemblée Générale",
          supervisor: "Présidence, Secrétaire Général",
          derniere_modif: "26/02/2024 par Bastien HUET",
          image :require("@/assets/clr.png")

        },
  
        // Exemple des différents type de nœuds
        nodes: [
          {
            key: "0",
            text: 'Asssemblée Générale à effectuer',
            type: "Start", // Met en vert et de forme elliptique (genre ovale)
          },
          {
            key: "1",
            text: "Avant l'Assemblée Générale",
            component: "0",
            supervisor: "President,Secrétaire Général",
          },
          {
            key: "2",
            text: "Pendant l'Assemblée Générale",
            component: "1",
            supervisor: "President,Secrétaire Général",
          },
          {
            key: "3",
            text: "Après l'Assemblée Générale",
            component: "2",
            supervisor: "President,Secrétaire Général",
          },
       
          {
            key: "4",
            text: "Assemblée Générale effectuée ",
            type: "End",

          },
        ],
  
        linkData: [
          {
            from: "2",
            to: "1",
            Emplacement: 1,
            Espace: "grand",
          },
          {
            from: "4",
            to: "3",
            Emplacement: 1,
            Espace: "grand",
          },
       
        ],
        componentList: [
          // détails avant ag
          {
        number: "0",
        Name: "Procédure: Avant Assemblée Générale",
        infosList: {
          items: [
            "Envoyer un message sur le canal avec les 1A.",
            "Envoyer un message au COS (Conseil d'Orientation Stratégique).",
            "Envoyer un message sur le groupe du mandat.",
            "Faire des binômes entre le mandat n-1 et mandat n.",
            "Demander au mandat n de récupérer les procurations du mandat n-1.",
            "imprimer le nombre de bulletin nécessaires.",
          ],
        },
        },
        //détails pendant ag
        {
          number: "1",
          Name: "Procédure: Pendant l'Asseblée Générale",
          infosList:{
            items:[
              "Prendre la preuve.",
              "Chaque membres concerné présente sa propre diapositive et répond aux questions qui lui sont posées.",
              "Faire voter les membres présents avec leurs procurations.",
            ],
          },
        },
        //détails après AG
        {
          number:"2",
          Name: "Procédure: Après l'Assemblée Générale",
          infosList:{ 
            items:[ 
              "Vérifier le dépouillement.",
              "Faire signer le dépouillement par le Président et le Secrétaire Général.",
              "Etablir le PV d'AG.",
              "Déclarer l'AG en préfecture (dans les 3mois, le plus tôt possible).",
              "Faire une Com récapitulative. ",
              "Envoyer un mail à membres@junior-aei.com pour indiquer les résultats.",
              "Archiver les documents: Bulletins de vote, Document 'Participation à une AG', Procurations, Justificatifs d'envoi de recommandé, Copie du PV d'AG."


            ],
          },
        },
      ],
      };
    },
    components: {
      DiagramComponent,
      TitleComponent,
    },
    methods: {
      goBack() {
        this.$router.go(-1);
      },
    },
  };
  </script> 