


<template>
    <ListCocheComponent :infos="infos" :Title="Title" @Click-close="goBack" />
  </template>
     
     <script>
  import ListCocheComponent from "@/components/ListeValidation.vue";
  
  export default {
    name: "list_example",
    components: {
      ListCocheComponent,
    },
    data() {
      return {
        Title: {
          mainTitle: "Cadre Légal et Réglementaire",
          subTitle: "Gestion Associative",
          subSubTitle: "Procédure: Gestion du Règlement Intérieur",
          supervisor: "Secrétaire Général",
          derniere_modif: "27/02/24 par Bastien HUET",
          image :require("@/assets/clr.png")
        },
        infos: {
          items: [
            " Module de calcul des cotisations et leur montant ",
            " Règles relatives à la tenue des membres en rendez-vous Client/ Congrès ",
            " Heures d'ouverture des locaux et des permanances ",
            " Déroulement et procédure de sélection",
            " Règles d'utilisation des ordinateurs/ du téléphone de la Junior",
            " Sanctions disciplinaires",
            " Tout élément jugé nécessaire au bon fonctionnement de la Junior",
            " Composition et fonctionnement du Conseil d'Administration",

          ],
          texte_if_all: "Bravo à toi tu es un génie",
          link_if_all:
            "https://docs.google.com/presentation/d/1Nuk0xAWRt1VpCjENFtEoXKJUuYKVm6btvSVDb-fGhW4/edit?usp=sharing",
          texte_link: "Cliquez ici",
      
        },
      };
    },
    methods: {
      goBack() {
        this.$router.go(-1);
      },
    },
  };
  </script>