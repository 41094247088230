<!-- DON'T TOUCH -->
<template>
    <div>
      <button class="return-button" @click="goBack">Retour</button>
      <TitleComponent :Title="Title" />
      <DiagramComponent
        :format="Format"
        :nodes="nodes"
        :linkData="linkData"
        :componentList="componentList"
      />
    </div>
  </template>
      
  
  
    <script>
  // DON'T TOUCH
  import DiagramComponent from "@/components/Diagram.vue";
  import TitleComponent from "@/components/TitlePage.vue";
  
  export default {
    name: "virementView", // Nom à changer
    data() {
      return {
        //Texte à remplir
        Title: {
          mainTitle:
            "Trésorerie",
          subTitle: "Achats",
          subSubTitle: "Processus: Achats",
          supervisor: "Trésorier",
          derniere_modif: "03/04/2024 par Mathieu CHAILLON",
        },
  
        // Exemple des différents type de nœuds
        nodes: [
          {
            key: "0",
            text: 'Achats à faire',
            type: "Start", // Met en vert et de forme elliptique (genre ovale)
          },
          {
            key: "1",
            text: "Achats de composants pour une étude",
            supervisor: "Pole ActiCo",
            addDecX : -150

          },
          {
            key: "2",
            text: "Achats hors étude ",
            direction : "right",
            noLinkauto : true

          },
          {
            key: "3",
            text: " Faire un panier prévisionnel, un devis ou une estimation de la dépense",

          },
          {
            key: "4",
            text: "Aller au processus Commande",
            supervisor: "Trésorier, Vice Trésorier et Pole ActiCo",
            indPrec : 1,
            link : "commandes"
          },
          {
            key: "5",
            text: "Valider la dépense en prenant en compte (Objet social, budget prévisionnel, montant, concurrence, ..)",
            supervisor: "Trésorier",
            indPrec : 3
          },
          { key: "6", 
            text: "Validation en CA",
            direction: "left",
            addDecY : 50 ,
            noLinkauto : true

          },
          
          { key: "7", 
            text: "Organisation de l'achat",
            addDecX : 150
          },
          { key: "8", 
            text: "Passer au Processus NFG",
            addDecX : -300, 
            addDecY : -50, 
            link : "nfg",
            noLinkauto : true
          },
          { key: "9", 
            text: "Préparer le panier sur le site",
            addDecY : 100, 
            addDecX : -100, 
            direction : "right",
            noLinkauto : true
          },
          { key: "10", 
            text: "Aller faire l'achat avec la carte AEI (Seul le Président et le Trésorier ont le droit de l'utiliser)",
            supervisor : "Trésorier ou Président",
            direction : "right"   ,
            noLinkauto : true
       
          },
          { key: "10", 
            text: "Payer sur le site après avoir vérifier que la facture sera valide (N° de TVA présent)",
            supervisor : "Trésorier",
            addDecX : -50,
            indPrec : 9         
          },
          { key: "11", 
            text: "Récupérer la facture Fourniseur au nom de Aquitaine Electronique Informatique \n (OBLIGATOIRE )",    
            addDecX : 50,
          },
          { key: "12", 
            text: "Mettre la facture sur la Dropbox et renseigner les infos sur le TS ainsi qu'imprimer la facture si facture en ligne",    
          },
          {
            key: "13",
            text: "Achat effectué",
            type: "End",

          },

        ],
  
        linkData: [
        {
            from: "0",
            to: "2",
          },
          {
            from: "5",
            to: "7",
          },
          {
            from: "5",
            to: "6",
            label : "Montant important",
            espace : "petit"
          },
          {
            from: "7",
            to: "8",
            label : "Pas de possibilité de payer avec la carte AEI ou avec le compte AEI",
            espace : "moyen"
          },
          {
            from: "7",
            to: "9",
            label : "Achat en ligne",
            espace : "moyen"
          },
          {
            from: "7",
            to: "10",
            label : "Achat IRL",
            espace : "moyen"
          },
          {
            from: "10",
            to: "12",
          },
          
        ],
        
        componentList: [
      ],
      };
    },
    components: {
    DiagramComponent,
    TitleComponent,
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};

</script> 