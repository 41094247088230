<!-- Ce document est un exmple pour une vue de menu avec les magnifiques cases bleus 
Genre c'est pour faire un menu -->

<!-- DO NOT TOUCH -->
<template>
    <categories-component :items="items" />
  </template>
  
  <script>
  // DO NOT TOUCH
  import CategoriesComponent from "@/components/Categories.vue";
  
  
  //Votre partie (vous avez le droit de remplir ;)
  export default {
    name: "actiCoView", // Nom de la Page
  
    data() {
      return {
        //Infos des différentes cases du menu 
        items: [
          {
            title: "Processus Général",
            subtitle: "Du premier RDV Client à la signature du devis",
            link: "/avant_etude/processus_general",
            responsables: "Pôle Activité Commerciale, Pôle Qualité",
          },

          {
            title: "Réponse à un appel d'offre", // Titre affiché
            link: "/avant_etude/appel_offre", //Lien vers la page que vous souhaitez rejoindre (path dans les routers)
            responsables: "Pôle Activité Commerciale",
          },

          {
            title: "Prospection", // Titre affiché
            link: "/avant_etude/prospection", //Lien vers la page que vous souhaitez rejoindre (path dans les routers)
            responsables: "Pôle Activité Commerciale",
          },

          {
            title: "Call Center", // Titre affiché
            link: "/avant_etude/call_center", //Lien vers la page que vous souhaitez rejoindre (path dans les routers)
            responsables: "Pôle Activité Commerciale",
          },
        ],
      };
    },
    components: {
      CategoriesComponent,
    },
  };
  </script>