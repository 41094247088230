<template>
  <div>
    <button class="return-button" @click="goBack">Retour</button>
    <TitleComponent :Title="Title" />
    <DiagramComponent
      :format="Format"
      :nodes="nodes"
      :linkData="linkData"
      :componentList="componentList"
    />
  </div>
</template>
    
  <script>
import DiagramComponent from "@/components/Diagram.vue";
import TitleComponent from "@/components/TitlePage.vue";

export default {
  name: "QualiteView",
  data() {
    return {
      Format: "vertical",

      Title: {
        mainTitle: "Activité Commerciale",
        subTitle: "Pendant étude",
        subSubTitle: "Processus: Suivi d'étude et qualité",
        supervisor: "Co-responsables Activité Commerciale",
        derniere_modif: "17/03/2024 par Enzo CHAMPAGNE"
      },

      // Exemple de données pour les nœuds
      nodes: [
        {
          key: "0",
          text: "Préparation de l'étude",
          type: "Start",
        },
        {
          key: "1",
          text: "Emission de la FA",
        },
        {
          key: "2",
          text: "Suivi d'étude",
        },
        {
          key: "3",
          text: "Suite de l'étude",
          type: "End"
        },
        {
          key: "4",
          text: "Rédaction ARM",
          link: 'redaction_arm',
          noLinkauto: true,
          indPrec: "1",
          direction: "right",
        },
        {
          key: "5",
          text: "Emission d'un PVRI",
          link: 'emission_pvri',
          direction: "right",
          noLinkauto: true,
        },
        { key: "6", text: "Emission d'une FI", direction: "right", noLinkauto: true},
        
        {
          key: "7",
          text: "Rédaction ACE",
          link: 'redaction_ace',
          indPrec: "4",
          noLinkauto: true,
        },
        { key: "8", text: "Validation par la Qualité", supervisor: "Pôle Qualité", direction: "right",noLinkauto: true},
        { key: "9", text: "Commande de pièces", link: 'commande_pieces', direction: "right", noLinkauto: true},
        { key: "10", text: "Passation d'étude", link: 'passation_etude', noLinkauto: true, indPrec: "7"},
        {
          key: "11",
          text: "Rupture de CE",
          link: "rupture_ce",
          direction: "right",
          noLinkauto: true
        },
        {
          key: "12",
          text: "Rupture de RM",
          link: "rupture_rm",
          direction: "right",
          noLinkauto: true,
        },

        
      ],

      linkData: [

        {from: "1", to: "4"},
        {from: "10", to: "3"}
        
      ],

      componentList: [
      ],
    };
  },
  components: {
    DiagramComponent,
    TitleComponent,
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script> 