<!-- DON'T TOUCH -->
<template>
    <div>
      <button class="return-button" @click="goBack">Retour</button>
      <TitleComponent :Title="Title" />
      <DiagramComponent
        :format="Format"
        :nodes="nodes"
        :linkData="linkData"
        :componentList="componentList"
      />
    </div>
  </template>
      
  
  
    <script>
  // DON'T TOUCH
  import DiagramComponent from "@/components/Diagram.vue";
  import TitleComponent from "@/components/TitlePage.vue";
  
  export default {
    name: "PageProcessus", // Nom à changer
    data() {
      return {
        //Texte à remplir
        Title: {
          mainTitle:
            "Cadre Légal et Réglementaire",
          subTitle: "Gestion Réunion",
          subSubTitle: "Processus Réunion de pôle hebdomadaire",
          supervisor: "Conseil d'Administration",
          derniere_modif: "26/02/2024 par Bastien HUET",
          image :require("@/assets/clr.png")

        },
  
        // Exemple des différents type de nœuds
        nodes: [
          {
            key: "0",
            text: 'Réunion de pôle à effectuer',
            type: "Start", // Met en vert et de forme elliptique (genre ovale)
          },
          {
            key: "1",
            text: "Avant la réunion de pôle hebdomadaire",
            component: "0",
            supervisor: "Responsable de pôle",
          },
          {
            key: "2",
            text: "Pendant la réunion de pôle hebdomadaire",
            component: "1",
            supervisor: "Responsable de pôle",
          },
          {
            key: "3",
            text: "Après la réunion de pôle hebdomadaire",
            component: "2",
            supervisor: "Responsable de pôle",
          },
       
          {
            key: "4",
            text: "Réunion de pôle effectuée ",
            type: "End",

          },
        ],
  
        linkData: [
          {
            from: "2",
            to: "1",
            Emplacement: 1,
            Espace: "grand",
          },
          {
            from: "4",
            to: "3",
            Emplacement: 1,
            Espace: "grand",
          },
       
        ],
        componentList: [
          // détails avant la réu de pôle
          {
        number: "0",
        Name: "Procédure: Avant la réunion de pôle hebdomadaire",
        infosList: {
          items: [
            "Prévoir plusieurs dates pour la réunion.",
            "Prévoir l'ordre du jour.",
            "Prévenir les membres du groupe sur Telegram et choisir la date qui convient.",
            "Annoncer la date.",
            "Faire un rappel la veille.",
            "Faire un rappel le jour même."
          ],
        },
        },
        //détails pendant la réu de pôle
        {
          number: "1",
          Name: "Procédure: Pendant la réunion de pôle hebdomadaire",
          infosList:{
            items:[
              "Prendre la preuve. ( photo des membres présents à la réunion).",
              "Faire un point sur les tâches effectuées depuis la réunion précédente.",
              "Suivre l'odre du jour.",
              "Répartir les tâches à faire pour la prochaine réunion.",
              
            ],
          },
        },
        //détails après la réu de pôle
        {
          number:"2",
          Name: "Procédure: Après la réunion de pôle hebdomadaire",
          infosList:{ 
            items:[ 
              "Ecrire le Compte-Rendu et le mettre sur le drive. ",
              "Mettre la preuve sur le drive.",

            ],
          },
        },
      ],
      };
    },
    components: {
      DiagramComponent,
      TitleComponent,
    },
    methods: {
      goBack() {
        this.$router.go(-1);
      },
    },
  };
  </script> 