<!-- DON'T TOUCH -->
<template>
    <div>
      <button class="return-button" @click="goBack">Retour</button>
      <TitleComponent :Title="Title" />
      <DiagramComponent
        :format="Format"
        :nodes="nodes"
        :linkData="linkData"
        :componentList="componentList"
      />
    </div>
  </template>
      
  
  
    <script>
  // DON'T TOUCH
  import DiagramComponent from "@/components/Diagram.vue";
  import TitleComponent from "@/components/TitlePage.vue";
  
  export default {
    name: "PageProcessus", // Nom à changer
    data() {
      return {
        //Texte à remplir
        Title: {
          mainTitle:
            "Suivi du Management par la Qualité (SMQ)",
          subTitle: "Procédure: Création du PDA",
          subSubTitle: "",
          supervisor: "Responsable Qualité",
          derniere_modif: "21/03/2024",
        },
  
        // Exemple des différents type de nœuds
        nodes: [
          {
            key: "0",
            text: 'La stratégie a été créée',
            type: "Start", // Met en vert et de forme elliptique (genre ovale)
          },
          {
            key: "0",
            text: "Chaque responsable du nouveau mandat doit lire la stratégie de la présidence",
         
          },
          {
            key: "2",
            text: "Chaque responsable du nouveau mandat doit lire les books de passation de son poste",
      
            
          },
          {
            key: "3",
            text: " Chaque responsable du nouveau mandat doit lire sa fiche de poste",
         
        
          },
          { key: "4", 
            text: " Le futur responsable doit trouver des objectifs et des actions visant à améliorer le pôle pour atteindre les objectifs fixés par la stratégie",
            supervisor: "Conseil d'Administration",
          
         },
          {
            key: "5",
            text: " Les objectifs doivent être SMART avec des indicateurs pertinants",
            supervisor: "Conseil d'Administration",
            component: "0"
          },
          { key: "6", 
          text: " Les objectifs ne doivent pas reprendre des éléments de la fiche de poste " 
        },
          {
            key: "7",
            text: " Remplir le Doc-type du PDA ( Gsheet )",
            supervisor: "Conseil d'Administration",
            
          },
          {
            key: "8",
            text: " évaluer la Priorité et la Gravité avec la matrice de risque",
            supervisor: "Conseil d'Administration",
            component:"1"
            
          },
          {
            key: "9",
            text: " Définir le budget nécessaire à la réalisation de l'objectif",
            supervisor: "Conseil d'Administration",
            
          },

          {
            key: "10",
            text: " Faire valider le PDA par le mandat sortant ",
          },
          {
            key: "11",
            text: "Le PDA est prêt ",
            type: "End",
          },

        ],
  
        linkData: [
          {
            from: "2",
            to: "1",
            Emplacement: 1,
            Espace: "grand",
          },

        ],
        componentList: [
          {
            number: "0",
            Name: " Objectif SMART",
            infosList: {
              items: [
                " Spécifique: l'objectif est précis et sans ambiguité",
                " Mesurable: l'indicateur est facilement mesurable ",
                " Ambitieux",
                " Réalisable",
                " Temporel: l'objectif doit être délimité dans le temps (date de début et date de fin où l'ojectif est atteind)"
              ],
            },
          },
          {
            number: "1",
            Name: "Evaluation des objectif",
            infosList: {
              items: [
                " Priorité : l'importance de l'objectif au niveau du délais de réalisation ",
                " Gravité: Impact de l'objectif sur la JE",
                " Criticité: Priorité x Gravité"
              ],
              
            },
          },
          {
            number: "2",
            Name: "Procédure: Formations générales",
            infosList: {
              items: [
                " Présentation générale de la JE et explication des dates du RFP ",
                " Formation Cadre Légal et Réglementaire ",
                " Formation à la prospection",
                " Formation au Suivi d'étude",
                " Formation à la Gestion associative et Suivi RH",
                " Formation Performance et Qualité en JE",
                " Formation Présentation SI",
                " Formation Communication en JE",
                " Formation La trésorerie pour tous",
              ],
              
            },  
        },
        {
            number: "3",
            Name: "Procédure: Entretien personnel et collectif",
            infosList: {
              items: [
                " Entretien personnel: Avec 2 membres du CA, le but est de tester les connaissances générales du candidat sur la JE et le mouvement et tester sa motivation ",
                " Entretien personnel: Utiliser le questionnaire entretien",
                " Entretien collectif: donner une mise en situation au groupe de candidat dans lequel après 10 min de réflexion de groupe, une préssentation est faite aux membres du CA",
                " Entretien collectif: Permet de mettre en évidence les caractères naturels des candidats ( leader, suiveur, timide, prise d'initiative, capacités de communication et de raisonnement)",
              ],
              
            },
          },
          {
            number: "4",
            Name: "Procédure: Formations spécifiques",
            infosList: {
              items: [
                " Former les candidats sur toutes les missions spécifiques aux pôle",
                " Mise a l'épreuve des candidats sur chaque formation ( les exercices doivent être notés)",
              ],
              
            },
          },
          {
            number: "5",
            Name: "Procédure: Binômage",
            infosList: {
              items: [
                " Intégration des pioux sélectionné dans les pôles",
                " Chaque pioux est mis en binôme avec un membre du pôle",
                " Touts les aspects/missions propre aux pôle doivent être abordés",
                " Les pioux effectuent les missions du chargé et le binôme associé surveille le travail et donne les conseils.",
                " Chaque élément abordé par le pioux doit être renseigné dans la fiche de binômage pour certifier de la passation de connaissance",
              ],
              
            },
          },
        ],
      };
    },
    components: {
      DiagramComponent,
      TitleComponent,
    },
    methods: {
      goBack() {
        this.$router.go(-1);
      },
    },
  };
  </script> 