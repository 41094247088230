<template>
    <!-- on affiche les catégories -->
    <categories-component :items="items" />
  </template>
  
  <script>
  import CategoriesComponent from "@/components/Categories.vue";
  export default {
    name: "comptabiliteView",
    data() {
      return {
        // Exemple de données pour les nœuds
        items: [
        //   {
        //     title: "Expert Comptable",
        //     link: "/expert_comptable",
        //     responsables: "Pôle Treso",
        //   },
        //   {
        //     title: "Tenir sa comptabilité",
        //     subtitle: "Principes de la comptabilité, Documents comptables en JE",
        //     link: "/tenir_sa_comptabilite",
        //     responsables: "Pôle Treso",
        //   },
        //   {
        //     title: "Contrôler sa comptabilité",
        //     subtitle: "ERB, Lettrage",
        //     link: "/controler_sa_comptabilite",
        //     responsables: "Pôle Treso, Présidence, Pôle Etude",
        //   },
          {
            title: "Clôture comptable",
            link: "/cloture_comptable",
            responsables: "Pôle Treso",
          },
        ],
      };
    },
    components: {
      CategoriesComponent,
    },
  };
  </script>