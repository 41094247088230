<!-- Copyright (C) 2023 Nesrine ABID, Nadjime BARTEAU, Mathieu DUPOUX, Léo-Paul MAZIÈRE, Maël PAUL, Antoine RAOULT, Lisa VEILLAT, Marine VOVARD

Authors: Nesrine ABID, Nadjime BARTEAU, Mathieu DUPOUX, Léo-Paul MAZIÈRE, Maël PAUL, Antoine RAOULT, Lisa VEILLAT, Marine VOVARD
Maintainer: contact@junior-aei.com

This file is part of LATIME.

LATIME is free software: you can redistribute it and/or modify it under the terms of the GNU Affero General Public License as published by the Free Software Foundation, either version 3 of the License, or (at your option) any later version.

LATIME is distributed in the hope that it will be useful, but WITHOUT ANY WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License along with LATIME. If not, see <https://www.gnu.org/licenses/>. -->
<template>
<PanelMenuComponent :items="items" :links="links"/>
</template>



<script>
import PanelMenuComponent from '@/components/LeftBarComponent.vue'

import clrImage from '@/assets/clr.png'; //Si vous ne trouvez pas le bon icone
export default {
  name: "NavBarComponent",
  components: {
		PanelMenuComponent
	},
  data() {
    return {
      items:[
      {
          label: "Cadre Légal et réglementaire",
          image: clrImage,
          route: "/clr",
          items: [
            {
              icon: "pi pi-fw pi-bars",
              label: "Chartes et conventions",
              route: "/chartes_conventions",
            },
          ],
        },
        {
          label: "Tresorerie",
          icon: "pi pi-fw pi-euro",
          items: [
            {
              label: "Social",
            },
            {
              label: "Fiscal",
            },
            {
              label: "Achats",
            },
            {
              label: "Ventes",
            },
            {
              label: "Transversal",
            },
          ],
        },
        ]
      ,
      links :[
      {
          
          label: "Liens Utiles",
          icon: "pi pi-fw pi-bars",
          target: "_blank",
          
        },
      {
          
          label: "ERP",
          icon: "pi pi-fw pi-external-link",
          url: "https://erp.junior-aei.com",
          target: "_blank",
          
        },
        {
          
          label: "Kiwi Légal",
          icon: "pi pi-fw pi-external-link",
          url: "https://legal.junior-entreprises.com/",
          target: "_blank",
          
        },

      ]
    };
  }
};
</script>
