<template>
    <!-- on affiche les catégories -->
    <categories-component :items="items" />
  </template>
  
  <script>
  import CategoriesComponent from '@/components/Categories.vue';
  export default {
    name: "chartes_conventions",
    data() {
      return { items : [
        {
          title: 'Relecture en Treso',
          link: '/relectureTreso',
          responsables : "Tresorier ou Vice Tresorier"
      },
      {
          title: 'Création Budget',
          link: '/creaBudget',
          responsables : "Tresorier et Ancien Tresorier"
      },
      {
          title: 'Actualisation du Budget Previsionnel',
          link: '/actualisation',
          responsables : "Tresorier"
      }
      ],
      };
    },
    components: {
      CategoriesComponent,
    },
  };
  </script>
  