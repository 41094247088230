<template>
      
  <div class="list-container">
    <button class="return-button" @click="handleReturnClick">Retour</button>
  <TitleComponent :Title="Title" />
    <div v-if="showText" v-html="renderMarkdown(infos.texte_before)" ></div>  
    <img v-if="showImg" :src="infos.image" id="image_list" alt="Image Non Trouvée" />
    <div v-for="(item, index) in infos.items" :key="index" style="display: flex; align-items: center;">
      <input
        type="checkbox"
        :id="'checkbox' + index"
        :value="item"
        v-model="checkedItems"
      />
      <label :for="'checkbox' + index" v-html="renderMarkdown(item)" ></label>

    </div>
    <div v-if="areAllChecked">
      {{infos.texte_if_all }}
      <a :href="infos.link_if_all" target="_blank"
        >{{infos.texte_link}}</a
      >
  </div>
    
  </div>
</template>
  
  <script>

import MarkdownIt from 'markdown-it';
import TitleComponent from "@/components/TitlePage.vue";
export default {
  props: {
    Title : Object,
    infos: {
      items: {
        type: Array,
      },
      texte_if_all: String,
      link_if_all: {
        type: String,
      },
      texte_link: {
        type: String,
        default: "lien",
      },
      image: String,
      texte_before : String,
    },
  },
  data() {
    return {
      checkedItems: [],
      link : String,
      showImg : false,
      showText : false,

    };
  },
  created (){
    this.show()
  },
  watch: {
    infos:'show'
  },

  computed: {
    areAllChecked() {
      return this.checkedItems.length === this.infos.items.length;
    },
  },
  components: {
    TitleComponent,
  },
  methods: {
    renderMarkdown(text) {
      // Création d'une instance de markdown-it
      const md = new MarkdownIt();
      // Convertir le texte Markdown en HTML
      return md.render(text);
    },
    show(){
      if (this.infos.image){
        this.showImg= true
      }
      else {
        this.showImg= false
      }
      if (this.infos.texte_before){
        this.showText= true
      }
      else {
        this.showText= false
      }
    },
    handleReturnClick() {
      // Émettre un événement personnalisé
      this.$emit('Click-close');
    }
  }
};
</script>
<style>
#image_list {
    max-height: 30vh;
    margin-left: 10px;
    margin-bottom: 20px;
}
.list-container {
  overflow: scroll;
  max-height: 70vh;
}
</style>
