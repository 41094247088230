<!-- DON'T TOUCH -->
<template>
    <div>
      <button class="return-button" @click="goBack">Retour</button>
      <TitleComponent :Title="Title" />
      <DiagramComponent
        :format="Format"
        :nodes="nodes"
        :linkData="linkData"
        :componentList="componentList"
      />
    </div>
  </template>
      
  
  
    <script>
  // DON'T TOUCH
  import DiagramComponent from "@/components/Diagram.vue";
  import TitleComponent from "@/components/TitlePage.vue";
  
  export default {
    name: "das2Processus", // Nom à changer
    data() {
      return {
        //Texte à remplir
        Title: {
          mainTitle:
            "Trésorerie",
          subTitle: "Social",
          subSubTitle: "Processus: DAS2",
          supervisor: "Trésorier",
          derniere_modif: "03/04/2024 par Mathieu CHAILLON",
        },
  
        // Exemple des différents type de nœuds
        nodes: [
          {
            key: "0",
            text: 'DAS2 à faire',
            type: "Start", // Met en vert et de forme elliptique (genre ovale)
          },
          {
            key: "1",
            text: "Suivre le TUTO de la CNJE",
          },
          {
            key: "1",
            text: "Le Tableau de suivi doit être à jour et COMPLET",
          },
          {
            key: "2",
            text: "Rassembler les intervenants et les personnes à qui AEI a versé plus de 1200€ TTC (Attention Experte Comptable)",
            supervisor: "Pole Trésorerie"
          },
          {
            key: "3",
            text: "Rassembler dans un tableau les infos suivantes : \n Nom \n Adresse \n Montant total des honoraires net",
            supervisor: "Trésorier",
          },
          {
            key: "4",
            text: "Remplir selon le tuto de la CNJE avant le 31 janvier",
          },
          { key: "4", 
            text: "Vérification et validation",
            supervisor: "Vice-Trésorier",
          },
          {
            key: "5",
            text: "Envoie de la déclaration \n ATTENTION à Faire avant le 31 janvier ",
            supervisor: "Trésorier",
          },
          { key: "6", 
          text: "Conserver le Compte-Rendu de saisie sur la Dropbox et dans les classeurs correspondants ainsi que le tableau de préparation" ,
          supervisor: "Trésorier",
          },
         
          {
            key: "7",
            text: "La déclaration a été faite",
            type: "End",

          },

        ],
  
        linkData: [
       {

       }
        ],
        
        componentList: [
      ],
      };
    },
    components: {
    DiagramComponent,
    TitleComponent,
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};

</script> 