<!-- DON'T TOUCH -->
<template>
    <div>
      <button class="return-button" @click="goBack">Retour</button>
      <TitleComponent :Title="Title" />
      <DiagramComponent
        :format="Format"
        :nodes="nodes"
        :linkData="linkData"
        :componentList="componentList"
      />
    </div>
  </template>
      
  
  
    <script>
  // DON'T TOUCH
  import DiagramComponent from "@/components/Diagram.vue";
  import TitleComponent from "@/components/TitlePage.vue";
  
  export default {
    name: "PageProcessus", // Nom à changer
    data() {
      return {
        //Texte à remplir
        Title: {
          mainTitle:
            "Cadre Légal et Réglementaire",
          subTitle: "Chartes et Conventions",
          subSubTitle: "Processus: Stages",
          supervisor: "Présidence, Secrétaire Général,Trésorier",
          derniere_modif: "27/02/2024 par Bastien HUET",
          image :require("@/assets/clr.png")

        },
  
        // Exemple des différents type de nœuds
        nodes: [
          {
            key: "0",
            text: 'Poste de stagiaire disponible',
            type: "Start", // Met en vert et de forme elliptique (genre ovale)
          },
          {
            key: "1",
            text: "définition de la mission du stage",
            supervisor: "President,Secrétaire Général, Trésorier",
          },
          {
            key: "2",
            text: "Annonce de recrutement sur les cannaux Admis et Telegram",
            supervisor: "Pôle Communication",
          },
          {
            key: "3",
            text: "Entretien et vérification de la compatibilité du candidat à la mission",
            supervisor: "President,Secrétaire Général",
          },
          {
            key: "4",
            text: "Vote en CA des modalités du stage",
            supervisor: "Conseil d'Administration",
          },
          {
            key: "5",
            text: "Rédaction de la convention de stage ",
            supervisor: "Présidence, Secrétaire Général, Trésorier",
          },
          {
            key: "6",
            text: "Signature de la convention de stage par les 3 parties: Stagiaire, Président (ou Trésorier si le président effecture le stage), Directeur de l'école",
            supervisor: "Présidence, Secrétaire Général, Trésorier",
          },
          {
            key: "7",
            text: "Réalisation du Stage ",
            supervisor: "Présidence",
          },
          {
            key: "8",
            text: "Signature de l'attestation de fin de stage",
            supervisor: "Présidence",
          },
          {
            key: "9",
            text: "Rémunération du stagiaire",
            supervisor: "Présidence,Trésorier",
          },
          {
            key: "10",
            text: "Fin du stage",
            type: "End",

          },
        ],
  
        linkData: [
          {
            from: "2",
            to: "1",
            Emplacement: 1,
            Espace: "grand",
          },
          {
            from: "4",
            to: "3",
            Emplacement: 1,
            Espace: "grand",
          },
          {
            from: "6",
            to: "5",
            Emplacement: 1,
            Espace: "grand",
          },
          {
            from: "8",
            to: "7",
            Emplacement: 1,
            Espace: "grand",
          },
          {
            from: "10",
            to: "9",
            Emplacement: 1,
            Espace: "grand",
          },
       
        ],
        componentList: [

      ],
      };
    },
    components: {
      DiagramComponent,
      TitleComponent,
    },
    methods: {
      goBack() {
        this.$router.go(-1);
      },
    },
  };
  </script> 