<template>
    <div>
      <button class="return-button" @click="goBack">Retour</button>
      <TitleComponent :Title="Title" />
      <DiagramComponent
        :format="Format"
        :nodes="nodes"
        :linkData="linkData"
        :componentList="componentList"
      />
    </div>
  </template>
      
    <script>
  import DiagramComponent from "@/components/Diagram.vue";
  import TitleComponent from "@/components/TitlePage.vue";
  
  export default {
    name: "QualiteView",
    data() {
      return {
        Format: "vertical",
  
        Title: {
          mainTitle: "Qualité",
          subTitle: "Suivi Management par la Qualité",
          subSubTitle: "Processus: Suivi Management par la Qualité",
          supervisor: "Responsable Qualité, Conseil d'Administration",
          derniere_modif: "01/03/2024 par Enzo CHAMPAGNE"
        },
  
        // Exemple de données pour les nœuds
        nodes: [
          {
            key: "0",
            text: "Aucun suivi de la structure",
            type: "Start",
          },
          {
            key: "1",
            text: "Le Président définit la stratégie",
            supervisor: "Président",
            link:'definition_strategie',
            noLinkauto: true,
          },
          {
            key: "2",
            text: "Les membres du Conseil d'Administration définissent leurs PDA respectifs",
            link: 'creation_pda',
            supervisor: "Conseil d'Administration"
          },
          {
            key: "3",
            text: "Les responsables de pôles remplissent les indicateurs de leurs PDA à chaque début de mois",
            noLinkauto: true,
            supervisor: "Conseil d'Administration"
          },
          {
            key: "4",
            text: "Revues de processus des PDA de pôles",
            noLinkauto: true,
            supervisor: "Pôle Qualité"
          },
          {
            key: "5",
            text: "Relecture par la Qualité, la Présidence et les responsables de pôles",
            supervisor: "Pôle Qualité, Conseil d'Administration"
          },
          { key: "6", text: "Signature des revues par les responsables de pôles", supervisor: "Conseil d'Administration" },
          
          {
            key: "7",
            text: "Compte-rendu des revues en CA",
            supervisor: "Conseil d'Administration"
          },
          { key: "8", text: "Analyse des Risques sur les Processus", supervisor: "Pôle Qualité", component : "0"},
          { key: "9", text: "Vérification et Modification des risques non pertinents", supervisor: "Pôle Qualité"},
          { key: "10", text: "Préparation et collecte des informations pour la revue de Direction", noLinkauto: true, supervisor: "Pôle Qualité"},
          {
            key: "11",
            text: "Rédaction de la première partie de la revue de Direction",
            supervisor: "Pôle Qualité"
          },
          {
            key: "12",
            text: "Présentation de la revue de Direction lors d'une réunion dédiée avec le CA",
            supervisor: "Conseil d'Administration"
          },

          {
            key: "13",
            text: "Finalisation de la revue de Direction",
            supervisor: "Pôle Qualité"
          },

          {
            key: "14",
            text: "Relecture et signature de la revue de Direction",
            supervisor: "Pôle Qualité, Conseil d'Administration"
          },

          {
            key: "15",
            text: "Mise en place des améliorations proposées pendant la réunion",
            supervisor: "Conseil d'Administration"
          },

          {
            key: "16",
            text: "Amélioration continue de la structure",
            type: "End",
          },
        ],
  
        linkData: [
          {
            from: "0",
            to: "1",
            Emplacement: 1,
            label: "Avant l'entrée en mandat",
            Espace: "grand",
          },
          {
            from: "2",
            to: "3",
            Emplacement: 1,
            label: "Tous les mois",
            Espace: "grand",
          },
          {
            from: "3",
            to: "4",
            Emplacement: 1,
            label: "Tous les 3 mois",
            Espace: "grand",
          },
          {
            from: "5",
            to: "4",
            Emplacement: 1,
            label: "Document à corriger",
            bySide: true,
            Espace: "petit",
            decalageSup: 20,
          },
          {
            from: "9",
            to: "10",
            label: "Tous les 6 mois",
            Espace: "grand",
          },
          {
            from: "9",
            to: "10",
            label: "Tous les 3 mois",
            Espace: "grand",
          },
        ],
        componentList: [
          {
            number: "0",
            Name: "Procédure : Analyse des Risques",
            infosList: {
              items: [
              " Identifier la partie prenante et l'événement redouté",
              " Calculer la criticité de l'événement (produit de l'Impact et de la Vraisemblance)",
              " Identifier les causes et les conséquences de l'événement",
              " Proposer des actions pour réduire le risque encouru ",
              " Calculer la nouvelle criticité suite aux actions proposées (produit du nouvel Impact et de la nouvelle Vraisemblance)",
              ],
            },
          },
        ],
      };
    },
    components: {
      DiagramComponent,
      TitleComponent,
    },
    methods: {
      goBack() {
        this.$router.go(-1);
      },
    },
  };
  </script> 