<template>
  <ListCocheComponent :infos="infos" :Title="Title"  @Click-close="goBack"/>
</template>

<script>
import ListCocheComponent from "@/components/ListeValidation.vue";
export default {
    name: "preparation_offre_list",
  components: {
    ListCocheComponent,
  },
  data() {
    return {

      Title: {
        mainTitle: "Cadre Légal et Réglementaire",
        subTitle: "Chartes et Conventions",
        subSubTitle: "Procédure: Evaluation et suivi",
        supervisor: "Présidence, Trésorier",
      },

      infos : {
       items: [
            ' Evaluer les résultats obtenus par votre Junior-Entreprise et l\'entreprise partenaire.',
            ' Collecter les retours d\'expérience de l\'entreprise partenaire.',
            ' Préparer un rapport détaillé sur la collaboration, y compris les résultats, les leçons apprises et les recommandations.',
            ' Présenter le rapport à l\'entreprise partenaire et discuter des résultats.',
            ' Utiliser les retours pour améliorer les futurs partenariats et renforcer les processus de notre Junior-Entreprise'
        ]}
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
