// Dans votre fichier de composant Vue (par exemple, JointDiagram.vue)

<template>
  <div class="diagram-container">
    <div ref="placeholder"></div>
  </div>
</template>

<script>
import * as joint from "jointjs";

export default {
  name: "JointDiagram",
  props: {
    nodes: Array,
    linkData: Array,
  },
  mounted() {
    this.createDiagram();
  },
  methods: {
    navigateToView(routeName) {
      this.$router.push({ name: routeName }); // Use the name of your route
    },
    getTextSize(text, fontSize = "14px", fontFamily = "Inter-Regular") {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      context.font = `${fontSize} ${fontFamily}`;
      return context.measureText(text).width;
    },
    createDiagram() {
      const graph = new joint.dia.Graph();
      const paper = new joint.dia.Paper({
        el: this.$refs.placeholder,
        model: graph,
        width: 600, // Largeur du papier
        height: 1000,
        gridSize: 10,
        interactive: false,
      });

      let createdRectsIds = [];

      // Exemple pour créer un rectangle
      for (let i = 0; i < this.nodes.length; i++) {
        let rect = new joint.shapes.standard.Rectangle();
        const text = joint.util.breakText(this.nodes[i].text, { width: 200 });
        let textSize = this.getTextSize(text);

        let textHeight = 1;
        if (textSize > 200) {
          textHeight = textSize / 200;
          textSize = 200;
        }
        let x = this.nodes[i].x - (textSize + 20) / 2;
        let y = this.nodes[i].y;
        rect.position(x, y); // Positionne chaque rectangle horizontalement
        rect.resize(textSize + 20, 20 * textHeight + 20);
        rect.attr({
          body: {
            fill: this.nodes[i].Backcolor,
            rx: this.nodes[i].courbure,
            ry: this.nodes[i].courbure,
          },
          label: {
            text: text,
            fill: "black",
            fontFamily: "Inter-Regular",
            fontSize: "14px",
          },
        });


        // Endroit où on récup les infos 
        if (this.nodes[i].link) {
          rect.prop("link", { name: this.nodes[i].link });
        }
        if (this.nodes[i].external_link) {
          rect.prop("external_link", { name: this.nodes[i].external_link });
        }
        if (this.nodes[i].component) {
          rect.prop("component", { name: this.nodes[i].component });
        }


        rect.addTo(graph); // Ajouter d'abord le rect principal au graphe

        if (this.nodes[i].supervisor){
        let miniRect = new joint.shapes.standard.Rectangle();
        let textSizeSuperVisor = this.getTextSize(this.nodes[i].supervisor );
        miniRect.position(x + (textSize)/2 +5  - textSizeSuperVisor/2, y +20 * (textHeight+1)); // Position à l'intérieur du rect
        miniRect.resize(textSizeSuperVisor+10, 20);
        miniRect.attr({
          body: {
            fill: "gray",
          },
          label: {
            text: this.nodes[i].supervisor,
            fill: "white",
            fontSize: 12,
          },
        });
        miniRect.addTo(graph);// Puis ajouter le miniRect
        rect.embed(miniRect); // Intégrer le miniRect dans le rect
        miniRect.toFront();

      }
         

        createdRectsIds.push(rect.id);
      }

      // Exemple pour créer des liens entre les rectangles

      for (let i = 0; i < this.linkData.length; i++) {
        let actualLink = this.linkData[i];
        let text = actualLink.label;
        if (actualLink.label) {
          text = joint.util.breakText(text, { width: actualLink.maxWidth });
        }
        const link = new joint.shapes.standard.Link({
          source: {
            id: createdRectsIds[actualLink.from],
          },
          target: {
            id: createdRectsIds[actualLink.to],
          },
          attrs: {
            line: {
              stroke: "#4b4a67",
              strokeWidth: 2,
              targetMarker: {
                type: "path",
                d: "M 10 -5 0 0 10 5 z",
                fill: "#4b4a67",
              },
            },
          },
          labels: [
            {
              attrs: {
                text: {
                  text: text || "", // Remplacez par votre texte
                  fill: "#333333", // Couleur du texte
                  fontSize: 12, // Taille de la police
                  fontFamily: "Inter-Regular", // Police de caractères
                },
              },
              position: {
                distance: 0.5, // Position le label au milieu du lien (0.5)
                // Vous pouvez ajuster cette valeur entre 0 et 1 pour déplacer le label le long du lien
                offset: { x: actualLink.offX, y: actualLink.offY }, // Décale le label verticalement de 20px
              },
            },
          ],
        });

        if (actualLink.fromSpot == "left" &&
          actualLink.toSpot === "left") {
          link.source({
            id: createdRectsIds[actualLink.from],
            anchor: {
              name: actualLink.fromSpot,
            },
          }),
            link.target({
              id: createdRectsIds[actualLink.to],
              anchor: {
                name: actualLink.toSpot,
              },
            }),
            link.router({
              name: "manhattan",
              args: {
                startDirections: [actualLink.fromSpot],
                endDirections: [actualLink.toSpot],
                step: actualLink.decLeft // Ajustez cette valeur pour obtenir des segments horizontaux plus longs
              },
            });
        }

        if (
          actualLink.from === actualLink.to &&
          actualLink.fromSpot === "bottom" &&
          actualLink.toSpot === "left"
        ) {
          const rect = graph.getCell(createdRectsIds[actualLink.from]);
          const rectPosition = rect.position();
          const rectSize = rect.size();
          link.vertices([
            {
              x: rectPosition.x + rectSize.width / 2,
              y: rectPosition.y + rectSize.height + 30,
            },
            {
              x: rectPosition.x - 30,
              y: rectPosition.y + rectSize.height + 30,
            },
          ]);
        }

        link.addTo(graph);
        link.toBack();
      }
      paper.on("cell:pointerclick", (eventObject) => {
        if (eventObject.model && eventObject.model.prop) {
          const link = eventObject.model.prop("link");
          const component = eventObject.model.prop("component");
          const external_link = eventObject.model.prop("external_link");

          if (link && link.name) {
            this.$router.push({ name: link.name });
          }
          if (component && component.name) {
            this.$emit("Click-component", component.name);
          }
          if (external_link && external_link.name) {
            window.open(external_link.name, "_blank");

          }
        }
      });
      paper.fitToContent({ padding: 100 });
    },
  },
};
</script>

<style>
.diagram-container {
  overflow: scroll;
  width: 42vw;
  max-height: 70vh;
}
.joint-label {
  pointer-events: none;
}

</style>
