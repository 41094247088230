<!-- DON'T TOUCH -->
<template>
  <div>
    <button class="return-button" @click="goBack">Retour</button>
    <TitleComponent :Title="Title" />
    <DiagramComponent :format="Format" :nodes="nodes" :linkData="linkData" :componentList="componentList" />
  </div>
</template>



<script>
// DON'T TOUCH
import DiagramComponent from "@/components/Diagram.vue";
import TitleComponent from "@/components/TitlePage.vue";

export default {
  name: "dadsProcessus", // Nom à changer
  data() {
    return {
      //Texte à remplir
      Title: {
        mainTitle:
          "Trésorerie",
        subTitle: "Transversal",
        subSubTitle: "Processus: Création de Budget",
        supervisor: "Trésorier",
        derniere_modif: "20/03/2024",
      },

      // Exemple des différents type de nœuds
      nodes: [
        {
          key: "0",
          text: 'Créer le Budget',
          type: "Start", // Met en vert et de forme elliptique (genre ovale)
        },
        {
          key: "1",
          text: "Présentaion du Fonctionnement du TS par le mandat N au mandat N+1",
        },
        {
          key: "2",
          text: "Téléchargé le TS à jour sur le Drive de la CNJE et le paramétrer ",
          supervisor: "Trésorier actuel et Nouveau Trésorier",
          component: 1
        },
        {
          key: "3",
          text: "Sondage auprès des diférents poles et membres du bureau lors de l'élaboration des PdA du Mandat Entrant sur les budgets nécessaires",
          supervisor: "Trésorier entrant",
          addDecX: -300
        },
        {
          key: "4",
          text: "Décider des cut-offs et les renseignés sur le TS N et N+1",
          direction: "right",
          supervisor: "Pole Trésorerie entrant et sortant",
          noLinkauto: true
        },
        {
          key: "4",
          text: "Analyser les Dépenses/Produits du mandat sortant (Remplir avec la colonne N-1 de l'onglet budget du Nouveau  TS en regardant le résultat après cut off ou budget après atterrissage)",
          direction: "right",
          supervisor: "Trésorier entrant",
          noLinkauto: true
        },
        {
          key: "4",
          text: "Vérifier les évolutions sur ces dépenses",
          supervisor: "Trésorier entrant",
          addDecY: 50
        },
        {
          key: "5",
          text: "Discussion de la stratégie du Mandat entrant et du résultat visé",
          supervisor: "Trésorier Entrant et CA Entrant",
          indPrec: 4,
          addDecY: 150
        },
        {
          key: "6",
          text: "Création de la Première version du budget (Consigne en cliquant)",
          component: 2,
          supervisor: "Trésorier Entrant",
        },
        {
          key: "6",
          text: "Retour et relecture par le Trésorier sortant (Infos à vérifier en cliquant)",
          component: 3,
          supervisor: "Trésorier Sortant et Trésorier Entrant",
        },

        {
          key: "6",
          text: "Présentation du Budget au 1er CA du Mandat (Conseil en cliquant)",
          component: 3,
          supervisor: "Trésorier Entrant",
        }, {
          key: "7",
          text: "Budget Validé",
          type: "End",

        },
        {
          key: "6",
          text: "Rajouter au CR de CA la page budget en PDF exporter depuis le Tableau de Suivi et l'archiver sur le Drive",
          supervisor: "Trésorier Entrant et Sec Gen ",
        },
        {
          key: "7",
          text: "Budget  Non Validé",
          type: "End",
          direction: "right",
          indPrec : 11,
          noLinkauto : true

        },
        {
          key: "6",
          text: "Faire les modifications",
          supervisor: "Trésorier Entrant",
          addDecY: -300
        },



      ],

      linkData: [
        {
          from: 10,
          to: 13
        },
        {
          from: 14,
          to: 10
        },
        {
          from: 6,
          to: 7
        },
        {
          from: 3,
          to: 7
        },
        {
          from: 2,
          to: 4
        },
        {
          from: 2,
          to: 5
        }
      ],

      componentList: [
        {

        },

        {
          number: "1",
          Name: "Paramétrage et Téléchargement Nouveau TS",
          texteMarkdown: `**Il est super important avant cette étape d'avoir compris l'utilité du TS**`,
          textes: ["1 - Télécharger le nouveau TS sur le drive CNJE [lien en 03/24](https://drive.google.com/drive/folders/1XQn4JT65R1D4ZGM0LvOo0IQZOKe9nRRd?usp=drive_link)",
            "2 - Paramètrer l'onglet Paramétrage infos à estimer ou à demander au Trésorier Sortant",
            "3 - Vérifier l'onglet Taux Cotisations et rajouter notre taux AT (Trouvable dans la lettre CARSAT stockée sur la Dropbox sinon voir la procédure)",
            "4 - Rajouter si le besoin et l'utilité parait intérresante pour le nouveau Trésorier les onglets créés ou modif par le Tresorier sortant (Ex : Onglet calcul Congrès)",
          ]
        },
        {
          number: "1",
          Name: "Création Première version du budget",
          texteMarkdown: `### Il est super important de respecter ces consignes `,
          textes: [`## Paramétrer l'onglet Paramétrages du TS avec :

- le Résultat visé 
- le % de rétribution 
- les 3 hyp de CA :

  - **Basse (Moins que prévu en CA)**
  - **Moyen (Ce que le CA a prévu)** (ATTENTION si l'estimation du CA paraît ahurissante, restez sur une estimation crédible)
  - **Haute (Plus que les estimations)**`,
            `## Ajuster les lignes en fonction des dépenses/produits et infos récupérées à l'étape précédente :

(Attention : Il est important de spécifier et séparer les lignes. Il ne faut pas, par exemple, de ligne "Cohésion", mais plutôt "Activité de cohésion de Septembre"; "Goûter de Juin". Il en va de même pour les frais administratifs et informatiques pour savoir où part vraiment l'argent.)
`,
            `## Remplir les lignes du budget avec les demandes, produits et dépenses nécessaires récoltés précédemment.

**__Attention :__** l'objectif est d'avoir le **même** résultat avec l'ensemble des hypothèses et qu'il soit proche du visé. Cet objectif peut demander des ajustements entre deux dépenses. Pour cela, vous pouvez vous référer au trésorier actuel pour des conseils ou demander l'avis de votre Conseil d'Administration.
`
          ]
        },
        {
          number: "1",
          Name: "Choses à vérif en  tant que Tresorier Sortant",
          texteMarkdown: `**Tache importante pour permettre un bon départ du pole Entrant**`,
          textes: ["1 - Vérifier le respect des consignes (objectif égaux, lignes précises)",
            "2 - Vérifier que les calculs sont égaux (pas de formule Excel cassé)",
            "3 - Surveiller que l'ensemble des dépenses nécessires au bon fonctionnement de AEI sont présentes",
            "4 - Vérifier que ce budget est sécur et permettra une continuité financière de AEI",
          ]
        },
        {
          number: "1",
          Name: "Conseil pour présenter",
          texteMarkdown: `**Il est très important que l'ensemble du CA (respo et bureau) soient au courant du budget (au moins de leur pole)**`,
          textes: ["1 - Faire un diapo propre (Exemple dispo sur le Drive dans 23-24->Trésorerie)",
            "2 - Essayer de bien expliquer les notions qu'on voit en forma Tresorerie (Cotisations, CFE, ...)",
            "3 - Valider les dépenses importantes par tout le monde pour que tout le monde soit au courant de où va l'argent",
            "4 - Bien discuter du CA pour que les respos ActiCo puissent bien comprendre l'objectif",
          ]
        },

      ],
    };
  },
  components: {
    DiagramComponent,
    TitleComponent,
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};

</script>