<!-- DON'T TOUCH -->
<template>
    <div>
      <button class="return-button" @click="goBack">Retour</button>
      <TitleComponent :Title="Title" />
      <DiagramComponent
        :format="Format"
        :nodes="nodes"
        :linkData="linkData"
        :componentList="componentList"
      />
    </div>
  </template>
      
  
  
    <script>
  // DON'T TOUCH
  import DiagramComponent from "@/components/Diagram.vue";
  import TitleComponent from "@/components/TitlePage.vue";
  
  export default {
    name: "dadsProcessus", // Nom à changer
    data() {
      return {
        //Texte à remplir
        Title: {
          mainTitle:
            "Trésorerie",
          subTitle: "Ventes",
          subSubTitle: "Processus: Refacturations",
          supervisor: "Trésorier",
          derniere_modif: "22/03/2024 par Mathieu CHAILLON",
        },
  
        // Exemple des différents type de nœuds
        nodes: [
          {
            key: "0",
            text: 'Refacturation',
            type: "Start", // Met en vert et de forme elliptique (genre ovale)
          },
          {
            key: "1",
            text: "Avant achat lié message d'engagement pour le fait de payer la refacturation",
            supervisor: "Trésorier ou Vice Trésorier",
          },
          {
            key: "1",
            text: "Récupérer les infos de la personne (Nom, Prénom, Adresse)",
            supervisor: "Trésorier ou Vice Trésorier",
          },
          {
            key: "2",
            text: "Prendre le DT de la Refacturation sur le Drive ",
            supervisor: "Trésorier ou Vice Trésorier",
          },
          {
            key: "3",
            text: "Remplir le DT avec les infos",
            supervisor: "Trésorier ou Vice Trésorier",
          },
          { key: "4", 
            text: "Relecture",
            link : "relectureTreso",
            supervisor: "Trésorier ou Vice Trésorier",
        },
          {
            key: "5",
            text: "Renseigner le TS et mettre la Refacturation sur la DropBox ",
            supervisor: "Trésorier ou Vice Trésorier",
          },
          { key: "6", 
          text: "L'envoyer à la personne concernée" ,
          },
          { key: "7", 
          text: "Passage au processus de suivi des créances" ,
          link : "creances"
          },
          
          { key: "6", 
          text: "Rentrer en comptabilité" ,
          component : 1
          },
          {
            key: "7",
            text: "Refacturation émise",
            type: "End",

          },
          {
            key: "7",
            text: "Attention Refacturation au taux de 20 %",
            type: "End",
            direction : "right",
            indPrec : 0,
            noLinkauto : true

          },

        ],
  
        linkData: [
       {

       }
        ],
        
        componentList: [
          {

          },
          
        {
          number: "4",
          Name: "Compta d'une Refacturation",
          images : [require("@/assets/ComptaRefact1.png"),
          require("@/assets/ComptaRefact2.png"),],
          
        
        },
        
      ],
      };
    },
    components: {
    DiagramComponent,
    TitleComponent,
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};

</script> 