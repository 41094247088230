<!-- DON'T TOUCH -->
<template>
    <div>
      <button class="return-button" @click="goBack">Retour</button>
      <TitleComponent :Title="Title" />
      <DiagramComponent
        :format="Format"
        :nodes="nodes"
        :linkData="linkData"
        :componentList="componentList"
      />
    </div>
  </template>
      
  
  
    <script>
  // DON'T TOUCH
  import DiagramComponent from "@/components/Diagram.vue";
  import TitleComponent from "@/components/TitlePage.vue";
  
  export default {
    name: "redaction_rmView", // Nom à changer
    data() {
      return {
        //Texte à remplir
        Title: {
          mainTitle:
            "Activité Commerciale",
          subTitle: "Avant Etude",
          subSubTitle: "Procédure : Premier entretien Intervenant",
          supervisor: "Pôle Activité Commerciale",
          derniere_modif: "28/03/2024 par Enzo CHAMPAGNE",
        },
  
        // Exemple des différents type de nœuds
        nodes: [
          {
            key: "0",
            text: 'Intervenant sélectionné',
            type: "Start",
          },
          {
            key: "1",
            text: "S'assurer que l'Intervenant a cotisé. Si ce n'est pas le cas, lui rappeler et le faire avec lui",
          },
          {
            key: "2",
            text: "Demander à l'Intervenant d'apporter ses documents",
          },
          {
            key: "3",
            text: "Ajouter les documents et une copie du BA au dossier de l'étude",
          },
          {
            key: "4",
            text: "Présenter le Cahier Des Charges, les délais de clôture des étapes et du rendu des livrables à l'Intervenant",
          },
          {
            key: "5",
            text: "Expliquer le déroulement de l'étude",
          },
          {
            key: "6",
            text: "Présenter la répartition des tâches",
          },
          {
            key: "7",
            text: "Rappeler ses obligations à signer le Récapitulatif de Mission et ses éventuels avenants ainsi que le rendu des différents manuels d'utilisation",
          },
          {
            key: "8",
            text: "Suite de l'étude",
            type: "End",
          },
        ],
  
        linkData: [
        ],
        componentList: [
        ],
      };
    },
    components: {
      DiagramComponent,
      TitleComponent,
    },
    methods: {
      goBack() {
        this.$router.go(-1);
      },
    },
  };
  </script> 