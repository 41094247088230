<!-- DON'T TOUCH -->
<template>
    <div>
      <button class="return-button" @click="goBack">Retour</button>
      <TitleComponent :Title="Title" />
      <DiagramComponent
        :format="Format"
        :nodes="nodes"
        :linkData="linkData"
        :componentList="componentList"
      />
    </div>
  </template>
      
  
  
    <script>
  // DON'T TOUCH
  import DiagramComponent from "@/components/Diagram.vue";
  import TitleComponent from "@/components/TitlePage.vue";
  
  export default {
    name: "redaction_BAView", // Nom à changer
    data() {
      return {
        //Texte à remplir
        Title: {
          mainTitle:
            "Activité Commerciale",
          subTitle: "Avant Etude",
          subSubTitle: "Procédure : Rédaction BA",
          supervisor: "Secrétaire Général, Trésorier",
          derniere_modif: "28/03/2024 par Enzo CHAMPAGNE",
        },
  
        // Exemple des différents type de nœuds
        nodes: [
          {
            key: "0",
            text: 'Etudiant non adhérent à la JE',
            type: "Start",
          },
          {
            key: "1",
            text: "Demander à l'étudiant ses informations personnelles",
          },
          {
            key: "2",
            text: "Remplir le document et l'ajouter au classeur des BA et au drive",
          },
          {
            key: "3",
            text: "Proposer à l'étudiant de payer la cotisation par Lydia ou par chèque",
          },
          {
            key: "4",
            text: "Transmettre l'information au Trésorier",
          },
          {
            key: "5",
            text: "Faire signer le BA",
          },
          {
            key: "4",
            text: "L'étudiant est adhérent à AEI",
            type: "End",
          },
        ],
  
        linkData: [
        ],
        componentList: [
        ],
      };
    },
    components: {
      DiagramComponent,
      TitleComponent,
    },
    methods: {
      goBack() {
        this.$router.go(-1);
      },
    },
  };
  </script> 