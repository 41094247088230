<!-- DON'T TOUCH -->
<template>
  <div>
    <button class="return-button" @click="goBack">Retour</button>
    <TitleComponent :Title="Title" />
    <DiagramComponent
      :format="Format"
      :nodes="nodes"
      :linkData="linkData"
      :componentList="componentList"
    />
  </div>
</template>
    


  <script>
// DON'T TOUCH
import DiagramComponent from "@/components/Diagram.vue";
import TitleComponent from "@/components/TitlePage.vue";

export default {
  name: "recrutement_intervenantView", // Nom à changer
  data() {
    return {
      //Texte à remplir
      Title: {
        mainTitle:
          "Activité Commerciale",
        subTitle: "Suivi Intervenant",
        subSubTitle: "Procédure : Recrutement de l'Intervenant",
        supervisor: "Co-responsables Activité Commerciale, pôle Communication, Secrétaire Général",
        derniere_modif: "28/03/2024",
      },

      // Exemple des différents type de nœuds
      nodes: [
        {
          key: "0",
          text: 'Etude sans intervenant',
          type: "Start", // Met en vert et de forme elliptique (genre ovale)
        },
        {
          key: "1",
          text: "Le pôle Activité Commerciale fait un brouillon de l'Appel à Intervenant pour une potentielle étude",
          supervisor: "Pôle Activité Commerciale"
        },
        {
          key: "2",
          text: "Rédaction de l'Appel à Intervenant par le pôle Communication",
          supervisor: "Pôle Communication",
        },
        {
          key: "3",
          text: "Validation par la Qualité",
          supervisor: "Pôle Qualité",
        },
        { key: "4", text: "Publication de l'Appel à Intervenant sur le groupe Admis et Télégram (en excluant explicitement le stagiaire prospect)", supervisor: "Pôle Communication" },
        {
          key: "5",
          text: "Ajout des candidats au groupe Télégram dédié",
        },
        { key: "6", text: "Audition des candidats par le chargé de projet, et/ou un co-respoonsable Activité Commerciale, et/ou le VP", noLinkauto: true, link: "premier_entretien_intervenant"},
        {
          key: "7",
          text: "Sélection du ou des Intervenants",
          noLinkauto: true,
        },
        {
          key: "8",
          text: "Prévenir le Secrétaire Général et le Trésorier",
          noLinkauto: true,
        },
        { key: "9", 
        text: "Le Secrétaire Général rédige le Bulletin d'Adhésion", supervisor: "Secrétaire Général", link: "redaction_BA", },


        { key: "10", text: "Définir un canal de discussion (éviter les mails)"},
        { key: "11", text: "Intervenant recruté", type: "End" },
        {
          key: "12",
          text: "Faire une photocopie du BA et l'ajouter au dossier d'étude",
          indPrec: 8,
          direction: "right",
          noLinkauto: true,
        },
      ],

      linkData: [
        {
          from: "5",
          to: "6",
          Emplacement: 1,
          label: "Pendant 5 jours",
          Espace: "grand",
        },
        {
          from: "6",
          to: "7",
          Emplacement: 1,
          label: "Au bout du 6ème jour",
          Espace: "grand",
        },
        {
          from: "7",
          to: "8",
          Emplacement: 1,
          label: "Intervenant n'ayant \n pas de BA",
          Espace: "grand",
        },
        {
          from: "7",
          to: "12",
          Emplacement: 1,
          label: "Intervenant ayant \n déjà un BA",
          Espace: "grand",
        },
        {
          from: "12",
          to: "11",
          label: "",
          fromSpot: "bottom",
          toSpot: "left",
          Espace: "grand",
        },

      ],
      componentList: [
      ],
    };
  },
  components: {
    DiagramComponent,
    TitleComponent,
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script> 