<!-- DON'T TOUCH -->
<template>
    <div>
      <button class="return-button" @click="goBack">Retour</button>
      <TitleComponent :Title="Title" />
      <DiagramComponent
        :format="Format"
        :nodes="nodes"
        :linkData="linkData"
        :componentList="componentList"
      />
    </div>
  </template>
      
  
  
    <script>
  // DON'T TOUCH
  import DiagramComponent from "@/components/Diagram.vue";
  import TitleComponent from "@/components/TitlePage.vue";
  
  export default {
    name: "call_centerView", // Nom à changer
    data() {
      return {
        //Texte à remplir
        Title: {
          mainTitle:
            "Activité Commerciale",
          subTitle: "Avant Etude",
          subSubTitle: "Processus: Call Center",
          supervisor: "Activité Commerciale",
          derniere_modif: "01/04/2024 par Enzo CHAMPAGNE",
        },
  
        // Exemple des différents type de nœuds
        nodes: [
          {
            key: "0",
            text: 'Avant le Call Center',
            type: "Start", // Met en vert et de forme elliptique (genre ovale)
          },
          {
            key: "1",
            text: "Récupérer des 06 (à privilégier) ou des 05 ou 01 dans le cas des petits commerçants. \n Récupérer entre 5 et 10 numéros.",
          },
          {
            key: "2",
            text: "Remplir le document de préparation \n (Cliquer ici)",
            external_link : "https://docs.google.com/spreadsheets/d/16QdPqZ74Rn1JPzASfJ3Ipwd_YSdykeQEvLEUA-31G_c/edit#gid=0"
          },
          {
            key: "3",
            text: "Tirage au sort pour faire des groupes de 2",
          },
          { key: "4", text: "Installation des groupes dans une salle" },
          {
            key: "5",
            text: "Passer des appels pendant 1h et pour chaque appel terminer de remplir le document",
          },
          { key: "6", text: "Fin du Call Center", type: "End" },
        ],
  
        linkData: [
        ],
        componentList: [
        ],
      };
    },
    components: {
      DiagramComponent,
      TitleComponent,
    },
    methods: {
      goBack() {
        this.$router.go(-1);
      },
    },
  };
  </script> 