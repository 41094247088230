<!-- DON'T TOUCH -->
<template>
    <div>
      <button class="return-button" @click="goBack">Retour</button>
      <TitleComponent :Title="Title" />
      <DiagramComponent
        :format="Format"
        :nodes="nodes"
        :linkData="linkData"
        :componentList="componentList"
      />
    </div>
  </template>
      
  
  
    <script>
  // DON'T TOUCH
  import DiagramComponent from "@/components/Diagram.vue";
  import TitleComponent from "@/components/TitlePage.vue";
  
  export default {
    name: "PageProcessus", // Nom à changer
    data() {
      return {
        //Texte à remplir
        Title: {
          mainTitle:
            "Communication",
          subTitle: "Processus : Envoi de communications ",
          supervisor: "Responsable Qualité, Responsable Communication",
          derniere_modif: "28/02/2024 par Enzo CHAMPAGNE",
        },
  
        // Exemple des différents type de nœuds
        nodes: [
          {
            key: "0",
            text: "Demande de com pour la participation à un événement",
            type: "Start",
            indPrec: 0,
          },

          {
            key: "1",
            text: "Demande de com pour appel à intervenant",
            type: "Start", // Met en vert et de forme elliptique (genre ovale)
            indPrec: 0,
            direction: "right",
            noLinkauto: true,
          },

          {
            key: "2",
            text: "Rédaction Participation à un événement",
            supervisor: "Pôle Communication",
            indPrec: 0,
          },
          {
            key: "3",
            text: "Envoi au groupe de com",
            supervisor: "Pôle Communication",
            noLinkauto: true,
          },
          {
            key: "4",
            text: "Validation par la qualité",
            supervisor: "Pôle Qualité",
            noLinkauto: true,
          },
          { key: "5", text: "Publication sur les réseaux sociaux", supervisor: "Pôle Communication" },
          {
            key: "6",
            text: "Demander au mandat de liker",
            supervisor: "Pôle Communication",
          },
          { key: "7", text: "Com visible par tous", type: "End" },

          { key: "8", text: "Rédaction Appel à intervenat", indPrec: 1, supervisor: "Pôle Communication" },
        ],
  
        linkData: [
          {
            from: "4",
            to: "2",
            Emplacement: 1,
            label: "Document non validé",
            bySide: true,
            Espace: "petit",
          },
          { from: "2", to: "3", label: "< 48h", Espace: "grand" },

          { from: "3", to: "4", label: "Com validée", Espace: "grand", },

          { from: "8", to: "3", label: "< 48h", Espace: "grand", },

          { from: "8", to: "3", label: "< 48h", Espace: "grand", },

        ],
      };
    },
    components: {
      DiagramComponent,
      TitleComponent,
    },
    methods: {
      goBack() {
        this.$router.go(-1);
      },
    },
  };
  </script> 