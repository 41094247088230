


<template>
    <ListCocheComponent :infos="infos" :Title="Title"  @Click-close="goBack"/>

</template>
   
   <script>
import ListCocheComponent from "@/components/ListeValidation.vue";


export default {
  name: "preparation_offre_list",
  components: {
    ListCocheComponent,
  },
  data() {
    return {
      Title: {
        subSubTitle: "Procédure: Reunion de présentation",
        supervisor: "Présidence, Trésorier",
      },
      infos: {
        items: [
          " Préparer une présentation mettant en valeur notre Junior-Entreprise",
          " Contacter l'entreprise pour fixer une date et un lieu pour la réunion",
          " S'assurer de la présence des personnes clés de l'entreprise à la réunion",
          " Présenter notre Junior-Entreprise et expliquer comment elle peut contribuer aux objectifs de l'entreprise ",
        ],
      },
      
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  }
  
  
};
</script>