<template>
  <div class="page-container">
    <div class="diagram">
      <JointDiagram
        :nodes="formattedNodes"
        :linkData="Link"
        @Click-component="handleNodesClick"
      />
    </div>

    <ListCocheComponent
      v-if="showList"
      @Click-close="handleCloseClick"
      class="dynamic-component"
      :infos="this.component.infosList"
      :Title="this.Title"
    ></ListCocheComponent>
    <TexteComponent
      v-if="showTexte"
      @Click-close="handleCloseClick"
      :markdownContent="this.component.texteMarkdown"
      :list_image="this.component.images"
      :list_markdown="this.component.textes"
      :Title="this.Title"
      class="dynamic-component"
    ></TexteComponent>
  </div>
</template>

  
  <script>
import JointDiagram from "@/components/DiagJoint.vue";
import ListCocheComponent from "@/components/ListeValidation.vue";
import TexteComponent from "@/components/TexteMarkdown.vue";

export default {
  name: "DiagramComponent", // Nom à changer
  props: {
    nodes: Array, // Recevoir les données des nœuds en tant que props
    linkData: Array,
    componentList: Array,
    format: {
      type: String,
      default: "vertical",
    },
  },
  data() {
    return {
      // Initialize formattedNodes with the mapped nodes
      formattedNodes: [],
      Link: [],
      showList : false,
      showTexte : false,
      component : Array,
      Title : String,
      StartX: 0,
      StartY: 0,
    };
  },
  created() {
    this.calculateStartPosStartwithvhInPixels();
    this.formatNodes();
    this.createLink();
  },
  methods: {
    createLink() {
      for (let i = 1; i < this.nodes.length; i++) {
        if (this.nodes[i].noLinkauto != true) {
          let indPrec,
            xOff = 0,
            yOff = 0;
          if (this.nodes[i].indPrec != null) {
            indPrec = this.nodes[i].indPrec;
          } else {
            indPrec = i - 1;
          }
          if (this.nodes[i].x != this.nodes[indPrec].x) {
            xOff = 30;
          }
          if (this.nodes[i].y != this.nodes[indPrec].y) {
            yOff = 10;
          }
          this.Link.push({
            from: indPrec,
            to: i,
            offset: { x: xOff, y: yOff },
          });
        }
      }
      for (let i = 0; i < this.linkData.length; i++) {
        let maxWidth;
        switch (this.linkData[i].Espace) {
          case "petit":
            maxWidth = 100;
            break;
          case "moyen":
            maxWidth = 200;
            break;
          case "grand":
            maxWidth = 300;
            break;
          default:
            maxWidth = 200;
            break;
        }
        if (this.linkData[i].bySide == true) {
          let dec
          if (this.linkData[i].decalageSup){
              dec = this.linkData[i].decalageSup
          } 
          // else {}
          this.Link.push({
            ...this.linkData[i],
            fromSpot: "left",
            toSpot: "left",
            decLeft :dec, 
            maxWidth: maxWidth,
          });
        } else if (this.linkData[i].byBottom == true) {
          this.Link.push({
            ...this.linkData[i],
            fromSpot: "bottom",
            toSpot: "bottom",
            maxWidth: maxWidth,
          });
        } else {
          this.Link.push({
            ...this.linkData[i],
            maxWidth: maxWidth,
          });
        }
      }
    },
    formatNodes() {
      // Utilisation d'une variable temporaire pour stocker les nœuds formatés
      const tempFormattedNodes = [];

      for (let i = 0; i < this.nodes.length; i++) {
        // Passer le nœud précédent directement
        let indPrec;
        if (this.nodes[i].indPrec != null) {
          indPrec = this.nodes[i].indPrec;
        } else {
          indPrec = i - 1;
        }
        const previousNode = i > 0 ? tempFormattedNodes[indPrec] : null;

        tempFormattedNodes.push(
          this.formatNode(this.nodes[i], i, previousNode)
        );
      }

      this.formattedNodes = tempFormattedNodes;
    },
    CalculNewPos(previousNode, node) {
      let newX, newY;
      let ecartHoriz, ecartVert, ecartHorizDefault, ecartVertDefault;

      ecartHoriz = 300;
      ecartVert = 150;
      switch (this.format) {
        case "horizontal":
          ecartHorizDefault = ecartHoriz;
          ecartVertDefault = 0;
          break;
        default:
          ecartHorizDefault = 0;
          ecartVertDefault = ecartVert;
          break;
      }

      if (!previousNode) {
        newX = this.StartX; // Position initiale X pour le premier nœud
        newY = 10; // Position initiale Y pour le premier nœud
      } else {
        switch (node.direction) {
          case "right":
            newX = previousNode.x + ecartHoriz; // Exemple de décalage vers la droite
            newY = previousNode.y;
            break;
          case "left":
            newX = previousNode.x - ecartHoriz; // Exemple de décalage vers la gauche
            newY = previousNode.y;
            break;
          case "up":
            newX = previousNode.x;
            newY = previousNode.y - ecartVert; // Exemple de décalage vers le haut
            break;
          case "down":
            newX = previousNode.x;
            newY = previousNode.y + ecartVert; // Exemple de décalage vers le bas
            break;
          default:
            newX = previousNode.x + ecartHorizDefault; // Décalage par défaut
            newY = previousNode.y + ecartVertDefault;
        }
      }
      if (node.addDecX) {
        newX += node.addDecX;
      }
      if (node.addDecY) {
        newY += node.addDecY;
      }
      return {
        x: newX,
        y: newY,
      };
    },

    formatNode(node, index, previousNode) {
      const determineBackColor = (aNode) => {
        if (aNode.type === "Start") return "green";
        else if (aNode.type === "End") return "#f24646";
        // Add other conditions as necessary
        else if (aNode.link) return "#3369E7";
        else if (aNode.external_link) return "gray";
        else if (aNode.component) return "blue";

        return "#a0bbfa"; // Default color
      };
      const determineShape = (type) => {
        if (type === "Start") return 10;
        if (type === "End") return 10;
        // Add other conditions as necessary
        return 0; // Default shape
      };
      const determineVisibleSupervisors = (supervisor) => {
        if (supervisor != null) return true;
        return false; // Default shape
      };
      return {
        ...node,
        // Example modification: adding a new property
        Backcolor: determineBackColor(node),
        courbure: determineShape(node.type),
        isVisible: determineVisibleSupervisors(node.supervisor),
        x: this.CalculNewPos(previousNode, node).x,
        y: this.CalculNewPos(previousNode, node).y,
      };
    },
    calculateStartPosStartwithvhInPixels() {
      const vwInPixels = window.innerWidth / 100;
      const vhInPixels = window.innerHeight / 100;

      switch (this.format) {
        case "horizontal":
          this.StartX = 260;
          this.StartY = 17.5 * vhInPixels;

          break;
        default:
          this.StartX = 21 * vwInPixels;
          this.StartY = 10;
          break;
      }

    },
    handleCloseClick() {
      this.showTitleSide = false
      this.showList = false
      this.showTexte = false
    },
    handleNodesClick(NumberComponent) {
      this.component = this.componentList[NumberComponent]
      console.log (this.component)
      if (this.component.Name){
        this.Title = {
          subSubTitle : this.component.Name,
        }
      }
      if (this.component.infosList){
        this.showList = true
        this.showTexte = false
      }
      if (this.component.texteMarkdown || this.component.images || this.component.textes ){
        this.showTexte = true
        this.showList = false
      }
    },
  },
  components: {
    JointDiagram,
    TexteComponent,
    ListCocheComponent,
  },
};
</script>
  
<style>
.page-container {
  display: flex;
  justify-content: space-between;
  height: 100%;
}

.diagram {
  flex: 1; /* Prend tout l'espace disponible */
  margin-right: 2vw; /* Marge à droite */
  overflow: hidden;
}

.dynamic-component {
  flex: 1; /* Largeur fixe pour le composant dynamique */
}
</style>