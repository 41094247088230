<!-- Ce document est un exmple pour une vue de menu avec les magnifiques cases bleus 
Genre c'est pour faire un menu -->

<!-- DO NOT TOUCH -->
<template>
  <categories-component :items="items" />
</template>

<script>
// DO NOT TOUCH
import CategoriesComponent from "@/components/Categories.vue";


//Votre partie (vous avez le droit de remplir ;)
export default {
  name: "templateMenu", // Nom de la Page

  data() {
    return {
      //Infos des différentes cases du menu 
      items: [
        {
          title: "Diagramme avec toute les options", // Titre affiché
          subtitle: "Toutes les options de diagram", // Sous titres en plus petit en dessous (optional)
          link: "/diagramProcessExample", //Lien vers la page que vous souhaitez rejoindre (path dans les routers)
          responsables: "Euh bonne chance la quali",// Responsables des process de la catégorie (optional)
        },
        {
          title: "Page avec une liste à cocher",
          subtitle: "Une simple page avec une liste à cocher",
          link: "/listExample",
          responsables: "Secrétaire général, Présidence",
        },
        {
          title: "Page avec du texte",
          subtitle: "Une simple page avec du texte en MarkDown",
          link: "/textExample",
          responsables: "Secrétaire général, Présidence",
        },
        {
          title: "Titre de la Case ",
          subtitle: "Y a quoi après avoir cliqué sur la case",
          link: "/destination_de_la_case",
          responsables: "Responsable des processus",
        },
      ],
    };
  },
  components: {
    CategoriesComponent,
  },
};
</script>
