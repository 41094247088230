<template>
    <div>
        <div v-if="Title.mainTitle" id="conteneur_titre">
            <div  id="titre_1">
                <h1>{{ Title.mainTitle }}</h1>
            </div>
            <img v-if="Title.image" :src="Title.image" id="image" alt="Image Non Trouvée" />
            <div id="responsables">
            <h5>Acteurs Responsables : {{ Title.supervisor }}</h5>
            <h5>Dernière modif : {{ Title.derniere_modif }}</h5>
        </div>
        </div>
        <div v-if="Title.subTitle" id="conteneur_titre">
            <div id="sous_titre">
                <h2>{{ Title.subTitle }}</h2>
            </div>
        </div>
        <div v-if="Title.subSubTitle" id="conteneur_titre">
            <div id="sous_sous_titre_1">
                <h3>{{ Title.subSubTitle }}</h3>
            </div>
        </div>
        
    </div>
</template>




<script>
export default {
    name : "TitleComponent",
    props: {
        Title: {
        mainTitle: {
            type: String,
        },
        subTitle: {
            type: String,
        },
        subSubTitle: {
            type: String,
        },
        supervisor: {
            type: String,
            default: 'A remplir'
        },
        image:{
            type: String,
        },
    },
    
}
};
</script>
<style>
#conteneur_titre {
    display: flex;
    align-items: center;
    margin-left: 80px;
}

#titre_1 {
    display: flex;
    align-items: center;
    width: auto;
    min-width: 400px;
    white-space: nowrap;
    height: 4vh;
    margin-bottom: 20px;
    padding-left: 30px;
    padding-right: 20px;
    background-origin: padding-box;
    background-color: #3369e7;
    font-family: 'Inter-Regular';
    border-radius: 10px;
}

#image {
    width: 45px;
    height: 45px;
    margin-left: 10px;
    margin-bottom: 20px;
}

#sous_titre {
    display: flex;
    align-items: center;
    width: auto;
    white-space: nowrap;
    padding-right: 20px;
    height: 4vh;
    margin-bottom: 20px;
    margin-left: 20px;
    padding-left: 20px;
    background-origin: padding-box;
    background-color: #cbd9f9;
    font-family: 'Inter-Regular';
    border-radius: 10px;
}

#sous_sous_titre_1 {
    display: flex;
    align-items: center;
    width: auto;
    white-space: nowrap;
    padding-right: 20px;
    height: 4vh;
    margin-bottom: 20px;
    margin-left: 40px;
    padding-left: 20px;
    background-origin: padding-box;
    background-color: #cbd9f9;
    font-family: 'Inter-Regular';
    border-radius: 10px;
}
#sous_sous_sous_titre {
    display: flex;
    align-items: center;
    width: auto;
    white-space: nowrap;
    padding-right: 20px;
    height: 40px;
    margin-bottom: 80px;
    margin-left: 60px;
    padding-left: 20px;
    background-origin: padding-box;
    background-color: #cbd9f9;
    font-family: 'Inter-Regular';
    border-radius: 10px;
}

#responsables {
    flex-direction: row;
    align-items: center;
    width: 300px;
    height: 40px;
    margin-left: 120px;
    padding-left: 30px;
    font-family: 'Inter-Regular';
}

#titre_1 h1 {
    color: white;
    font-size: 26px;
    font-weight: bold;
}

#sous_titre h2 {
    color: rgb(5, 5, 5);
    font-size: 20px;
}

#sous_sous_titre h3 {
    color: rgb(5, 5, 5);
    font-size: 15px;
}

#sous_sous_sous_titre h3 {
    color: rgb(5, 5, 5);
    font-size: 15px;
}

</style>
