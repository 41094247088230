<template>
  <!-- on affiche les catégories -->
  <categories-component :items="items" />
</template>

<script>
import CategoriesComponent from "@/components/Categories.vue";
export default {
  name: "clrView",
  data() {
    return {
      // Exemple de données pour les nœuds
      items: [
        {
          title: "Charte et Convention",
          subtitle: "Partenariats et stages ",
          link: "/chartes_conventions",
          responsables: "Secrétaire général, Présidence",
        },
        {
          title: "Gestion Associative",
          subtitle: "Gestion des réunions et des documents ",
          link: "/gestion_associative",
          responsables: "Secrétaire général, Présidence",
        },
        {
          title: "Cadeaux et Concours",
          subtitle: "Concours en Junior et remise des lots",
          link: "/cadeaux_concours",
          responsables: "Secrétaire général, Présidence,Trésorerie",
        },
      ],
    };
  },
  components: {
    CategoriesComponent,
  },
};
</script>
