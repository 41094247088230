<!-- DON'T TOUCH -->
<template>
    <div>
      <button class="return-button" @click="goBack">Retour</button>
      <TitleComponent :Title="Title" />
      <DiagramComponent
        :format="Format"
        :nodes="nodes"
        :linkData="linkData"
        :componentList="componentList"
      />
    </div>
  </template>
      
  
  
    <script>
  // DON'T TOUCH
  import DiagramComponent from "@/components/Diagram.vue";
  import TitleComponent from "@/components/TitlePage.vue";
  
  export default {
    name: "brcProcessus", // Nom à changer
    data() {
      return {
        //Texte à remplir
        Title: {
          mainTitle:
            "Trésorerie",
          subTitle: "Social ",
          subSubTitle: "Processus: BRC",
          supervisor: "Trésorier,Comptable, Vice-Trésorier",
          derniere_modif: "29/03/2024 par Mathieu CHAILLON",
        },
  
        // Exemple des différents type de nœuds
        nodes: [
          {
            key: "0",
            text: 'TR à déclarer (Attention avant le 31/01)',
            type: "Start", // Met en vert et de forme elliptique (genre ovale)
          },
          {
            key: "1",
            text: "S'assurer que l'onglet 'BV & RM du Tableau de Suivi est à jour. ",
            component: "0",
            supervisor: 'Vice-Trésorier ou Trésorier'
          },
          {
            key: "3",
            text: "Lister les BV émis pendant l'année Civile",
            supervisor: 'Vice-Trésorier ou Trésorier',
            addDecY : 50,
          },
          {
            key: "4",
            text: "Sortir le nombre de JEH payés sur l'ensemble des BV de l'année civile pour calculer l'Assiette de Cotisation",
            supervisor: 'Vice-Trésorier ou Trésorier'
          },
          
          {
            key: "6",
            text: "Vérifier tout les BRC de l'année civile",
            supervisor: 'Vice-Trésorier ou Trésorier'
          },
          { key: "5", 
          text: "Accéder à l'espace URSSAF en s'identifiant (Mot de Passe présent dans Passbolt)" ,
          external_link : "https://www.urssaf.fr/portail/home.html",
          noLinkauto: true,
          addDecY : 50,

          },
          {
            key: "7",
            text: " Cliquer dans le menu à droite sur 'Déclarer des Cotisations'",
            addDecY : -20,
            
          },
          {
            key: "7",
            text: "Remplir la partie haute comme un BRC en prenant comme période l'année civile",
          },
          {
            key: "7",
            text: "Vérifier les infos de la partie Basse avec les différents BRC de l'année civile",
          },
          {
            key: "12",
            text: "Contrôle du Vice-Trésorier: Vérifier que la somme de tous les montants de toutes les cotisations Junior présentes sur tous les BV correspond au déclaratif",
            supervisor: 'Vice-Trésorier',
            addDecY : -50,
          },
          {
            key: "13",
            text: "Contrôle du Comptable: Double contrôle avec le compte 431",
            supervisor:'Comptable',
            addDecY : +50,
          },
          {
            key: "14",
            text: "Archivage d'un Grand Livre du compte 431 par le comptable",
            supervisor:'Comptable'
          },
          {
            key: "15",
            text: "Payer la différence entre la somme des BRC et le TR",
            addDecX : -150,
            noLinkauto : true

            
          },
          {
            key: "15",
            text: "Rédiger une lettre explicative à l'URSSAF et faire la regularisation dans le prochain BRC",
            direction: "right",
            indPrec: 12,  
            noLinkauto : true
          },
          {
            key: "16",
            text: "Sauvegarder les documents et les archiver",
            component: '1',
            addDecX : -150,
            
          },
          {
            key: "18",
            text: "Imprimer et ranger tous les documents à archiver dans le Classeur Social",
          },
          {
            key: "19",
            text: "Comptabilisé le TR",
            component : 2
          },
          {
          key: "20",
          text: "Le TR a été effectué avec succès",
          type: "End",
        },
        ],
  
        linkData: [
          {
            from: "4",
            to: "5",
            Emplacement: 1,
            label: "On peut maintenant passer à la Déclaration",
            
            Espace: "grand",
          },
          {
            from: "12",
            to: "14",
          },
          {
            from: "11",
            to: "12",
            Emplacement: 1,
            label: "Différence en la faveur de l'URSSAF",
            
            Espace: "petit",
          },
          {
            from: "11",
            to: "13",
            Emplacement: 1,
            label: "Différence en la faveur de AEI",
            
            Espace: "petit",
          },
       
        ],
        componentList: [
        {
          number: "0",
          Name: "S'assurer que l'onglet BV et RM du Tableau de suivi sont à jour",
          infosList: {
            texte_before :  " Le Tableau de Suivi (TS) fourni par la CNJE via Kiwi Légal calcule automatiquement les cotisations dues pour l'ensemble des BVs",

            items: ["Vérifier que l'ensemble des BVs émis soient présents dans l'onglet ", 
            "Revérifier les taux présents dans l'onglet Taux cotisation du TS",
            "Vérifier que le bon Taux soient renseignés pour chaque BV"
            ],
          },
        },
        {
          number: "1",
          Name: "A archiver",
          infosList: {
            items: [
              " Le récapitulatif de déclaration",
              " Le justificatif de paiement",
              " L'avis de réception",
              " GL du compte 431",
              " Lettre si nécessaire (Solde en faveur de AEI)"
            ],
            
          },
        },
        {
          number: "2",
          Name: "Rentrer en compta le TR",
          texteMarkdown: `Informations sur les entrés comptables lors d'un TR`,
          images : [require("@/assets/comptaTR1.png"),
          require("@/assets/comptaTR2.png"),
          require("@/assets/comptaTR3.png"),]
        
        }
      ],
      };
    },
    components: {
      DiagramComponent,
      TitleComponent,
    },
    methods: {
      goBack() {
        this.$router.go(-1);
      },
    },
  };
  </script> 