<!-- DON'T TOUCH -->
<template>
    <div>
      <button class="return-button" @click="goBack">Retour</button>
      <TitleComponent :Title="Title" />
      <DiagramComponent
        :format="Format"
        :nodes="nodes"
        :linkData="linkData"
        :componentList="componentList"
      />
    </div>
  </template>
      
  
  
    <script>
  // DON'T TOUCH
  import DiagramComponent from "@/components/Diagram.vue";
  import TitleComponent from "@/components/TitlePage.vue";
  
  export default {
    name: "PageProcessus", // Nom à changer
    data() {
      return {
        //Texte à remplir
        Title: {
          mainTitle:
            "Cadre Légal et Réglementaire",
          subTitle: "Gestion Réunion",
          subSubTitle: "Processus Conseil d'Administration",
          supervisor: "Présidence, Secrétaire Général, Conseil d'Administration",
          derniere_modif: "26/02/2024 par Bastien HUET",
          image :require("@/assets/clr.png")

        },
  
        // Exemple des différents type de nœuds
        nodes: [
          {
            key: "0",
            text: 'Conseil d\'Administration à effectuer',
            type: "Start", // Met en vert et de forme elliptique (genre ovale)
          },
          {
            key: "1",
            text: "Avant le Conseil d'Administration",
            component: "0",
            supervisor: "President,Secrétaire Général",
          },
          {
            key: "2",
            text: "Pendant le Conseil d'Administration",
            component: "1",
            supervisor: "President,Secrétaire Général",
          },
          {
            key: "3",
            text: "Après le Conseil d'Administration",
            component: "2",
            supervisor: "President,Secrétaire Général",
          },
       
          {
            key: "4",
            text: "Conseil d'Administration effectuée ",
            type: "End",

          },
        ],
  
        linkData: [
          {
            from: "2",
            to: "1",
            Emplacement: 1,
            Espace: "grand",
          },
          {
            from: "4",
            to: "3",
            Emplacement: 1,
            Espace: "grand",
          },
       
        ],
        componentList: [
          // détails avant CA
          {
        number: "0",
        Name: "Procédure: Avant le Conseil d'Administration",
        infosList: {
          items: [
            "Prévoir une date (Tous les 15 jours).",
            "Tous les membres du Conseil d'Administration proposent des sujets à mettre à l'ordre du jour.",
            "Soulever les éventuels problèmes.",
          ],
        },
        },
        //détails pendant un CA
        {
          number: "1",
          Name: "Procédure: Pendant le Conseil d'administration",
          infosList:{
            items:[
              "Prendre la preuve.",
              "La prise de parole se fait dans l'ordre de priorité des points à aborder.",
              "Le Secrétaire Général prend les notes afin de préparer le Compte-Rendu.",
              "En fonction des décisions à prendre, un vote est nécessaire.",
              "Le Secrétaire Général Compabilise les votes."
            ],
          },
        },
        //détails après le CA
        {
          number:"2",
          Name: "Procédure: Après le Conseil d'Administration",
          infosList:{ 
            items:[ 
              "Le Secrétaire Général écrit le Compte-Rendu du CA (< 48h après).",
              "Mettre le CR sur le drive dans le dossier approprié.",
              "Communiquer le CR à l'ensemble du mandat.",
              "Imprimer le CR, et faire signer par le Président et le Secrétaire Général.",
              "Archiver le CR dans le classeur dédié.",

            ],
          },
        },
      ],
      };
    },
    components: {
      DiagramComponent,
      TitleComponent,
    },
    methods: {
      goBack() {
        this.$router.go(-1);
      },
    },
  };
  </script> 