


<template>
  <ListCocheComponent :infos="infos" :Title="Title" @Click-close="goBack" />
</template>
   
   <script>
import ListCocheComponent from "@/components/ListeValidation.vue";

export default {
  name: "list_example",
  components: {
    ListCocheComponent,
  },
  data() {
    return {
      Title: {
        mainTitle: "Example de Page avec une liste à cocher",
        subTitle: "Sous Titre",
        subSubTitle: "Procédure: Comment être un bon codeur de Wikix",
        supervisor: "Qualité",
        derniere_modif: "18/02/24 par Mathieu CHAILLON",
        
        image :require("@/assets/clr.png")
      },
      infos: {
        items: [
          " Je ne commit pas si ça compile pas",
          " Je ne touche pas au endroit où y a écrit DO NOT TOUCH ",
          " Je ne m'appele pas Bastien ",
          " Treso >> ",
        ],
        texte_if_all: "Bravo à toi tu es un génie",
        link_if_all:
          "https://docs.google.com/presentation/d/1Nuk0xAWRt1VpCjENFtEoXKJUuYKVm6btvSVDb-fGhW4/edit?usp=sharing",
        texte_link: "Cliquez ici",
        image :require("@/assets/roue_archivage.png"),
        texte_before : "Blabla",
      },
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>