<!-- DON'T TOUCH -->
<template>
    <div>
      <button class="return-button" @click="goBack">Retour</button>
      <TitleComponent :Title="Title" />
      <DiagramComponent
        :format="Format"
        :nodes="nodes"
        :linkData="linkData"
        :componentList="componentList"
      />
    </div>
  </template>
      
  
  
    <script>
  // DON'T TOUCH
  import DiagramComponent from "@/components/Diagram.vue";
  import TitleComponent from "@/components/TitlePage.vue";
  
  export default {
    name: "processus_generalView", // Nom à changer
    data() {
      return {
        //Texte à remplir
        Title: {
          mainTitle:
            "Activité Commercial",
          subTitle: "Avant étude",
          subSubTitle: "Processus Général",
          supervisor: "Co-Responsables Activité Commerciale",
          derniere_modif: "24/03/2024 par Enzo CHAMPAGNE",
        },
  
        // Exemple des différents type de nœuds
        nodes: [
          {
            key: "0",
            text: 'Ajouter le Client à la liste des prospects \n (Cliquer ici)',
            external_link: "https://docs.google.com/spreadsheets/d/10BR4CxbuoxZyDyqLEglg5ZUSLNEEh6c8fo7c5kfJjvk/edit#gid=0",
            type: "Start", // Met en vert et de forme elliptique (genre ovale)
          },
          {
            key: "1",
            text: "Validation de l'étude puis ajout du Client sur l'ERP",
            component: "6",
          },
          {
            key: "2",
            text: "Envoyer un mail au Client ou l'appeler si l'on possède son numéro de téléphone",
            component: "0",
          },
          {
            key: "3",
            text: "Attendre 3 jours maximum",
          },
          {
            key: "4",
            text: "Faire le RDV Client 1 pour chiffrer le Devis",
            noLinkauto: true,
            link: "rdv_client",
          },
          { key: "5", text: "Ecrire un mail de relance ou effectuer un appel téléphonique", indPrec: 3, direction: "right", noLinkauto: true, },
          {
            key: "6",
            text: "Abandon. \n Mettre à jour la liste des prospects",
            indPrec: 5,
            direction: "right",
            type: "End",
            noLinkauto: true,
          },
          { key: "7", text: "Vérification de la solvabilité de l'étude", indPrec: 4, link: 'verification_solvabilite'},
          {
            key: "8",
            text: "Rédaction du devis",
            link: 'redaction_devis',
          },
          {
            key: "9",
            text: "Validation par la Qualité",
            supervisor: "Pôle Qualité",
          },
          { key: "10", 
          text: "Envoyer le devis au Client et lui proposer une prochaine date pour discuter du devis et pour lui expliquer la suite du processus", noLinkauto: true, addDecY: -30},
  
  
          { key: "11", text: "Attendre 1 semaine maximum la réponse du Client vis-à-vis du budget pour le devis (puis relancer de manière hebdomadaire si aucune réponse)", addDecY: -20,},
          {key: "12", text: "Création de l'étude sur l'ERP", component: "5", addDecY: 50, noLinkauto: true,},
          { key: "13", text: "Négociations jusqu'à trouver un accord", indPrec :11, direction: "right", noLinkauto: true,},
          {
            key: "14",
            text: "Comprendre pourquoi le Client n'a pas signé. \n S'il a signé ailleurs chercher à savoir chez qui et à combien",
            indPrec: 13,
            direction: "right",
            noLinkauto: true,
          },
          {
            key: "15",
            text: "Abandon",
            type: "End",
            indPrec: 14,
            direction: "right",
          },
          {
            key: "16",
            text: "Rédaction de la CE ou CC",
            indPrec: 12,
            noLinkauto: true,
            link: 'redaction_ce_cc',
          },

          {
            key: "17",
            text: "Recrutement de l'intervenant",
            direction: "left",
            noLinkauto: true,
            link: 'recrutement_intervenant',
          },

          {
            key: "18",
            text: "Planifier un RDV Client 2 pour effectuer les modifications nécessaires (avec ou sans intervenant en fonction de la difficulté de l'étude)",
            noLinkauto: true,
            indPrec: 16,
            direction: "right",
            addDecY: -10,
            addDecX: 50
          },

          {
            key: "19",
            text: "Rédaction du RM",
            link: 'redaction_rm',
            indPrec: 16,
          },

          {
            key: "20",
            text: "Intervenant sélectionné et documents rédigés",
          },

          {
            key: "21",
            text: "Envoyer la CE au Client et lui proposer de rencontrer l'intervenant lors d'un RDV",
            component: "1",
            noLinkauto: true,
          },

          {
            key: "22",
            text: "Signature de la CE",
            type: "End",
          },
          {
            key: "23",
            text: "Abandon. \n Avorter l'étude sur l'ERP (Cliquer ici)",
            component: "3",
            indPrec: 21,
            direction: "right",
            type: "End",
            noLinkauto: true,
          },
          
        ],
  
        linkData: [
          {
            from: "3",
            to: "4",
            Emplacement: 2,
            label: "Réponse positive",
            Espace: "grand",
          },
          {
            from: "3",
            to: "5",
            Emplacement: 1,
            label: "Absence \n de réponse",
            Espace: "grand",
          },
          {
            from: "5",
            to: "4",
            label: "Réponse positive",
            Espace: "petit",
          },
          { from: "5", to: "6", label: "Réponse négative", Espace: "petit" },
          { from: "9", to: "8", label: "Devis \n non validé", Emplacement: 1, bySide: true, },
          {
            from: "9",
            to: "10",
            label: "Devis validé",
            Emplacement: 1,
            Espace: "petit",
          },
  
          {
            from: "11",
            to: "12",
            label: "Budget validé \n (attestation écrite)",
            Espace: "petit",
          },
          {
            from: "11",
            to: "13",
            label: "Budget \n non validé",
          },

          {
            from: "13",
            to: "12",
            label: "Budget \n validé",
          },

          {
            from: "13",
            to: "14",
            label: "Accord \n non trouvé",
          },

          {
            from: "12",
            to: "16",
            label: "Devis validé et suffisamment détaillé"
          },

          {
            from: "12",
            to: "18",
            label: "S'il manque \n des détails"
          },

          {
            from: "18",
            to: "16",
            label: "Modifications \n effectuées"
          },


          {
            from: "20",
            to: "21",
            label: "Dans la semaine suivant l'appel à intervevant et après validation par la Qualité",
          },
          {
            from: "12",
            to: "17",
          },

          {
            from: "17",
            to: "20",
          },
          {
            from: "21",
            to: "23",
            label: "Refus du \n Client",
          }
        ],
        componentList: [
          {
            number: "0",
            Name: "Eléments qui doivent apparaitre dans le Mail :",
            infosList: {
              items: [
                "Expliquer pourquoi il devrait travailler avec AEI",
                "Proposer un premier RDV Client",
                "Mettre en copie @commercial",
              ],
            },
          },
          {
            number: "1",
            Name: "Rencontre entre l'intervenant et le Client :",
            infosList: {
              items: [
                "Envoyer à l'intervenant le Template AEI",
                "L'intervenant présente ses projets en lien avec l'étude",
              ],
              
            },
          },
          {
            number: "2",
            Name: "Procédure : Envoi d'un document au Client ",
            infosList: {
              items: [
                "Joindre le document dans le mail",
                "Rappeler le contexte entre le Client et AEI",
                "Mettre les formules de politesse",
                "Utiliser la signature de la JE",
              ],
            },
          },
          {
            number: "3",
            Name: "Procédure : Avorter une étude sur l'ERP ",
            infosList: {
              items: [
                "Se connecter à l'ERP",
                "Onglet : Suivi d'études",
                "Onglet : voir les études",
                "Choisir l'étude",
                "Aller dans Suivi",
                "Sélectionner 'avorté' dans l'état de l'étude",
              ],
            },
          },
          {
            number: "4",
            Name: "Premier RDV Client",
            infosList: {
              items: [
                "Poser des questions de mise en contexte",
                "Rappeler le fonctionnement d'une JE",
                "Expliquer l'organisation de l'étude",
                "Envoyer un mail récapitulatif au Client moins de 24h après le RDV",
              ],
            },
          },
          {
            number: "5",
            Name: "Création de l'étude sur l'ERP",
            infosList: {
              items: [
                "Se connecter à l'ERP",
                "Onglet : Suivi d'étude",
                "Onglet : 'Créer une étude'",
                "Remplir le nom de référence en majuscule (3 lettres) et un chiffre si ce n'est pas la première étude",
                "Les champs de mandat et de numéros d'étude s'incrémentent automatiquement, ne rien modifier",
                "Cliquer sur 'enregistrer l'étude'",
              ],
            },
          },
          {
            number: "6",
            Name: "Tous les points suivants doivent être vérifiés :",
            infosList: {
              items: [
                "Déontologie : L'étude met-elle en application les enseignements de l'école ?",
                "Qualité : Avons-nous les ressources humaines et techniques à la réalisation de l'étude sur TOUTE sa durée",
                "Projet : La demande du Client est-elle sensée",
                "Projet : Le Client sait-il vraiment ce qu'il veut ?",
              ],
            },
          },
        ],
      };
    },
    components: {
      DiagramComponent,
      TitleComponent,
    },
    methods: {
      goBack() {
        this.$router.go(-1);
      },
    },
  };
  </script> 