<!-- DON'T TOUCH -->
<template>
    <div>
      <button class="return-button" @click="goBack">Retour</button>
      <TitleComponent :Title="Title" />
      <DiagramComponent
        :format="Format"
        :nodes="nodes"
        :linkData="linkData"
        :componentList="componentList"
      />
    </div>
  </template>
      
  
  
    <script>
  // DON'T TOUCH
  import DiagramComponent from "@/components/Diagram.vue";
  import TitleComponent from "@/components/TitlePage.vue";
  
  export default {
    name: "PageProcessus", // Nom à changer
    data() {
      return {
        //Texte à remplir
        Title: {
          mainTitle:
            "Communication",
          subTitle: "Processus : Organisation d'événement",
          supervisor: "Responsable Qualité, Responsable Communication, Responsable Event, Présidence",
          derniere_modif: "28/02/2024 par Enzo CHAMPAGNE",
        },
  
        // Exemple des différents type de nœuds
        nodes: [
          {
            key: "0",
            text: 'Evénement à organiser planifié',
            type: "Start",
          },
          {
            key: "1",
            text: "Rédaction coms écrites",
            supervisor: "Pôle Communication",
            noLinkauto: true,
          },
          {
            key: "2",
            text: "Rédaction coms visuelles",
            supervisor: "Pôle Communication",
            
          },
          {
            key: "3",
            text: "Validation par la qualité",
            supervisor: "Pôle Qualité",
            noLinkauto: true,
          },
          { key: "4", text: "Faire imprimer les affiches et les afficher sur les écrans de l'école", noLinkauto: true, supervisor: "Pôle Communication"},
          {
            key: "5",
            text: "Publier la première com écrite d'annonce",
            supervisor: "Pôle Communication",
            noLinkauto: true,
          },
          { key: "6", text: "Publier la première com écrite de rappel", noLinkauto: true, supervisor: "Pôle Communication"},
          {
            key: "7", text: "Publier la seconde com écrite de rappel", noLinkauto: true, supervisor: "Pôle Communication"},

          {
            key: "8",
            text: "Communiquer après l'événement",
            supervisor: "Pôle Communication",
            noLinkauto: true,
          },
          { key: "9", 
          text: "Fin de l'événement", type: "End" },

        ],
  
        linkData: [

          {
            from: "3",
            to: "1",
            Emplacement: 2,
            label: "Document non validé",
            bySide: true,
            decalageSup : 40,
            Espace: "petit",
          },
          {
            from: "0",
            to: "1",
            label: "2 semaines avant",
            Espace: "grand",
          },
          {
            from: "2",
            to: "3",
            label: "< 48h",
            Espace: "grand",
          },
          {
            from: "3",
            to: "4",
            label: "Com validée",
            Espace: "grand",
          },
          { from: "4", to: "5", label: "1 semaine avant", Espace: "petit" },
          { from: "5", to: "6", label: "1 jour avant", Emplacement: 1 },
          {
            from: "6",
            to: "7",
            label: "Le jour même",
            Emplacement: 1,
            Espace: "petit",
          },
  
          {
            from: "7",
            to: "8",
            label: "< 1 semaine après l'événement",
            Espace: "petit",
          }
        ],
      };
    },
    components: {
      DiagramComponent,
      TitleComponent,
    },
    methods: {
      goBack() {
        this.$router.go(-1);
      },
    },
  };
  </script> 

