<!-- DON'T TOUCH -->
<template>
    <div>
      <button class="return-button" @click="goBack">Retour</button>
      <TitleComponent :Title="Title" />
      <DiagramComponent
        :format="Format"
        :nodes="nodes"
        :linkData="linkData"
        :componentList="componentList"
      />
    </div>
  </template>
      
  
  
    <script>
  // DON'T TOUCH
  import DiagramComponent from "@/components/Diagram.vue";
  import TitleComponent from "@/components/TitlePage.vue";
  
  export default {
    name: "PageProcessus", // Nom à changer
    data() {
      return {
        //Texte à remplir
        Title: {
          mainTitle:
            "Processus RFP",
          subTitle: "",
          subSubTitle: "",
          supervisor: "GRH, Secrétaire Général",
          derniere_modif: "21/03/2024",
        },
  
        // Exemple des différents type de nœuds
        nodes: [
          {
            key: "0",
            text: 'Entré en mandat du mandat n',
            type: "Start", // Met en vert et de forme elliptique (genre ovale)
          },
          {
            key: "0",
            text: "Vacances d'été: Préparation du RFP",
            supervisor: "Secrétaire général, GRH",
            component: "0",
          },
          {
            key: "2",
            text: "Septembre: Présentation de AEI aux 1A",
            component: "1",
            
          },
          {
            key: "3",
            text: "Octobre: Formations Générales ",
            component: "2",
        
          },
          { key: "4", 
            text: "Novembre: Entretiens personnels et de groupes",
            supervisor: "Conseil d'Administration",
            component: "3",
         },
         {
            key: "5",
            text: "Fin Novembre: CA de selection des Pioux",
            supervisor: "Conseil d'Administration",
            
          },
          {
            key: "6",
            text: "Décembre: Formations spécifiques",
            supervisor: "Conseil d'Administration",
            component: "4"
          },
          { key: "7", 
          text: "Janvier: AG d'élection et composition des pôles" 
        },
          {
            key: "8",
            text: "Février-Avril: Binômage dans les pôles",
            supervisor: "Conseil d'Administration",
            component:"5"
          },

          {
            key: "9",
            text: "AG des quitus et Passation",
          },
          {
            key: "10",
            text: "Entré en fonction du nouveau mandat",
            
            type: "End",
          },

        ],
  
        linkData: [
          {
            from: "2",
            to: "1",
            Emplacement: 1,
            Espace: "grand",
          },

        ],
        componentList: [
          {
            number: "0",
            Name: "Procédure: Préparation du RFP",
            infosList: {
              items: [
                " Choisir les dates clef du RFP",
                " Définir les qualités recherchés dans le prochain mandat",
                " Définir les critères de sélections",
                " Préparer les formations générales et spécifiques"
              ],
            },
          },
          {
            number: "1",
            Name: "Présentation de AEI aux 1A",
            infosList: {
              items: [
                " Présentation de la JE lors de l'Amphi de présentation des associations ",
                " Rendre la JE actractive en parlant aux 1A pendant le mois d'intégration et en la rendant visible au travers des vestes et T-shirt",
              ],
              
            },
          },
          {
            number: "2",
            Name: "Procédure: Formations générales",
            infosList: {
              items: [
                " Présentation générale de la JE et explication des dates du RFP ",
                " Formation Cadre Légal et Réglementaire ",
                " Formation à la prospection",
                " Formation au Suivi d'étude",
                " Formation à la Gestion associative et Suivi RH",
                " Formation Performance et Qualité en JE",
                " Formation Présentation SI",
                " Formation Communication en JE",
                " Formation La trésorerie pour tous",
              ],
              
            },  
        },
        {
            number: "3",
            Name: "Procédure: Entretien personnel et collectif",
            infosList: {
              items: [
                " Entretien personnel: Avec 2 membres du CA, le but est de tester les connaissances générales du candidat sur la JE et le mouvement et tester sa motivation ",
                " Entretien personnel: Utiliser le questionnaire entretien",
                " Entretien collectif: donner une mise en situation au groupe de candidat dans lequel après 10 min de réflexion de groupe, une préssentation est faite aux membres du CA",
                " Entretien collectif: Permet de mettre en évidence les caractères naturels des candidats ( leader, suiveur, timide, prise d'initiative, capacités de communication et de raisonnement)",
              ],
              
            },
          },
          {
            number: "4",
            Name: "Procédure: Formations spécifiques",
            infosList: {
              items: [
                " Former les Pioux sur toutes les missions spécifiques aux pôle",
                " Mise a l'épreuve des candidats sur chaque formation ( les exercices doivent être notés)",
              ],
              
            },
          },
          {
            number: "5",
            Name: "Procédure: Binômage",
            infosList: {
              items: [
                " Intégration des pioux sélectionné dans les pôles",
                " Chaque pioux est mis en binôme avec un membre du pôle",
                " Touts les aspects/missions propre aux pôle doivent être abordés",
                " Les pioux effectuent les missions du chargé et le binôme associé surveille le travail et donne les conseils.",
                " Chaque élément abordé par le pioux doit être renseigné dans la fiche de binômage pour certifier de la passation de connaissance",
              ],
              
            },
          },
        ],
      };
    },
    components: {
      DiagramComponent,
      TitleComponent,
    },
    methods: {
      goBack() {
        this.$router.go(-1);
      },
    },
  };
  </script> 