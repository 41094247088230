<!-- DON'T TOUCH -->
<template>
    <div>
      <button class="return-button" @click="goBack">Retour</button>
      <TitleComponent :Title="Title" />
      <DiagramComponent
        :format="Format"
        :nodes="nodes"
        :linkData="linkData"
        :componentList="componentList"
      />
    </div>
  </template>
      
  
  
    <script>
  // DON'T TOUCH
  import DiagramComponent from "@/components/Diagram.vue";
  import TitleComponent from "@/components/TitlePage.vue";
  
  export default {
    name: "PageProcessus", // Nom à changer
    data() {
      return {
        //Texte à remplir
        Title: {
          mainTitle:
            "Suivi du Management par la Qualité (SMQ)",
          subTitle: "Procédure: Création de la Stratégie",
          subSubTitle: "",
          supervisor: "Présidence",
          derniere_modif: "21/03/2024",
        },
  
        // Exemple des différents type de nœuds
        nodes: [
          {
            key: "0",
            text: 'Le Conseil d\'Administration a été élu',
            type: "Start", // Met en vert et de forme elliptique (genre ovale)
          },
          {
            key: "1",
            text: "Analyse de l'environnement de la JE",
            component:"0"
          },
          {
            key: "2",
            text: "Création de la stratégie ",
            component:'1',
            
            
          },

          {
            key: "3",
            text: "Déployer sa stratégie au travers des PDA ",
            type: "End",
          },

        ],
  
        linkData: [

        {}

        ],
        componentList: [
          {
            number: "0",
            Name: " Analyse de l'environnement",
            infosList: {
              items: [
                " Analyse interne",
                " Analyse externe",
                " Matrice Intérêt/Pouvoir",
                " Synthèse dans la matrice PESTEL ( Politique Economique Socioculturel Technologique Ecologique Légal)",
                " Synthèse dans la matrice SWOT (Strength Weakness Opportunities Threats )",
                
              ],
            },
          },
          {
            number: "1",
            Name: "Créer sa stratégie",
            infosList: {
              items: [
                " Utiliser nos forces et travailler sur nos faiblesses",
                " Choisir la direction générale avec les grands objectifs du mandat",
                " Respecter la culture de la JE",
                " Vérifier que la majorité des membres adhèrent à la stratégie",
              ],
              
            },
          },
        ],
      };
    },
    components: {
      DiagramComponent,
      TitleComponent,
    },
    methods: {
      goBack() {
        this.$router.go(-1);
      },
    },
  };
  </script> 