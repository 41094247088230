import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import AccueilRoutes from './accueil';
import ClrRoutes from './clr';
import TresoRoutes from './tresorerie';
import AutresRoutes from './autres';

import ActiCoRoutes from './actiCo';
import ComRoutes from './com';
import RFPRoutes from './rfp';
import DSIRoutes from './dsi';
import QualiRoutes from './quali';





import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  ...AccueilRoutes, ...ClrRoutes,...TresoRoutes,...AutresRoutes,
...ActiCoRoutes,...ComRoutes,...RFPRoutes,...DSIRoutes,...QualiRoutes,
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
