import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/styles.css';
import PanelMenu from "primevue/panelmenu";

import 'primeicons/primeicons.css';

const app = createApp(App);
app.component("PanelMenu", PanelMenu);
app.use(router);
app.mount('#app');
