<template>
  <div>
    <button class= "return-button" @click="goBack">Retour</button>
    <div class="container">
      <div class="grid">
        <div class="grid-item" v-for="(item, index) in items" :key="index">
          <router-link :to="item.link">
            <div class="div_txt">
              <h1>{{ item.title }}</h1>
              <h2>{{ item.subtitle }}</h2>
              <h3>{{ item.responsables }}</h3>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>




<script>

export default {
  name: "CategoriesComponent",
  props: {
    items: {
      type: Array,
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
}
</script>


<style scoped>
.grid {
  padding-top: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 16px;
  justify-content: center;
  align-items: center;
  height: 60vh;
}

.grid-item {
  background-color: #3369e7;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}

.grid-item h1 {
  font-size: 24px;
  margin: 0;
}

.grid-item h2 {
  padding: 20px;
  font-size: 16px;
  margin: 0;
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.blue-button {
  background-color: white;
  color: blue;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  align-self: center;
  margin-top: 10px;
  cursor: pointer;
}

.div_txt {
  font-family: "Inter-Regular", sans-serif;
  padding: 20px;
  color: white;
  text-align: center;
  height: 300px;
}

h1 {
  font-size: 24px;
  margin: 0;
}

h2 {
  padding: 20px;
  font-size: 16px;
  margin: 0;
}

</style>
