<template>
    <!-- on affiche les catégories -->
    <categories-component :items="items" />
  </template>
  
  <script>
  import CategoriesComponent from "@/components/Categories.vue";
  export default {
    name: "socialVue",
    data() {
      return {
        // Exemple de données pour les nœuds
        items: [
          {
            title: "Intervenant: Émettre et payer un BV",
            link: "/BV",
            responsables: "Pôle Treso - Pôle Actico",
          },
          {
            title: "Déclaratif: BRC",
            link: "/brc",
            responsables: "Pôle Treso",
          },
          {
            title: "Déclaratif: Tableau récapitulatif (TR)",
            link: "/tr",
            responsables: "Pôle Treso",
          },
          {
            title: "Déclaratif: DADS",
            link: "/dads",
            responsables: "Pôle Treso",
          },

        ],
      };
    },
    components: {
      CategoriesComponent,
    },
  };
  </script>