<!-- DON'T TOUCH -->
<template>
    <div>
      <button class="return-button" @click="goBack">Retour</button>
      <TitleComponent :Title="Title" />
      <DiagramComponent
        :format="Format"
        :nodes="nodes"
        :linkData="linkData"
        :componentList="componentList"
      />
    </div>
  </template>
      
  
  
    <script>
  // DON'T TOUCH
  import DiagramComponent from "@/components/Diagram.vue";
  import TitleComponent from "@/components/TitlePage.vue";
  
  export default {
    name: "suivi_clientView", // Nom à changer
    data() {
      return {
        //Texte à remplir
        Title: {
          mainTitle:
            "Activité Commerciale",
          subTitle: "Suivi Relation Client",
          subSubTitle: "Processus: Suivi Relation Client",
          supervisor: "Pôle Activité Commerciale",
          derniere_modif: "28/03/2024 par Enzo CHAMPAGNE",
        },
  
        // Exemple des différents type de nœuds
        nodes: [
          {
            key: "0",
            text: 'Client ajouté à la liste des prospects',
            type: "Start", // Met en vert et de forme elliptique (genre ovale)
          },
          {
            key: "1",
            text: "Mise en place des canaux de communication (email, téléphone ...)",
            noLinkauto: true,
          },
          {
            key: "2",
            text: "Mise en place du CDC en communiquant avec le Client",
          },
          {
            key: "3",
            text: "Si possible, faire un RDV physique pour signer la CE",
          },
          { key: "4", text: "Maintenir un contact régulier avec le Client (1 échange par semaine), proposer un RDV s'il y a des avancées/questions", noLinkauto: true, },
          {
            key: "5",
            text: "Avoir au moins un RDV mensuel avec le Client et l'Intervenant",
          },
          { key: "6", text: "Archiver les échanges mails/SMS" },
          {
            key: "7",
            text: "(Optionnel) \n Demander la satisfaction du Client pour voir s'il faut modifier l'approche",
          },
          {
            key: "8",
            text: "Proposer un RDV de clôture pour signer le PVRF",
            noLinkauto: true,
          },
          { key: "9", 
          text: "Envoyer un questionnaire de satisfaction de fin d'étude"},
  
  
          { key: "10", text: "Recontacter le Client quelques mois après la fin de l'étude",},
          { key: "11", text: "(Optionnel) \n Proposer d'organiser des Afterwork, proposer un RDV plus informel",},
          {
            key: "13",
            text: "Fin de l'étude",
            type: "End",
          },
        ],
  
        linkData: [
          {
            from: "0",
            to: "1",
            Emplacement: 1,
            label: "Avant l'étude",
            Espace: "grand",
          },
          {
            from: "3",
            to: "4",
            Emplacement: 1,
            label: "Pendant l'étude",
            Espace: "grand",
          },
          {
            from: "7",
            to: "8",
            label: "Après l'étude",
            Emplacement: 2,
          },
        ],
        componentList: [
        ],
      };
    },
    components: {
      DiagramComponent,
      TitleComponent,
    },
    methods: {
      goBack() {
        this.$router.go(-1);
      },
    },
  };
  </script> 