import clr from '../views/clr/clr.vue';
import tresorerie from '../views/tresorerie/tresorerie.vue';
import autres from '../views/autres/autres.vue';
import com from '../views/com/com.vue';
import qualite from '../views/qualite/qualite.vue';
import actiCo from '../views/suivi_etude/actiCo.vue';
import rfp from '../views/rfp/rfp.vue';
import comptabilite from '../views/comptabilite/comptabilite.vue';

// Pour rajouter la route pour une des grandes catégories
// import nom de la vue from '../views/tresorerie/component_souhaité.vue';


const routes = [

    // Pour rajouter la route pour une des grandes catégories :
    
    // { path: '/nom_chemin', name: 'Nom de la route', component: nom de la vue }
    
    { path: '/clr', name: 'Cadre Légal et Réglementaire', component: clr },
    { path: '/tresorerie', name: 'Trésorerie', component: tresorerie },
    { path: '/autres', name: 'Autres', component: autres },
    { path : '/com', name: 'Communication', component: com },
    {path : '/qualite', name: 'Qualité', component: qualite},
    {path : '/actiCo', name: 'Activité Commerciale', component: actiCo},
    {path : '/rfp', name: 'rfp', component: rfp},
    {path : '/comptabilite', name: 'comptabilite', component: comptabilite},
    

];

export default routes;
