<!-- DON'T TOUCH -->
<template>
    <div>
      <button class="return-button" @click="goBack">Retour</button>
      <TitleComponent :Title="Title" />
      <DiagramComponent
        :format="Format"
        :nodes="nodes"
        :linkData="linkData"
        :componentList="componentList"
      />
    </div>
  </template>
      
  
  
    <script>
  // DON'T TOUCH
  import DiagramComponent from "@/components/Diagram.vue";
  import TitleComponent from "@/components/TitlePage.vue";
  
  export default {
    name: "NFGView", // Nom à changer
    data() {
      return {
        //Texte à remplir
        Title: {
          mainTitle:
            "Trésorerie",
          subTitle: "Achats",
          subSubTitle: "Processus: Note de Frais Généraux",
          supervisor: "Trésorier",
          derniere_modif: "03/04/2024 par Mathieu CHAILLON",
        },
  
        // Exemple des différents type de nœuds
        nodes: [
          {
            key: "0",
            text: 'NFG Prévu',
            type: "Start", // Met en vert et de forme elliptique (genre ovale)
          },
          {
            key: "1",
            text: "Si pas effectué suivre la première partie du processus Achats",
            link : "processus_achats"

          },
          {
            key: "2",
            text: "Si l'achat est validé le rajouter dans le Trello des Notes de Frais pour le suivi colonne 'Demande de NFG'",
            external_link : "https://trello.com/b/YZfwFVbr/notes-de-frais",
            supervisor : "Trésorier"

          },
          {
            key: "2",
            text: "Achats classiques hors transport",
            addDecX : -150
            
          },
          {
            key: "4",
            text: "Régle à respecter : Facture au nom de Aquitaine Electronique Informatique",
          },
          {
            key: "3",
            text: " Achats pour les transports",
            indPrec : 3,
            direction : "right",
            noLinkauto : true

          },
          {
            key: "4",
            text: "Régle à respecter : \n Essence : Plein avant le trajet et après le trajet sinon pas de remboursement du premier plein \n Péage besoin du Ticket de péage en physique \n Train justificatif de la SNCF",
          },
          {
            key: "5",
            text: "Ajouter la carte sur le trello dans 'NFG en attente de justificatif et d'infos' ",
            supervisor: "Pole Trésorerie",
            external_link : "https://trello.com/b/YZfwFVbr/notes-de-frais",
            addDecX : -150,
            addDecY : 50,
            noLinkauto : true

          },
          { key: "6", 
            text: "Donner au Pole Trésorerie les factures, les différents justificatifs, son RIB ainsi que son adresse actuelle",
            supervisor: "Personne qui fait l'achat",
          },
          
          { key: "7", 
            text: "Rajout des infos transmises à la carte Trello et mise dans la case NFG à Faire",
            supervisor: "Pole Trésorerie",
            external_link : "https://trello.com/b/YZfwFVbr/notes-de-frais",

          },
          { key: "8", 
            text: "Remplir le DT de NFG avec les informations récupérés et déposer cette version sur trello",
            supervisor: "Trésorier ou Vice Trésorier",
            component : "0"
          },
          { key: "9", 
            text: "Relecture ",
            link:"relectureTreso",
            supervisor: "Trésorier ou Vice Trésorier",
          },
          { key: "10", 
            text: "Faire signer la NFG en ligne sur LiveConsent ou en présentiel au Local (à privilégier c'est gratuit) et la passer sur Trello en Attente de Signature ",
            external_link: "https://app.liveconsent.com/home/",
            supervisor: "Trésorier",
          },
          { key: "10", 
            text: "Mettre la NFG sur la Dropbox avec l'ensemble des justificatifs",
            supervisor : "Trésorier",
          },
          { key: "11", 
            text: "La rentrer en compta",
            supervisor : "Trésorier",
            component : 1
       
          },
          {
            key: "13",
            text: "Achat effectué et NFG remboursé",
            type: "End",

          },

        ],
  
        linkData: [
        {
            from: "2",
            to: "5",
          },
          {
            from: "4",
            to: "7",
            label : "Achat effectué"
          },
          {
            from: "6",
            to: "7",
            label : "Achat effectué"
          },
          
        ],
        
        componentList: [
        {
            number: "0",
            Name: "Informations utiles pour savoir comment le remplir",
            texteMarkdown : `**Voici tout ce qu'il faut savoir pour bien remplir une NFG** 

Le DT est disponible : [ici](https://drive.google.com/drive/folders/1fXZWO1eWgK72EuV7moSJQuNgjkvQI2Ib)

L'approbateur doit être un validateur de l'achat (membre du CA) différent de l'acheteur 

Les NFG pour le trésorier doivent être signées par le président
            `
          },
          {
          number: "8",
          Name: "Rentrer en compta la NFG",
          texteMarkdown: `Informations sur les entrés comptables lors d'une NFG`,
          images : [require("@/assets/comptaNFG1.png"),
          require("@/assets/comptaNFG2.png"),]
        
        },
      ],
      };
    },
    components: {
    DiagramComponent,
    TitleComponent,
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};

</script> 