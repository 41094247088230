<!-- Ce document est un exmple pour une vue de menu avec les magnifiques cases bleus 
Genre c'est pour faire un menu -->

<!-- DO NOT TOUCH -->
<template>
    <categories-component :items="items" />
  </template>
  
  <script>
  // DO NOT TOUCH
  import CategoriesComponent from "@/components/Categories.vue";
  
  
  //Votre partie (vous avez le droit de remplir ;)
  export default {
    name: "templateMenu", // Nom de la Page
  
    data() {
      return {
        //Infos des différentes cases du menu 
        items: [
          {
            title: "Assemblée Générale (AG)", // Titre affiché
            subtitle: "", // Sous titres en plus petit en dessous (optional)
            link: "/gestion_reunion/assemblee_generale",
            responsables: "Secrétaire Général, Présidence,Trésorier",// Responsables des process de la catégorie (optional)
          },
          {
            title: "Conseil d'Administration (CA)",
            subtitle: "",
            link: "/gestion_reunion/conseil_administration",
            responsables: "Secrétaire général, Présidence",
          },
          {
            title: "Réunion Hebdomadaire",
            subtitle: "",
            link: "/gestion_reunion/reunion_hebdomadaire",
            responsables: "Conseil d'Administration",
          },

        ],
      };
    },
    components: {
      CategoriesComponent,
    },
  };
  </script>
  