


<template>
    <ListCocheComponent :infos="infos" :Title="Title" @Click-close="goBack" />
  </template>
     
     <script>
  import ListCocheComponent from "@/components/ListeValidation.vue";
  
  export default {
    name: "list_example",
    components: {
      ListCocheComponent,
    },
    data() {
      return {
        Title: {
          mainTitle: "Cadre Légal et Réglementaire",
          subTitle: "Gestion Associative",
          subSubTitle: "Procédure: Gestion du classeur d'association",
          supervisor: "Secrétaire Général",
          derniere_modif: "27/02/24 par Bastien HUET",
          image :require("@/assets/clr.png")
        },
        infos: {
          items: [
            " Archive tous les Comptes-Rendu de CA",
            " Archives tous les PV d'AG",
            " Archive toutes les déclarations CNIL",
            " Archive toutes les licences utilisées par la Junior",

          ],
          texte_if_all: "Bravo à toi tu es un génie",
          link_if_all:
            "https://docs.google.com/presentation/d/1Nuk0xAWRt1VpCjENFtEoXKJUuYKVm6btvSVDb-fGhW4/edit?usp=sharing",
          texte_link: "Cliquez ici",
      
        },
      };
    },
    methods: {
      goBack() {
        this.$router.go(-1);
      },
    },
  };
  </script>