<!-- DON'T TOUCH -->
<template>
    <div>
      <button class="return-button" @click="goBack">Retour</button>
      <TitleComponent :Title="Title" />
      <DiagramComponent
        :format="Format"
        :nodes="nodes"
        :linkData="linkData"
        :componentList="componentList"
      />
    </div>
  </template>
      
  
  
    <script>
  // DON'T TOUCH
  import DiagramComponent from "@/components/Diagram.vue";
  import TitleComponent from "@/components/TitlePage.vue";
  
  export default {
    name: "brcProcessus", // Nom à changer
    data() {
      return {
        //Texte à remplir
        Title: {
          mainTitle:
            "Trésorerie",
          subTitle: "Social ",
          subSubTitle: "Processus: BRC",
          supervisor: "Trésorier,Comptable, Vice-Trésorier",
          derniere_modif: "29/03/2024 par Mathieu CHAILLON",
        },
  
        // Exemple des différents type de nœuds
        nodes: [
          {
            key: "0",
            text: 'BRC à effectuer',
            type: "Start", // Met en vert et de forme elliptique (genre ovale)
          },
          {
            key: "1",
            text: "S'assurer que l'onglet 'BV & RM du Tableau de Suivi est à jour. (necessaire pour remplir le BRC)'",
            component: "0",
            supervisor: 'Vice-Trésorier ou Trésorier'
          },
          {
            key: "3",
            text: "Lister les BV émis pour la période. (il suffit de lire le Tableau de Suivi des BV)",
            component: "1",
            supervisor: 'Vice-Trésorier ou Trésorier',
            addDecY : 50,
          },
          {
            key: "4",
            text: "Sortir le nombre de JEH payés sur l'ensemble des BV de la période pour calculer l'Assiette de Cotisation",
            supervisor: 'Vice-Trésorier ou Trésorier'
          },
          { key: "5", 
          text: "Accéder à l'espace URSSAF en s'identifiant (Mot de Passe présent dans Passbolt)" ,
          external_link : "https://www.urssaf.fr/portail/home.html",
          noLinkauto: true,
          addDecY : 50,

          },
          {
            key: "6",
            text: " Cliquer dans le menu à droite sur 'Déclarer des Cotisations'",
            addDecY : -20,
            
          },
          {
            key: "7",
            text: "Effectif au dernier jour de la période: mettre '1' s'il y a au moins eu un BV et mettre '0' sinon",
            addDecY : -20,
          },
          {
            key: "8",
            text: "Remplir l'effectif rémunéré sur la période (nombre d'intervenant DIFFERENTS ayant eu au moins un BV)",
        
          },
          { key: "9", 
          text: "Supprimer certaines lignes du tableau avant la déclaration en cliquant sur l'onglet 'supprimer' bleu en haut à droite de l'écran", 
          component:"2",
        },
          { key: "10", 
          text: "Remplir le tableau",
          component: "3"
          },
          {
            key: "12",
            text: "Contrôle du Vice-Trésorier: Vérifier que la somme de tous les montants de toutes les cotisations Junior présentes sur tous les BV correspond au déclaratif",
            supervisor: 'Vice-Trésorier',
            addDecY : -50,
          },
          {
            key: "13",
            text: "Contrôle du Comptable: Double contrôle avec le compte 431",
            supervisor:'Comptable',
            addDecY : +50,
          },
          {
            key: "14",
            text: "Archivage d'un Grand Livre du compte 431 par le comptable",
            supervisor:'Comptable'
          },
          {
            key: "15",
            text: "Une fois les montants vérifiés cliquer sur 'Envoyer' (Si la déclaration est faite en avance avant le 15 du mois, elle peut être modifié jusqu'au 15 du mois)",
            
          },
          {
            key: "16",
            text: "Sauvegarder les documents et les archiver",
            component: '4',
            addDecY : +50,
            
          },
          {
            key: "17",
            text: "Marquer les BV déclarés comme tel dans le Tableau de Suivi",
          },
          {
            key: "18",
            text: "Imprimer et ranger tous les documents à archiver dans le Classeur Social",
          },
          {
            key: "19",
            text: "Comptabilisé le BRC",
            component : 5
          },
          {
          key: "20",
          text: "Le BRC a été effectué avec succès",
          type: "End",
        },
        ],
  
        linkData: [
          {
            from: "3",
            to: "4",
            Emplacement: 1,
            label: "On peut maintenant passer à la Déclaration des Cotisations",
            
            Espace: "grand",
          },
       
        ],
        componentList: [
        {
          number: "0",
          Name: "S'assurer que l'onglet BV et RM du Tableau de suivi sont à jour",
          infosList: {
            texte_before :  " Le Tableau de Suivi (TS) fourni par la CNJE via Kiwi Légal calcule automatiquement les cotisations dues pour l'ensemble des BVs",

            items: ["Vérifier que l'ensemble des BVs émis soient présents dans l'onglet ", 
            "Revérifier les taux présents dans l'onglet Taux cotisation du TS",
            "Vérifier que le bon Taux soient renseignés pour chaque BV"
            ],
          },
        },
        {
          number: "1",
          Name: "Lister les BV émis pour la période",
            texteMarkdown : `En règle générale, les BV sont émis et payés en même temps.  
            Cependant, dans les cas d'un BV émis mais non-payé, c'est bien la date **d'EMISSION** de ce dernier qui fait foi.  
            Les BVs à déclarer sont ceux émis le mois précédent. Regardez la colonne Mois Décla URSSAF du TS pour vérifier.  
            **Vérifiez** si vous avez bien déclaré les BVs des mois précédents.`,
            
        },
        {
          number: "3",
          Name: "Supprimer certaines lignes du tableau: supprimer les lignes suivantes",

          infosList: {
            image : require("@/assets/BRCsuppr.png"),
            items: [
              " CREDIT IMPOT COMPETITIVITE EMPLOI - BASE DEPLAFONNEE (code : 400D)",
              " REPRODUCTION GENERALE - BASE PLAFONNEE (code : 671P)",
              " APPRENTIS SECT PRIVE INF SEUIL - ACCIDENT DU TRAVAIL (code : 726A)",
              " APPRENTIS SECT PRIVE INF SEUIL - BASE DEPLAFONNEE (code : 726D)",
              " APPRENTIS SECT PRIVE INF SEUIL - BASE PLAFONNEE  (code : 726P)",
              " CONTRIBUTIONS ASSURANCE CHOMAGE - BASE PLAFONNEE (code : 772D) (nous n'y sommes plus assujeti depuis mars 2023) ",
              " COTISATION AGS CAS GENERAL - BASE DEPLAFONEE (code : 937D) (nous y sommes plus assujeti depuis mars 2023 )",
            ],
            
          },
        },
        {
          number: "5",
          Name: "Remplir et exporter l'aperçu",
          infosList: {
            items : ["Dans la colonne Salaires arrondis mettre à chaquer ligne la somme de l'assiette de cotisation de tout les BVs du mois déclaré",
          "Vérifier le taux AT à la ligne 100A ",
          "Exporter en cliquant sur aperçu avant envoi et le mettre sur la Dropbox pour relecture"]
          }
        },
        {
          number: "5",
          Name: "A archiver",
          infosList: {
            items: [
              " Le récapitulatif de déclaration",
              " Le justificatif de paiement",
              " L'avis de réception",
              " GL du compte 431"
            ],
            
          },
        },
        {
          number: "8",
          Name: "Rentrer en compta le BRC",
          texteMarkdown: `Informations sur les entrés comptables lors d'un BRC`,
          images : [require("@/assets/comptaBRC1.png"),
          require("@/assets/comptaBRC2.png"),]
        
        }
      ],
      };
    },
    components: {
      DiagramComponent,
      TitleComponent,
    },
    methods: {
      goBack() {
        this.$router.go(-1);
      },
    },
  };
  </script> 