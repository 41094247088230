<!-- DON'T TOUCH -->
<template>
  <div>
    <button class="return-button" @click="goBack">Retour</button>
    <TitleComponent :Title="Title" />
    <DiagramComponent :format="Format" :nodes="nodes" :linkData="linkData" :componentList="componentList" />
  </div>
</template>



<script>
// DON'T TOUCH
import DiagramComponent from "@/components/Diagram.vue";
import TitleComponent from "@/components/TitlePage.vue";

export default {
  name: "dadsProcessus", // Nom à changer
  data() {
    return {
      //Texte à remplir
      Title: {
        mainTitle:
          "Trésorerie",
        subTitle: "Ventes",
        subSubTitle: "Processus: Cotisations",
        supervisor: "Trésorier",
        derniere_modif: "22/03/2024 par Mathieu CHAILLON",
      },

      // Exemple des différents type de nœuds
      nodes: [
        {
          key: "0",
          text: 'Cotisation',
          type: "Start", // Met en vert et de forme elliptique (genre ovale)
        },
        {
          key: "1",
          text: "Cotisation Hors Etude (Direct sur Hello Asso)",
          supervisor: "Trésorier ou Sec Gen",
          addDecX: -150
        },
        {
          key: "1",
          text: "Récupérer le justificatif de payement et le transmettre au comptable",
          supervisor: "Trésorier ou Vice Trésorier",
        },
        {
          key: "1",
          text: "Mettre en compta",
          supervisor: "Comptable",
          component: 1
        },
        {
          key: "1",
          text: "Cotisation Etude (Aussi sur Hello Asso)",
          supervisor: "Trésorier ou Sec Gen",
          indPrec: 1,
          direction: "right",
          noLinkauto: true
        },
        {
          key: "2",
          text: "Récupérer les infos sur Hello Asso de l'adhérent et les rajouter à l'onglet Intervenant du TS ",
          supervisor: "Trésorier",
          noLinkauto: true
        },
        {
          key: "3",
          text: "Remplir le DT de Facture de Cotisation avec les infos \n Numéro : FC-2X2X-X à la suite de la FC précédente",
          supervisor: "Trésorier ou Vice Trésorier",
        },
        {
          key: "4",
          text: "Relecture",
          link: "relectureTreso",
          supervisor: "Trésorier ou Vice Trésorier",
        },
        {
          key: "5",
          text: "Renseigner le TS et mettre la Facture de Cotisation sur la DropBox ",
          supervisor: "Trésorier ou Vice Trésorier",
        },
        {
          key: "6",
          text: "Mettre en compta",
          component: 1,
          supervisor: "Comptable",
        },
        {
          key: "7",
          text: "Cotisation traitée",
          type: "End",
          addDecX: -150

        },
      ],

      linkData: [
        {
          from: 0,
          to: 4
        },
        {
          from: 3,
          to: 9
        },
        {
          from: 4,
          to: 5,
          label: "Lorsque le BA a été émis "
        }
      ],

      componentList: [
        {

        },
        {
          number: "1",
          Name: "Compta d'une Cotisation",
          images: [require("@/assets/comptaCotis1.png"),
          require("@/assets/comptaCotis2.png"),
          require("@/assets/comptaCotis3.png"),],


        },

      ],
    };
  },
  components: {
    DiagramComponent,
    TitleComponent,
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};

</script>