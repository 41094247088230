
import qualite from '../views/qualite/qualite.vue';
import creation_pda from '../views/qualite/creation_pda.vue';
import definition_strategie from '../views/qualite/definition_strategie.vue';
// Pour rajouter la route pour votre vue
// import nom de la vue(son emplacement dans le dossier) from '../views/catégorie/component_souhaité.vue';


const routes = [

    // Pour rajouter la route pour une des grandes catégories :
    
    // { path: '/nom_chemin', name: 'Nom de la route', component: nom de la vue }
    { path: '/qualite', name: 'qualite', component: qualite },
    { path: '/qualite/creation_pda', name:'creation_pda', component: creation_pda},
    { path: '/qualite/definition_strategie', name: 'definition_strategie', component: definition_strategie},

];

export default routes;
