<!-- DON'T TOUCH -->
<template>
    <div>
      <button class="return-button" @click="goBack">Retour</button>
      <TitleComponent :Title="Title" />
      <DiagramComponent
        :format="Format"
        :nodes="nodes"
        :linkData="linkData"
        :componentList="componentList"
      />
    </div>
  </template>
      
  
  
    <script>
  // DON'T TOUCH
  import DiagramComponent from "@/components/Diagram.vue";
  import TitleComponent from "@/components/TitlePage.vue";
  
  export default {
    name: "document_erpView", // Nom à changer
    data() {
      return {
        //Texte à remplir
        Title: {
          mainTitle:
            "Activité Commerciale",
          subTitle: "Avant Etude",
          subSubTitle: "Procédure : Uploader un document sur l'ERP",
          supervisor: "Pôle Activité Commerciale",
          derniere_modif: "26/03/2024 par Enzo CHAMPAGNE",
        },
  
        // Exemple des différents type de nœuds
        nodes: [
          {
            key: "0",
            text: 'Document rédigé mais non uploader',
            type: "Start",
          },
          {
            key: "1",
            text: "Se connecter à l'ERP",
          },
          {
            key: "2",
            text: "Onglet : Prospection",
          },
          {
            key: "3",
            text: "Onglet : Voir les prospects",
          },
          {
            key: "4",
            text: "Sélectionner le prospect",
          },
          {
            key: "5",
            text: "Onglet : Document",
          },
          {
            key: "6",
            text: "Cliquer sur 'Ajouter un document avec relecture'",
          },
          {
            key: "7",
            text: "Le document doit être en PDF et avoir un nom court (devis1, devis2, ...)",
          },
          {
            key: "8",
            text: "Envoyer un message sur le groupe Télégram de relecture",
          },
          {
            key: "9",
            text: "Devis envoyé en relecture",
            type: "End",
          },
          {
            key: "10",
            text: "IMPORTANT : Ne pas modifier le nom du fichier dans le cas de correction du document avec la Qualité. \n Le nom ne change que si le Client n'est pas satisfait du précédent document envoyé !",
            direction: "right",
            noLinkauto: true,
          },
        ],
  
        linkData: [
        ],
        componentList: [
        ],
      };
    },
    components: {
      DiagramComponent,
      TitleComponent,
    },
    methods: {
      goBack() {
        this.$router.go(-1);
      },
    },
  };
  </script> 