<!-- Ce document est un exmple pour une vue de menu avec les magnifiques cases bleus 
Genre c'est pour faire un menu -->

<!-- DO NOT TOUCH -->
<template>
    <categories-component :items="items" />
  </template>
  
  <script>
  // DO NOT TOUCH
  import CategoriesComponent from "@/components/Categories.vue";
  
  
  //Votre partie (vous avez le droit de remplir ;)
  export default {
    name: "actiCoView", // Nom de la Page
  
    data() {
      return {
        //Infos des différentes cases du menu 
        items: [
          {
            title: "Avant étude",
            subtitle: "Processus Général, Réponse à un Appel d'Offre",
            link: "/avant_etude",
            responsables: "Pôle Activité Commerciale",
          },

          {
            title: "Pendant étude", // Titre affiché
            subtitle: "Suivi d'étude et qualité", // Sous titres en plus petit en dessous (optional)
            link: "/pendant_etude", //Lien vers la page que vous souhaitez rejoindre (path dans les routers)
            responsables: "Pôle Activité Commerciale",
          },
          {
            title: "Fin étude",
            subtitle: "De la vérification du livrable à l'archivage de l'étude",
            link: "/fin_etude",
            responsables: "Pôle Activité Commerciale, Pôle Qualité, Trésorerie, Présidence, Secrétaire Général",
          },
          {
            title: "Suivi Relation Client",
            subtitle: "Processus du suivi Client du début à la fin de l'étude",
            link: "/suivi_client",
            responsables: "Pôle Activité Commerciale",
          },
          {
            title: "Suivi Intervenant",
            subtitle: "Processus du suivi Intervenant du début à la fin de l'étude",
            link: "/suivi_intervenant",
            responsables: "Pôle Activité Commerciale",
          },
          {
            title: "Sous-traitance",
            subtitle: "Cas de sous-traitance d'une ou plusieurs phase avec une autre entité lors d'une étude",
            link: "/sous_traitance",
            responsables: "Pôle Activité Commerciale, Présidence, Trésorier",
          },
        ],
      };
    },
    components: {
      CategoriesComponent,
    },
  };
  </script>