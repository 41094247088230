<template>
  <!-- on affiche les catégories -->
  <categories-component :items="items" />
</template>

<script>
import CategoriesComponent from "@/components/Categories.vue";
export default {
  name: "tresoView",
  data() {
    return {
      // Exemple de données pour les nœuds
      items: [
        {
          title: "Social",
          subtitle: "Partenariats et stages ",
          link: "/social",
          responsables: "Pole Treso",
        },
        {
          title: "Fiscal",
          subtitle: "",
          link: "/fiscal",
          responsables: "Pole Treso",
        },
        {
          title: "Achats",
          subtitle: "NFG, Compte BNP, Commandes",
          link: "/achats",
          responsables: "Pole Treso, Présidence, Pole Etude",
        },
        {
          title: "Ventes",
          subtitle: "Factures, Refacturation",
          link: "/ventes",
          responsables: "Pole Treso",
        },
        {
          title: "Transversal",
          subtitle: "Accés PassBolt, Dropbox, Trello,Budget Previsionnel",
          link: "/transversal",
          responsables: "Pole Treso",
        },
      ],
    };
  },
  components: {
    CategoriesComponent,
  },
};
</script>