import envoi_com from '../views/com/envoi_com/envoi_com.vue';

import organisation_event from '../views/com/organisation_event/organisation_event.vue';


const routes = [

    // Pour rajouter la route pour une des grandes catégories :
    
    // { path: '/nom_chemin', name: 'Nom de la route', component: nom de la vue },
    { path: '/envoi_com', name: 'envoi_com', component: envoi_com },

    { path: '/organisation_event', name: 'organisation_event', component: organisation_event },

];

export default routes;