
import pendant_etude from '../views/suivi_etude/pendant_etude/pendant_etude.vue';

import fin_etude from '../views/suivi_etude/fin_etude/fin_etude.vue';

import sous_traitance from '../views/suivi_etude/sous_traitance/sous_traitance.vue';

import passation_etude from '../views/suivi_etude/pendant_etude/passation_etude.vue';

import redaction_ace from '../views/suivi_etude/pendant_etude/redaction_ace.vue';

import redaction_arm from '../views/suivi_etude/pendant_etude/redaction_arm.vue';

import emission_pvri from '../views/suivi_etude/pendant_etude/emission_pvri.vue';

import commande_pieces from '../views/suivi_etude/pendant_etude/commande_pieces.vue';

import rupture_rm from '../views/suivi_etude/pendant_etude/rupture_rm.vue';

import rupture_ce from '../views/suivi_etude/pendant_etude/rupture_ce.vue';

import avant_etude from '../views/suivi_etude/avant_etude/avant_etude.vue';

import processus_general from '../views/suivi_etude/avant_etude/processus_general/processus_general.vue';

import appel_offre from '../views/suivi_etude/avant_etude/appel_offre/appel_offre.vue';

import redaction_devis from '../views/suivi_etude/avant_etude/processus_general/redaction_devis.vue';

import verification_solvabilite from '../views/suivi_etude/avant_etude/processus_general/verification_solvabilite.vue';

import document_erp from '../views/suivi_etude/avant_etude/processus_general/document_erp.vue';

import redaction_ce_cc from '../views/suivi_etude/avant_etude/processus_general/redaction_ce_cc.vue';

import redaction_rm from '../views/suivi_etude/avant_etude/processus_general/redaction_rm.vue';

import recrutement_intervenant from '../views/suivi_etude/avant_etude/processus_general/recrutement_intervenant.vue';

import redaction_BA from '../views/suivi_etude/avant_etude/processus_general/redaction_BA.vue';

import premier_entretien_intervenant from '../views/suivi_etude/avant_etude/processus_general/premier_entretien_intervenant.vue';

import suivi_client from '../views/suivi_etude/suivi_client/suivi_client.vue';

import call_center from '../views/suivi_etude/avant_etude/call_center/call_center.vue';

import prospection from '../views/suivi_etude/avant_etude/prospection/prospection.vue';

import suivi_intervenant from '../views/suivi_etude/suivi_intervenant/suivi_intervenant.vue';

import rdv_client from '../views/suivi_etude/avant_etude/processus_general/rdv_client.vue';
// Pour rajouter la route pour une des grandes catégories
// import nom de la vue(son emplacement dans le dossier) from '../views/catégorie/component_souhaité.vue';


const routes = [

    // Pour rajouter la route pour une des grandes catégories :
    
    // { path: '/nom_chemin', name: 'Nom de la route', component: nom de la vue }
    {path: '/avant_etude', name: 'avant_etude', component: avant_etude},

    {path: '/avant_etude/processus_general', name: 'processus_general', component: processus_general},

    {path: '/avant_etude/appel_offre', name: 'appel_offre', component: appel_offre},
    
    { path: '/pendant_etude', name: 'pendant_etude', component: pendant_etude },
    
    { path: '/fin_etude', name: 'fin_etude', component: fin_etude },

    { path: '/sous_traitance', name: 'sous_traitance', component: sous_traitance },

    {path: '/pendant_etude/passation_etude', name: 'passation_etude', component: passation_etude},

    {path: '/pendant_etude/redaction_ace', name: 'redaction_ace', component: redaction_ace},

    {path: '/pendant_etude/redaction_arm', name: 'redaction_arm', component: redaction_arm},

    {path: '/pendant_etude/emission_pvri', name: 'emission_pvri', component:emission_pvri},

    {path: '/pendant_etude/commande_pieces', name: 'commande_pieces', component: commande_pieces},

    {path: '/pendant_etude/rupture_rm', name: 'rupture_rm', component: rupture_rm},

    {path: '/pendant_etude/rupture_ce', name: 'rupture_ce', component: rupture_ce},

    {path: '/avant_etude/processus_general/redaction_devis', name: 'redaction_devis', component: redaction_devis},

    {path: '/avant_etude/processus_general/verification_solvabilite', name: 'verification_solvabilite', component: verification_solvabilite},

    {path: '/avant_etude/processus_general/document_erp', name: 'document_erp', component: document_erp},

    {path: '/avant_etude/processus_general/redaction_ce_cc', name: 'redaction_ce_cc', component: redaction_ce_cc},

    {path: '/avant_etude/processus_general/redaction_rm', name: 'redaction_rm', component: redaction_rm},

    {path: '/avant_etude/processus_general/recrutement_intervenant', name: 'recrutement_intervenant', component: recrutement_intervenant},

    {path: '/avant_etude/processus_general/redaction_BA', name: 'redaction_BA', component: redaction_BA},

    {path: '/avant_etude/processus_general/premier_entretien_intervenant', name: 'premier_entretien_intervenant', component: premier_entretien_intervenant},

    {path: '/suivi_client', name: 'suivi_client', component: suivi_client},

    {path: '/avant_etude/call_center', name: 'call_center', component: call_center},

    {path: '/avant_etude/prospection', name: 'prospection', component: prospection},

    {path: '/suivi_intervenant', name: 'suivi_intervenant', component: suivi_intervenant},

    {path: '/avant_etude/processus_general/rdv_client', name: 'rdv_client', component: rdv_client},

];

export default routes;
