<template>
    <!-- on affiche les catégories -->
    <categories-component :items="items" />
  </template>
  
  <script>
  import CategoriesComponent from '@/components/Categories.vue';
  export default {
    name: "chartes_conventions",
    data() {
      return { items : [
      
      {
          title: 'Faire un virement',
          link: '/virement',
          responsables : "Trésorier"
      },
      {
          title: 'Processus Achats',
          link: '/processus_achats',
          responsables : "Tresorier"
      },
      {
          title: "Ajout d'un bénéficiaire sur le compte BNP de AEI",
          link: '/ajoutBeneficiaire',
          responsables : "Trésorier"
      },
      {
          title: "Note de Frais Généraux",
          link: '/nfg',
          responsables : "Pole Trésorerie"
      },
      ],
      };
    },
    components: {
      CategoriesComponent,
    },
  };
  </script>
  