<template>
    <!-- on affiche les catégories -->
    <categories-component :items="items" />
  </template>
  
  <script>
  import CategoriesComponent from "@/components/Categories.vue";
  export default {
    name: "fiscalVue",
    data() {
      return {
        // Exemple de données pour les nœuds
        items: [
          {
            title: "Déclaratif : TVA",
            link: "/tva",
            responsables: "Pôle Treso",
          },
          {
            title: "Déclaratif: IS",
            link: "/is",
            responsables: "Pôle Treso",
          },
          {
            title: "Déclaratif: DAS2",
            link: "/das2",
            responsables: "Pôle Treso",
          },
          {
            title: "Déclaratif: CFE",
            link: "/cfe",
            responsables: "Pôle Treso",
          },
        ],
      };
    },
    components: {
      CategoriesComponent,
    },
  };
  </script>