
import Menu from '../views/autres/templates/MenuTemplate.vue';
import Markdown from '../views/autres/templates/MarkdownExample.vue';
import List from '../views/autres/templates/listExample.vue';
import DiagramPage from '../views/autres/templates/DiagramPageExample.vue';

// Pour rajouter la route 
// import nom de la vue from '../views/tresorerie/component_souhaité.vue';


const routes = [

    // Pour rajouter la route  :
    
    // { path: '/nom_chemin', name: 'Nom de la route', component: nom de la vue }
    { path: '/menuExample', name: 'Example Menu', component: Menu },
    { path: '/textExample', name: 'Markdown Example', component: Markdown },    
    { path: '/listExample', name: 'List Example', component: List },    
    { path: '/diagramProcessExample', name: 'Diagram Page Example', component: DiagramPage } 

];

export default routes;
