<!-- DON'T TOUCH -->
<template>
  <div>
    <button class="return-button" @click="goBack">Retour</button>
    <TitleComponent :Title="Title" />
    <DiagramComponent :format="Format" :nodes="nodes" :linkData="linkData" :componentList="componentList" />
  </div>
</template>



<script>
// DON'T TOUCH
import DiagramComponent from "@/components/Diagram.vue";
import TitleComponent from "@/components/TitlePage.vue";

export default {
  name: "RelectureTrésorerie", // Nom à changer
  data() {
    return {
      //Texte à remplir
      Title: {
        mainTitle:
          "Trésorerie",
        subTitle: "Ventes",
        subSubTitle: "Processus : Gestions de Créances",
        supervisor: "Pole Trésorerie",
        derniere_modif: "28/03/2024",
      },

      // Exemple des différents type de nœuds
      nodes: [
        {
          key: "0",
          text: 'Refacturation',
          type: "Start", // Met en vert et de forme elliptique (genre ovale)
          
        },
        {
          key: "1",
          text: "Envoyer via Telegram la refacturation et les infos de relance",
          supervisor: "Tresorier ou Vice Tresorier"
        },
        {
          key: "2",
          text: "Rappel par Telegram",
          supervisor: "Tresorier ou Vice Tresorier",
          noLinkauto : true
        },
        {
          key: "3",
          text: "Si pas de réponse, essayez de contacter en réel",
          supervisor: "Tresorier ou Vice Tresorier",
          direction : "left",
          indPrec: 2
        },

        {
          key: "7",
          text: "Rappel par Telegram",
          supervisor: "Tresorier ou Vice Tresorier",
          indPrec: 2,
          noLinkauto : true


        },
        {
          key: "7",
          text: "Rappel par Telegram ",
          supervisor: "Tresorier ou Vice Tresorier",
          noLinkauto : true


        },
        {
          key: "3",
          text: "Si pas de réponse, passer par le responsable du pole de la personne pour avoir des infos",
          supervisor: "Tresorier ou Vice Tresorier",
          direction : "left",
        },
        {
          key: "7",
          text: "Dernier Rappel sur Telegram",
          supervisor: "Tresorier ou Vice Tresorier",
          indPrec: 5,
          noLinkauto : true

        },
        {
          key: "7",
          text: "RdV en  réel ou appel avec la Personne pour discuter de comment elle compte payer et Président mis au courant",
          supervisor: "Tresorier",
          noLinkauto : true

        },

       

        

        {
          key: "0",
          text: 'Facture Client',
          type: "Start", // Met en vert et de forme elliptique (genre ovale)
          indPrec:0,
          direction : "right",
          noLinkauto : true
          
        },
        {
          key: "1",
          text: "Demander au chargé d'étude de faire une relance",
          supervisor: "Tresorier et Pole Etude",
          noLinkauto : true

        },
        {
          key: "2",
          text: "Deuxième Rappel par le chargé d'étude",
          supervisor: "Tresorier et Pole Etude",
          noLinkauto : true

        },
        {
          key: "3",
          text: "Troisième Rappel par le chargé d'étude",
          supervisor: "Tresorier et Pole Etude",
          noLinkauto : true

        },
        {
          key: "3",
          text: "Sauf info contraire de payement proche, Mail par le Trésorier, Président mis au courant",
          supervisor: "Tresorier",
          noLinkauto : true

        },
        {
          key: "3",
          text: "Relance par Mail",
          supervisor: "Tresorier ou Président",
          noLinkauto : true


        },

        {
          key: "7",
          text: "Appel Téléphonique ",
          supervisor: "Tresorier ou Président",
          noLinkauto : true


        },

        {
          key: "3",
          text: "Relance par Mail",
          supervisor: "Tresorier ou Président",
          noLinkauto : true

        },
        {
          key: "7",
          text: "Prévenir la CNJE et Suivre leurs conseils ",
          supervisor: "Tresorier ou Vice Tresorier",
          noLinkauto : true

          

        },
        {
          key: "3",
          text: "Faire une mise en demeure si cela est conseillé (Demandez à Clément Zilliox Trésorier 22-23 pour + d'infos et de conseils sur cette étape)",
          supervisor: "Tresorier ou Vice Tresorier",
        },

        

        {
          key: "2",
          text: "Date de Payement à renseigner sur le TS, Processus Fini",
          type: "End",
          indPrec: 8

        },
        {
          key: "2",
          text: "Date de Payement à renseigner sur le TS, Processus Fini",
          type: "End",
          indPrec: 18
        },
        {
          key: "2",
          text: "Dès Payement \n reçu passer à la dernière case",
          type: "End",
          indPrec: 0,
          direction : "right",
          addDecX : -150,
          addDecY : 50,
          noLinkauto : true

        },

      ],

      linkData: [
        {
          from : 1,
          to : 2,
          label : "10 jours après"
        },
        {
          from : 2,
          to : 4,
          label : "10 jours après(20j)"
        },
        {
          from : 4,
          to : 5,
          label : "5 jours après (25j)"
        },
        {
          from : 5,
          to : 7,
          label : "3 jours après (28j)"
        },
        {
          from : 7,
          to : 8,
          label : "A la date d'échéance ou dans les 3 jours suivants"
        },
        {
          from : 9,
          to : 10,
          label : "20 jours après"
        },
        {
          from : 10,
          to : 11,
          label : "5 jours après (25j)"
        },
        {
          from : 11,
          to : 12,
          label : "5 jours après (30j)"
        },
        {
          from : 12,
          to : 13,
          label : "3j après date d'échéance"
        },
        {
          from : 13,
          to : 14,
          label : "7j après (+ 10j après échéance )"
        },
        {
          from : 14,
          to : 15,
          label : "5j après (+ 15j après échéance)"
        },
        {
          from : 15,
          to : 16,
          label : "5j après (+ 20j après échéance)"
        },
        {
          from : 16,
          to : 17,
          label : "10j après (+ 30j après échéance)"
        }
      ],

      componentList: [
      ],
    };
  },
  components: {
    DiagramComponent,
    TitleComponent,
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};

</script>