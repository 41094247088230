<!-- DON'T TOUCH -->
<template>
    <div>
      <button class="return-button" @click="goBack">Retour</button>
      <TitleComponent :Title="Title" />
      <DiagramComponent
        :format="Format"
        :nodes="nodes"
        :linkData="linkData"
        :componentList="componentList"
      />
    </div>
  </template>
      
  
  
    <script>
  // DON'T TOUCH
  import DiagramComponent from "@/components/Diagram.vue";
  import TitleComponent from "@/components/TitlePage.vue";
  
  export default {
    name: "das2Processus", // Nom à changer
    data() {
      return {
        //Texte à remplir
        Title: {
          mainTitle:
            "Trésorerie",
          subTitle: "Social",
          subSubTitle: "Processus: IS",
          supervisor: "Pole Trésorerie",
          derniere_modif: "03/04/2024 par Mathieu CHAILLON",
        },
  
        // Exemple des différents type de nœuds
        nodes: [
          {
            key: "0",
            text: 'IS à faire avant le 15 juillet',
            type: "Start", // Met en vert et de forme elliptique (genre ovale)
          },
          {
            key: "1",
            text: "Fait par l'Experte Comptable",
            addDecX : -150
          },
          {
            key: "2",
            text: "Taux en vigueur : \n 15 % pour un CA < 42500€ \n 25% si > à 42500€",
            direction : "right",
            noLinkauto : true
          },
          {
            key: "3",
            text: "Calculer le résultat fiscal / Déficit reportable ",
            supervisor: "Trésorier",
            component: 1
          },
          {
            key: "4",
            text: "Aller sur impots.gouv.fr et remplir le Formulaire 2572 (Voir en cliquant)",
            component: 2,
            supervisor: "Trésorier",
          },
          { key: "4", 
            text: "Comptabilisation à voir avec l'Expert Comptable",
            supervisor: "Comptable",
        },
          {
            key: "5",
            text: "Archivage au format numérique pendant 6 ans ",
          },
          { key: "6", 
          text: "Conserver le Compte-Rendu de saisie sur la Dropbox et dans les classeurs correspondants" ,
          supervisor: "Trésorier",
          },
         
          {
            key: "7",
            text: "La déclaration a été faite",
            type: "End",

          },

        ],
  
        linkData: [
          
       {
          from : 0,
          to : 2,
          label : "Si on change d'experte Comptable"
       }
        ],
        
        componentList: [
        {
          
        },
        
        {
          number: "1",
          Name: "Calculer le déficit/résultat",
          texteMarkdown : `### Résultat fiscal

Le résultat fiscal, montant sur lequel est basé l’IS, est établi à partir du résultat comptable (produits moins charges) auquel on déduit des produits non imposables (reprise de provision réintégrée etc.) et auquel on réintègre des charges non déductibles fiscalement (amendes et pénalités, dépenses exagérées dans leur montant etc.) qui ont été comptabilisées.

Ces rectifications se font hors comptabilité, à l’aide de l’imprimé n° 2058-A inclus dans votre liasse fiscale et sont détaillées dans l’article correspondant.

### Déficits reportables

Les déficits réalisés lors des années précédentes peuvent être imputés sur les bénéfices imposables réalisés et diminuent donc votre résultat fiscal.

Ces déficits peuvent être indéfiniment imputés sur les bénéfices futurs tant qu’ils n’ont pas été intégralement imputés. Ils sont à retrouver dans votre liasse fiscale.`,
            images : [require("@/assets/Resultat.png")]
        },
        {
          number: "0",
          Name: "Remplir le Formulaire",
          textes : [
              " En bleu, la déclaration de la part des bénéfices inférieure ou égale à 42 500 € ;",
              "En rouge, si les bénéfices sont supérieurs à 42 500 €, il faudra déclarer cette part.",],
            images : [require("@/assets/IS.png")]
        },
      ],
      };
    },
    components: {
    DiagramComponent,
    TitleComponent,
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};

</script> 