<!-- DON'T TOUCH -->
<template>
    <div>
      <button class="return-button" @click="goBack">Retour</button>
      <TitleComponent :Title="Title" />
      <DiagramComponent
        :format="Format"
        :nodes="nodes"
        :linkData="linkData"
        :componentList="componentList"
      />
    </div>
  </template>
      
  
  
    <script>
  // DON'T TOUCH
  import DiagramComponent from "@/components/Diagram.vue";
  import TitleComponent from "@/components/TitlePage.vue";
  
  export default {
    name: "PageProcessus", // Nom à changer
    data() {
      return {
        //Texte à remplir
        Title: {
          mainTitle:
            "Cadre Légal et Réglementaire",
          subTitle: "Cadeaux et Concours",
          subSubTitle: "Processus: Concours",
          supervisor: "Présidence, Secrétaire Général",
          derniere_modif: "27/02/2024 par Bastien HUET",
          image :require("@/assets/clr.png")

        },
  
        // Exemple des différents type de nœuds
        nodes: [
          {
            key: "0",
            text: 'Concours annoncé',
            type: "Start", // Met en vert et de forme elliptique (genre ovale)
          },
          {
            key: "1",
            text: "Vérification de l'intérêt du concours",
            component: "0",
            supervisor: "President,Secrétaire Général",
          },
          {
            key: "2",
            text: "Vérification du respect des règles des concours en JE",
            component: "1",
            supervisor: "President,Secrétaire Général",
          },
          {
            key: "3",
            text: "Participation au concours",
            supervisor: "President,Secrétaire Général",
          },
          {
            key: "4",
            text: "Signature par l'étudiant de l'attestation de remise des prix ",
            supervisor: "President,Secrétaire Général",
          },
       
          {
            key: "5",
            text: "Fin du concours",
            type: "End",

          },
        ],
  
        linkData: [
          {
            from: "2",
            to: "1",
            Emplacement: 1,
            Espace: "grand",
          },
          {
            from: "4",
            to: "3",
            Emplacement: 1,
            Espace: "grand",
          },
          {
            from: "6",
            to: "5",
            Emplacement: 1,
            Espace: "grand",
          },
       
        ],
        componentList: [
          // détails vérif intérêt concours
          {
        number: "0",
        Name: "Procédure: Vérification de l'intérêt du concours",
        infosList: {
          items: [
            "Concours à plu-value pédagogique",
            "Concours dans l'intérêt de la Junior et de son développement",
            "Concours de prospection",
            "Concours de prototypage",
            "Concours d'éloquance",
            "Concours de code en tout genre",
            "Hackatlon",
            "...",
          ],
        },
        },
        //détails pendant ag
        {
          number: "1",
          Name: "Procédure: Vérification du respect des règles des concours en JE",
          infosList:{
            items:[
              "Prix à vocation pédagogique (tablette, livre, équipements,...)",
              "Le prix ne peut pas être une somme d'argent",
              "Prix non-remis à un membre du CA",
              "Le montant du prix ne dépasse pas 2% du CA plafonnée à 2000 € et 500€/étudiant"
            ],
          },
        },
    
      ],
      };
    },
    components: {
      DiagramComponent,
      TitleComponent,
    },
    methods: {
      goBack() {
        this.$router.go(-1);
      },
    },
  };
  </script> 