


<template>
    <ListCocheComponent :infos="infos" :Title="Title" @Click-close="goBack" />
  </template>
     
     <script>
  import ListCocheComponent from "@/components/ListeValidation.vue";
  
  export default {
    name: "list_example",
    components: {
      ListCocheComponent,
    },
    data() {
      return {
        Title: {
          mainTitle: "Cadre Légal et Réglementaire",
          subTitle: "Gestion Associative",
          subSubTitle: "Procédure: Gestion des Status",
          supervisor: "Secrétaire Général",
          derniere_modif: "27/02/24 par Bastien HUET",
          image :require("@/assets/clr.png")
        },
        infos: {
          items: [
            " Nom officiel de la Junior ",
            " Objet social de la Junior ",
            " Adresse exacte du siège social ",
            " Durée pour laquelle l'association est créée (pour les Juniors, durée: indéterminée)",
            " Qualité des membres et composition de la Junior",
            " Conditions d'adhésion et d'exclusion",
            " Composition et fonctionnement du bureau",
            " Composition et fonctionnement du Conseil d'Administration",
            " Déroulement d'une Assemblée Générale Ordinaire/Extraordinaire",
            " Modalités de dissolution, liquidation, dévolution",
            " Chaque modification doit être votée en CA et en AG et doivent être déclarée sous 3 mois à la préfecture",
            " Le Président et le Secrétaire Général doivent signer"
          ],
          texte_if_all: "Bravo à toi tu es un génie",
          link_if_all:
            "https://docs.google.com/presentation/d/1Nuk0xAWRt1VpCjENFtEoXKJUuYKVm6btvSVDb-fGhW4/edit?usp=sharing",
          texte_link: "Cliquez ici",
      
        },
      };
    },
    methods: {
      goBack() {
        this.$router.go(-1);
      },
    },
  };
  </script>