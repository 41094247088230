<!-- DON'T TOUCH -->
<template>
  <div>
    <button class="return-button" @click="goBack">Retour</button>
    <TitleComponent :Title="Title" />
    <DiagramComponent :format="Format" :nodes="nodes" :linkData="linkData" :componentList="componentList" />
  </div>
</template>



<script>
// DON'T TOUCH
import DiagramComponent from "@/components/Diagram.vue";
import TitleComponent from "@/components/TitlePage.vue";

export default {
  name: "RelectureTrésorerie", // Nom à changer
  data() {
    return {
      //Texte à remplir
      Title: {
        mainTitle:
          "Trésorerie",
        subTitle: "Transversal",
        subSubTitle: "Processus: Relecture en Trésorerie",
        supervisor: "Pole Trésorerie",
        derniere_modif: "28/03/2024",
      },

      // Exemple des différents type de nœuds
      nodes: [
        {
          key: "0",
          text: 'Documents à émettre',
          type: "Start", // Met en vert et de forme elliptique (genre ovale)
          
        },
        {
          key: "1",
          text: "Déposer dans le tableau lié sur Trello",
          supervisor: "Tresorier ou Vice Tresorier"
        },
        {
          key: "2",
          text: "Prévenir sur le groupe Telegram du besoin de relecture",
          supervisor: "Tresorier ou Vice Tresorier"
        },
        {
          key: "3",
          text: "Rechercher les différents sources d'informations nécessaires à l'émission du document (CE/RM/Avenant/PVRF/PVRI sur l'ERP, infos des personnes auprès de la personne ou chargé d'étude, Justificatifs)",
          supervisor: "Tresorier ou Vice Tresorier"
        },

        {
          key: "7",
          text: "Vérifier que l'ensemble des informations sont similaires sur le document émis et les différentes sources ",
          supervisor: "Tresorier ou Vice Tresorier",
          addDecY : 100

        },
        {
          key: "7",
          text: "Vérifier les calculs automatiques et les lignes à remplir (Montant à Payer en toutes lettres) ",
          supervisor: "Tresorier ou Vice Tresorier",
          

        },
        {
          key: "7",
          text: "Mettre dans la colonne validé sur Trello ",
          supervisor: "Tresorier ou Vice Tresorier",
        },

        {
          key: "8",
          text: "Envoyer un message à la personne qui a fait les erreurs avec les différentes choses à modifier et attendre une nouvelle version ",
          supervisor: "Tresorier ou Vice Tresorier",
          direction: "left",
          indPrec : 5

        },

        {
          key: "2",
          text: "Document Validé vous pouvez retourner au processus en cours",
          type: "End",
          indPrec: 6

        },

        {
          key: "0",
          text: 'Déclaratifs à émettre',
          type: "Start", // Met en vert et de forme elliptique (genre ovale)
          indPrec: 0,
          direction: "right",
          noLinkauto: true
        },
        {
          key: "7",
          text: "Exporter l'aperçu ou le tableau de suivi de la TVA",
          supervisor: "Tresorier ou Vice Tresorier"

        },

        {
          key: "3",
          text: "Rechercher les différents sources d'informations nécessaires à l'émission du déclaratifs (Facture client, FF, BV, infos sur les intervenant)",
          supervisor: "Tresorier ou Vice Tresorier"
        },
        {
          key: "3",
          text: "Sortir le GL lié (431 pour BRC/TR et 445 pour la TVA)",
          supervisor: "Comptable"
        },
        {
          key: "7",
          text: "Vérifier que l'ensemble des informations sont similaires sur le déclaratif préparé et les différentes sources ",
          supervisor: "Tresorier ou Vice Tresorier"

        },
        {
          key: "7",
          text: "Prévenir l'émetteur que c'est validé et archiver l'aperçu et les GL sur la Dropbox ",
          supervisor: "Tresorier ou Vice Tresorier",


        },

        

        {
          key: "2",
          text: "Déclaratif Validé vous pouvez retourner au processus en cours",
          type: "End",

        },
        {
          key: "8",
          text: "Envoyer un message à la personne qui a fait les erreurs avec les différentes choses à modifier et attendre une nouvelle version ",
          supervisor: "Tresorier ou Vice Tresorier",
          direction: "right",
          indPrec : 13

        },

      ],

      linkData: [
        {
          from : 7,
          to : 4
        }
      ],

      componentList: [
      ],
    };
  },
  components: {
    DiagramComponent,
    TitleComponent,
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};

</script>