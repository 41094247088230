<template>
  <TexteComponent
    :markdownContent="Texte"
    :list_image = "images"
    :list_markdown = "textes"
    :Title="Title"
    @Click-close="goBack"
  />
</template>

<script>
import TexteComponent from "@/components/TexteMarkdown.vue";
export default {
  name: "ajoutBeneficiaire",
  components: {
    TexteComponent,
  },
  data() {
    return {
      //Le titre avec les différentes lignes
      Title: {
        mainTitle:
          "Achats",
        subSubTitle: "Procédure: Ajouter un Bénéficiaire pour virement",
        supervisor: "Trésorier",
        derniere_modif: "25/03/2024 par Mathieu CHAILLON",
      },
      images : [require("@/assets/tutoRIB1.png"),
      require("@/assets/tutoRIB2.png"),      
      require("@/assets/tutoRIB3.png"),
      require("@/assets/tutoRIB4.png"),
        ],
        textes: [
          " Sélectionner le compte courant \n",
          " Cliquer sur ajouter un Bénéficiaire ",
          " Remplir les infos",
          " Activer via la clé digitale [cliquez ici pour savoir comment la paramètrer](https://mabanquepro.bnpparibas/fr/notre-offre-pro/comptes-cartes-et-services/gerer-ses-comptes-en-ligne/cle-digitale)",
        ],
      //Le Texte en Markdown (Genre comme dans Discord ou Telegram il existe plein de tranformateur texte Markdown sur internet)
      Texte: `**Voilà comment ajouter un nouveau bénéficiare pour les virements avec notre compte BNP**
      
      Premièrement aller dans virements`,
    };
  },

  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
