<template>
  <div >
    <button class="return-button" @click="handleReturnClick">Fermer</button>
    <TitleComponent :Title="Title" />
      <div v-if="!showMarkdownOnly" class = "texte-container">

        <div v-for="(item, index) in this.merged" :key="index">
          <img v-if="item.type === 'image'" :src="item.url" id="image_txt" />
          <div v-else v-html="renderMarkdown(item.markdown)"></div>
        </div>
      </div>
      <div v-if="showMarkdownOnly" class = "texte-container" v-html="renderMarkdown(markdownContent)"></div>
      
    
    
  </div>
</template>

<script>
// Importation de la bibliothèque markdown-it pour convertir le Markdown en HTML
import MarkdownIt from 'markdown-it';

import TitleComponent from "@/components/TitlePage.vue";
export default {
  props: {
    // Propriété pour recevoir le texte en Markdown
    markdownContent: {
      type: String,
    },
    list_markdown : {
      type: Array,
    },
    list_image : {
      type: Array,
    },
    Title : Object,
  },
  data() {
    return {
      showMerge : true,
      merged : [],
      showMarkdownOnly : false,
    };
  },
  created (){
    this.actualisation()
  },
  watch: {
    list_markdown: 'actualisation',
    list_image: 'actualisation',
    markdownContent : 'actualisation'

  },

  
  methods: {
    handleReturnClick() {
      // Émettre un événement personnalisé
      this.$emit('Click-close');
    },
    actualisation(){
      if (this.list_markdown || this.list_image)
      {this.mergedList();
      this.showMarkdownOnly = false;}
    else if (this.markdownContent){
      this.showMarkdownOnly = true;
    }
    },
    renderMarkdown(text) {
      console.log(text)
      // Création d'une instance de markdown-it
      const md = new MarkdownIt();
      // Convertir le texte Markdown en HTML
      return md.render(text);
    },
    mergedList() {
      this.merged = []
      let maxLength = 0 
      if (this.list_markdown && this.list_image ){
        maxLength = Math.max(this.list_markdown.length, this.list_image.length)
        this.showMerge = true
      }
      else if (this.list_markdown)
      {
        maxLength = this.list_markdown.length
        this.showMerge = true
      }
      else if (this.list_image)
      {
        maxLength = this.list_image.length
        this.showMerge = true
      }
      else if(this.markdownContent ) {
        this.showMerge = true
      }
      else {
        this.showMerge = false
      }
      if (this.markdownContent) {
          this.merged.push({ type: 'text', markdown: this.markdownContent });
        }

      
      for (let i = 0; i < maxLength; i++) {
        if (this.list_image && this.list_image[i]) {
          console.log("img")
          this.merged.push({ type: 'image', url: this.list_image[i] });
        }
        if (this.list_markdown && this.list_markdown[i]) {
          this.merged.push({ type: 'text', markdown: this.list_markdown[i] });
        }
      }
    },
  },
  components: {
    TitleComponent,
  },
};
</script>

<style>
.texte-container {
  overflow: scroll;
  max-height: 65vh;
}
#image_txt {
    max-height: 30vh;
    margin-left: 10px;
    margin-bottom: 10px;
}
</style>