<template>
    <!-- on affiche les catégories -->
    <categories-component :items="items" />
  </template>
  
  <script>
  import CategoriesComponent from '@/components/Categories.vue';
  export default {
    name: "chartes_conventions",
    data() {
      return { items : [
      {
          title: 'Factures Clients',
          link: '/facture',
          subtitle: 'FA,FI,FS,FAvoir',
          responsables : "Pole Treso"
      },
      {
          title: 'Refacturations',
          link: '/refact',
          responsables : "Pole Treso"
      },
      
      {
          title: 'Cotisations',
          link: '/cotis',
          responsables : "Pole Treso et Sec Gen"
      },
      {
          title: 'Subventions',
          link: '/subvention',
          responsables : "Pole Treso"
      },
      {
          title: 'Gestion Créances',
          link: '/creance',
          responsables : "Pole Treso Pole ActiCo et President"
      },
      ],
      };
    },
    components: {
      CategoriesComponent,
    },
  };
  </script>
  