<template>
    <!-- on affiche les catégories -->
    <categories-component :items="items" />
  </template>
  
  <script>
  import CategoriesComponent from "@/components/Categories.vue";
  export default {
    name: "autresView",
    data() {
      return {
        // Exemple de données pour les nœuds
        items: [
          {
            title: "Template Wikix",
            subtitle: "Diagram, Text,Liste à cocher, Menu",
            link: "/menuExample",
            responsables: "Secrétaire général, Présidence",
          },
        ],
      };
    },
    components: {
      CategoriesComponent,
    },
  };
  </script>
  