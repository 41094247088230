<template>
    <div>
      <button class="return-button" @click="goBack">Retour</button>
      <TitleComponent :Title="Title" />
      <DiagramComponent
        :format="Format"
        :nodes="nodes"
        :linkData="linkData"
        :componentList="componentList"
      />
    </div>
  </template>
      
  
  
    <script>
  // DON'T TOUCH
  import DiagramComponent from "@/components/Diagram.vue";
  import TitleComponent from "@/components/TitlePage.vue";
  
  export default {
    name: "emission_pvriView", // Nom à changer
    data() {
      return {
        //Texte à remplir
        Title: {
          mainTitle:
            "Activité Commerciale",
          subTitle: "Pendant étude",
          subSubTitle: "Processus : Commande de pièces",
          supervisor: "Pôle Activité Commerciale, Trésorerie, Présidence",
          derniere_modif: "23/03/2024 par Enzo CHAMPAGNE",
        },
  
        // Exemple des différents type de nœuds
        nodes: [
          {
            key: "1",
            text: "Faire remplir le document Excel par l'intervenant (imposer 1 semaine)",
            type: "Start",
            supervisor: "Chargé d'affaires",
          },
          {
            key: "2",
            text: "Gérer les problèmes de TVA",
            supervisor: "Chargé d'affaires",
          },
          {
            key: "3",
            text: "Amazon : remplir le panier sur le compte d'AEI et demander au trésorier de faire la commande. \n Autres sites : demander au trésorier de faire la commande et lui envoyer le document Excel si besoin",
            supervisor: "Chargé d'affaires",
          },
          {
            key: "4",
            text: "Passer la commande : détails à rajouter ?",
            supervisor: "Trésorerier",
          },
          {
            key: "5",
            text: "Suivre régulièrement l'avancée de la commande jusqu'à ce que tout le matériel soit arrivé",
            supervisor: "Trésorier, chargé d'affaires"
          },
          {
            key: "6",
            text: "Noter et prendre en photo ce qui est reçu au fur et à mesure",
            supervisor: "Chargé d'affaires"
          }
        ],
  
        linkData: [
        ],
        componentList: [
        ],
      };
    },
    components: {
      DiagramComponent,
      TitleComponent,
    },
    methods: {
      goBack() {
        this.$router.go(-1);
      },
    },
  };
  </script> 