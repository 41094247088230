<template>
  <div class="home-container">
      <div class="home">
          <router-link class="home-item" to="/clr">
              <div class="div-txt">
                  <h1>Cadre Légal & Réglementaire</h1>
              </div>
          </router-link>
          <router-link class="home-item" to="/actiCo">
              <div class="div-txt">
                  <h1>Activité Commerciale</h1>
              </div>
          </router-link>
          <router-link class="home-item" to="/tresorerie">
              <div class="div-txt">
                  <h1>Trésorerie</h1>
              </div>
          </router-link>
          <router-link class="home-item" to="/com">
              <div class="div-txt">
                  <h1>Communication</h1>
              </div>
          </router-link>
          <router-link class="home-item" to="/comptabilite">
              <div class="div-txt">
                  <h1>Comptabilite</h1>
              </div>
          </router-link>
          <router-link to="/social_et_fiscal" class="home-item">
              <div class="div-txt">
                  <h1>DSI</h1>
              </div>
              
          </router-link>
          <router-link class="home-item" to="/qualite">
              <div class="div-txt">
                  <h1>Stratégie et Pilotage</h1>
              </div>
          </router-link>
          <router-link class="home-item" to="/rfp">
              <div class="div-txt">
                  <h1>RFP</h1>
              </div>
          </router-link>
      
          <router-link class="home-item" to="/autres">
              <div class="div-txt">
                  <h1>Autres</h1>
              </div>
          </router-link>
      </div>
  </div>
</template>

<!-- <script setup>
import { useNavStore } from '@/stores/nav';

useNavStore().setNavItems('Social et Fiscal', 'Achats et Ventes', 'Autres');
</script> -->

<style>
.logo-barre {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.home-container {
  margin: 0 auto;
}

.home {
  display: flex;
  gap: 1em;
  flex-wrap: wrap;
}

.home-item {
  background-color: #3369e7;
  border-radius: 10px;
  transition: all 0.3s;
  text-align: center;
  color: white;
  text-decoration: none;
  font-family: 'Inter-ExtraBold';
  height: 8rem;
  flex: 1;
  min-width: 25em;
}

.div-txt {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1em;
  box-sizing: border-box;
}

h1 {
  font-size: 2.5rem;
  color: white;
  width: 100%;
}

.home-item:hover {
  background-color: #5b87eb;
}

</style>
