import comptabilite from '../views/tresorerie/comptabilite/comptabilite.vue';

// import expert_comptable from '../views/tresorerie/comptabilite/expert_comptable/expert_comptable.vue';

// import tenir_sa_comptabilite from '../views/tresorerie/comptabilite/tenir_sa_comptabilite/tenir_sa_comptabilite.vue';

// import controler_sa_comptabilite from '../views/tresorerie/comptabilite/controler_sa_comptabilite/controler_sa_comptabilite.vue';

import emettre_BV from '../views/tresorerie/social/BV.vue';
import social from '../views/tresorerie/social/social.vue';
import brc from '../views/tresorerie/social/brc.vue';
import dads from '../views/tresorerie/social/dads.vue';
import tr from '../views/tresorerie/social/TR.vue';


import tva from '../views/tresorerie/fiscal/TVA.vue';
import fiscal from '../views/tresorerie/fiscal/fiscal.vue';
import is from '../views/tresorerie/fiscal/IS.vue';
import das2 from '../views/tresorerie/fiscal/das2.vue';
import cfe from '../views/tresorerie/fiscal/cfe.vue';

import cloture_comptable from '../views/tresorerie/comptabilite/cloture_comptable/cloture_comptable.vue';

import transversal from '../views/tresorerie/transversal/transversal.vue';
import relectureTreso from '../views/tresorerie/transversal/RelectureTreso.vue';
import actualisation from '../views/tresorerie/transversal/Actualisation.vue';
import creaBudget from '../views/tresorerie/transversal/CreaBudget.vue';

import ventes from '../views/tresorerie/ventes/ventes.vue';
import facture from '../views/tresorerie/ventes/fact.vue';
import refact from '../views/tresorerie/ventes/refact.vue';
import cotis from '../views/tresorerie/ventes/Cotisations.vue';
import subvention from '../views/tresorerie/ventes/subventions.vue';
import creance from '../views/tresorerie/ventes/Creance.vue';

import achats from '../views/tresorerie/achats/achats.vue';

import ajoutBeneficiaire from '../views/tresorerie/achats/ajoutBeneficiaire.vue';
import virement from '../views/tresorerie/achats/virement.vue';
import processus_achats from '../views/tresorerie/achats/processusAchat.vue';
import nfg from '../views/tresorerie/achats/nfg.vue';



const routes = [

    {path: '/comptabilite', name: 'comptabilite', component : comptabilite},
    {path: '/transversal', name: 'transversal', component : transversal},
    {path: '/relectureTreso', name: 'relectureTreso', component : relectureTreso},
    {path: '/actualisation', name: 'actualisation', component : actualisation},
    {path: '/creaBudget', name: 'creaBudget', component : creaBudget},


    {path: '/ventes', name: 'ventes', component : ventes},
    {path: '/facture', name: 'facture', component : facture},
    {path: '/refact', name: 'refact', component : refact},
    {path: '/cotis', name: 'cotis', component : cotis},
    {path: '/subvention', name: 'subvention', component : subvention},
    {path: '/creance', name: 'creance', component : creance},

    {path: '/achats', name: 'achats', component : achats},
    {path: '/ajoutBeneficiaire', name: 'ajoutBeneficiaire', component : ajoutBeneficiaire},
    {path: '/virement', name: 'virement', component : virement},
    {path: '/processus_achats', name: 'processus_achats', component : processus_achats},
    {path: '/nfg', name: 'nfg', component : nfg},


    {path: '/social', name: 'social', component : social},
    {path: '/BV', name: 'BV', component : emettre_BV},
    { path: '/brc', name: 'brc', component: brc},
    { path: '/dads', name:'dads', component: dads},
    { path: '/tr', name:'tr', component: tr},


    {path: '/fiscal', name: 'fiscal', component : fiscal},
    {path: '/tva', name: 'tva', component : tva},
    { path: '/is', name: 'is', component: is},
    { path: '/das2', name:'das2', component: das2},
    { path: '/cfe', name:'cfe', component: cfe},

    // { path: '/expert_comptable', name: 'expert_comptable', component: expert_comptable },
    // {
    //     path: '/tenir_sa_comptabilite',
    //     name: 'tenir_sa_comptabilite',
    //     component: tenir_sa_comptabilite
    // },
    // {
    //     path: '/controler_sa_comptabilite',
    //     name: 'controler_sa_comptabilite',
    //     component: controler_sa_comptabilite
    // },

    {
          path: '/cloture_comptable',
          name: 'cloture_comptable',
          component: cloture_comptable
    },
];

export default routes;