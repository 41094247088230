<!-- DON'T TOUCH -->
<template>
    <div>
      <button class="return-button" @click="goBack">Retour</button>
      <TitleComponent :Title="Title" />
      <DiagramComponent
        :format="Format"
        :nodes="nodes"
        :linkData="linkData"
        :componentList="componentList"
      />
    </div>
  </template>
      
  
  
    <script>
  // DON'T TOUCH
  import DiagramComponent from "@/components/Diagram.vue";
  import TitleComponent from "@/components/TitlePage.vue";
  
  export default {
    name: "PageProcessus", // Nom à changer
    data() {
      return {
        //Texte à remplir
        Title: {
          mainTitle:
            "Activité Commerciale",
          subTitle: "Sous-traitance",
          supervisor: "Pôle Activité Commerciale, Présidence, Trésorier",
          derniere_modif: "09/03/2024 par Zhaniya NURKHANOVA",

        },
  
        // Exemple des différents type de nœuds
        nodes: [
          {
            key: "0",
            text: 'Etude comportant une partie hors de nos domaines de compétences',
            type: "Start",
          },
          {
            key: "1",
            text: "Choisir l'entité sous-traitée ayant les compétances requises(recherche: d'abord entre JEB, ouis JE françcaise, puis Hors JE)",
          },
          {
            key: "2",
            text: "Prévenir le Client qu'une partie de l'étude sera sous-traitée",
          },
          {
            key: "3",
            text: "Rédaction de la CE, la phase sous-traitée à facturer en frais puis en JEH(pas de bénéfice pour cette phase)",
            direction: "right",
            indPrec: 2,
            noLinkauto: true,
          },
       
          {
            key: "4",
            text: "Suite de l'étude habituelle",
            indPrec: 3,
            type: "End",

          },
          {
            key: "5",
            text: "On devient le Client de la JE sous-traitante. Elle rédige une CE et suis le processus classique d'une étude",
            direction: "left",
            noLinkauto: true,

          },
          {
            key: "6",
            text: "Vérifier la partie Litige de la CE(voir détail)",

          },
          {
            key: "7",
            text: "Fin de la sous-traitance, paiement de la JE sous-traitée même si le Clien n'a pas encore payé",
            type: "End",
          },
        ],
  
        linkData: [
           {
            from: "2",
            to: "3",
            Emplacement: 2,
            label: "Si on est la Junior sous-traitante",
            decalageSup : 40,
            Espace: "petit"
          },
           {
            from: "2",
            to: "5",
            Emplacement: 2,
            label: "Si on est la JE sous-traitée",
            bySide: true,
            decalageSup : 40,
            Espace: "grand"
          },
           {
            from: "0",
            to: "1",
            Espace: "grand",
          },
          {
            from: "1",
            to: "2",
            Espace: "grand",
          },
          {
            from: "5",
            to: "6",
            Espace: "grand",
          },
          {
            from: "6",
            to: "7",
            Espace: "grand",
          },
          
        ],
        componentList: [
          // Vérification des livrables
          {
        number: "0",
        Name: "Procédure: Vérification des livrables",
        infosList: {
          items: [
            "Reprendre chaque point du CDC et le tester.",
            "Si livrable non conforme, correction du livrable par l'intervenant.",
          ],
        },
        },
        //Mini-audit
        {
          number: "1",
          Name: "Procédure: Mini-audit",
          infosList:{
            items:[
              "Pagination correcte.",
              "Les dates sont exactes.",
              "Le bon nombre de JEH a bien été attribué.",
              "On reparcourt le processus d'étude et on vérifie son exactitude.",
            ],
          },
        },
        //Archivage de l'étude
        {
          number:"2",
          Name: "Procédure: Archivage de l'étude",
          infosList:{ 
            items:[ 
              "Convention d'étude et Avenants (le cas échéant).",
              "Accord de confidencialité (le cas échéant).",
              "Procès verbaux de recette.",
              "Factures.",
              "Récapitulatif de Mission et Avenants (le cas échéant). ",
              "Rapport pédagogique (si l'étude a débuté avant le changement).",
            ],
          },
        },
      ],
      };
    },
    components: {
      DiagramComponent,
      TitleComponent,
    },
    methods: {
      goBack() {
        this.$router.go(-1);
      },
    },
  };
  </script> 