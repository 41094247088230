<!-- DON'T TOUCH -->
<template>
    <div>
      <button class="return-button" @click="goBack">Retour</button>
      <TitleComponent :Title="Title" />
      <DiagramComponent
        :format="Format"
        :nodes="nodes"
        :linkData="linkData"
        :componentList="componentList"
      />
    </div>
  </template>
      
  
  
    <script>
  // DON'T TOUCH
  import DiagramComponent from "@/components/Diagram.vue";
  import TitleComponent from "@/components/TitlePage.vue";
  
  export default {
    name: "verification_solvabiliteView", // Nom à changer
    data() {
      return {
        //Texte à remplir
        Title: {
          mainTitle:
            "Activité Commerciale",
          subTitle: "Avant Etude",
          subSubTitle: "Procédure : Vérification de la solvabilite de l'étude",
          supervisor: "Pôle Activité Commerciale",
          derniere_modif: "25/03/2024 par Enzo CHAMPAGNE",
        },
  
        // Exemple des différents type de nœuds
        nodes: [
          {
            key: "0",
            text: 'Demande du budget au Client',
            type: "Start",
          },
          {
            key: "1",
            text: "Récupération des comptes de la société sur infogreffe.fr",
          },
          {
            key: "2",
            text: "Demander la provenance des fonds",
            indPrec: 1,
            direction: "right",
            noLinkauto: true,
          },
          {
            key: "3",
            text: "Vérifications : Existance de la société, Liquidation, Redressement judiciaire",
            indPrec: 2,
            direction: "right",
            noLinkauto: true,
          },
          {
            key: "4",
            text: "Validation",
            type: "End",
            indPrec: 1,
          },
        ],
  
        linkData: [
          {
            from: "0",
            to: "2",
            Emplacement: 2,
            label: "Particulier",
            Espace: "grand",
          },
          {
            from: "0",
            to: "3",
            Emplacement: 2,
            label: "Société immatriculée",
            Espace: "grand",
          },
          {
            from: "3",
            to: "4",
            Emplacement: 2,
            Espace: "grand",
          },
          {
            from: "2",
            to: "4",
            Emplacement: 2,
            Espace: "grand",
          },

        ],
        componentList: [
        ],
      };
    },
    components: {
      DiagramComponent,
      TitleComponent,
    },
    methods: {
      goBack() {
        this.$router.go(-1);
      },
    },
  };
  </script> 