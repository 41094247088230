
import rfp from '../views/rfp/rfp.vue';

// Pour rajouter la route pour une des grandes catégories
// import nom de la vue(son emplacement dans le dossier) from '../views/catégorie/component_souhaité.vue';


const routes = [

    // Pour rajouter la route pour une des grandes catégories :
    
    // { path: '/nom_chemin', name: 'Nom de la route', component: nom de la vue }
    { path: '/rfp', name: 'rfp', component: rfp }

];

export default routes;
