<template>
  <Toast />

  <header id="header">
    <div class="logo-barre">
      <RouterLink to="/">
        <logo> <img src=".\assets\logo.png" id="logo" width="10%" /></logo>
      </RouterLink>
    </div>
  </header>
  <div class="content">
    <NavBarComponent />
    <div class="app-container">
      <router-view></router-view>
    </div>
  </div>
</template>
<script lang="ts">
import NavBarComponent from "./components/NavBar.vue";

export default {
  components: {
    NavBarComponent,
  },
};
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

#app {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.content {
  display: flex;
  flex: 1;
  height: 92vh;
}

.app-container {
  display: flex;
  overflow: hidden; 
  flex-direction: column;
  flex: 2;
  height: 100%;
  box-sizing: border-box;
  padding: 1em;
}

.main-content {
  margin-left: 5px;
}

/* Style du header */
#header {
  display: flex;
  background-color: #030712;
  height: 8vh;
  position: sticky;
  top: 0;
  z-index: 1;
}

/* Style de la barre de logo */
.logo-barre {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 0 20px;
}


/* Styles pour le bouton de retour */
.return-button {
  display: flex;
  position: fixed;
  background-color: gray; /* Couleur de fond */
  color: #fff; /* Couleur du texte */
  padding: 10px 10px; /* Espace intérieur du bouton */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 999; /* Assure que le bouton reste au-dessus du contenu */ 
}

.return-button:hover {
  background-color: #0056b3; /* Couleur de fond au survol */
}
</style>