<!-- DON'T TOUCH -->
<template>
    <div>
      <button class="return-button" @click="goBack">Retour</button>
      <TitleComponent :Title="Title" />
      <DiagramComponent
        :format="Format"
        :nodes="nodes"
        :linkData="linkData"
        :componentList="componentList"
      />
    </div>
  </template>
      
  
  
    <script>
  // DON'T TOUCH
  import DiagramComponent from "@/components/Diagram.vue";
  import TitleComponent from "@/components/TitlePage.vue";
  
  export default {
    name: "passation_etudeView", // Nom à changer
    data() {
      return {
        //Texte à remplir
        Title: {
          mainTitle:
            "Activité Commerciale",
          subTitle: "Pendant étude",
          subSubTitle: "Processus : Passation d'étude",
          supervisor: "Pôle Activité Commerciale, pôle Qualité",
          derniere_modif: "23/03/2024 par Enzo CHAMPAGNE",
        },
  
        // Exemple des différents type de nœuds
        nodes: [
          {
            key: "0",
            text: 'Etude précédente à transférer',
            type: "Start",
          },
          {
            key: "1",
            text: "Prévenir le plus tôt possible le Chargé Commercial. \n Ce sera lui qui se chargera de l'étude pendant l'absence",
            noLinkauto: true,
          },
          {
            key: "2",
            text: "Présenter le Client. \n Présenter l'étude. \n Présenter les réalisateurs. \n Présenter l'état d'avancement de l'étude. \n Présenter les difficultés de réalisation.",
            noLinkauto: true,
          },
          {
            key: "3",
            text: "L'un des deux Co-responsables ActiCo assurera temporairement la gestion de l'étude",
            indPrec: "1",
            direction: "right",
            noLinkauto: true,
          },
          { key: "4", text: "Choisir un nouveau Chef de Projet pour le suivi de l'étude" },
          {
            key: "5",
            text: "Organiser une réunion de passation d'étude entre le nouveau et l'ancien chef de projet",
          },
          { key: "6", text: "Réaliser un entretien avec les réalisateurs" },
          {
            key: "7",
            text: "Prévenir le Client et les réalisateurs du changement d'interlocuteur",
          },
          {
            key: "8",
            text: "Réaliser si possible un entretien avec le Client",
          },
          { 
            key: "9", 
            text: "L'un des deux Co-responsables ActiCo assurera temporairement la gestion de l'étude le temps de reprendre contact avec le chef de projet",
            indPrec: "1",
            direction: "left",
          },
  
  
          { key: "10", text: "Sélectionner un nouveau chef de projet. \n Le responsable fera un briefing au nouveau chargé", },
          { key: "11", text: "Informer le Client et les réalisateurs du changement d'interlocuteur", },
        ],
  
        linkData: [
          {
            from: "5",
            to: "2",
            Emplacement: 1,
            Espace: "grand",
          },
          {
            from: "10",
            to: "2",
            Emplacement: 1,
            Espace: "grand",
          },
          {
            from: "0",
            to: "3",
            label: "Cas d'une absence prévue et de longue durée",
          },
          {
            from: "0",
            to: "1",
            label: "Si l'absence est prévue et temporaire",
          },
          { from: "0", to: "9", label: "Si l'absence est \n non prévue",},
          
        ],
        componentList: [
        ],
      };
    },
    components: {
      DiagramComponent,
      TitleComponent,
    },
    methods: {
      goBack() {
        this.$router.go(-1);
      },
    },
  };
  </script> 