<!-- DON'T TOUCH -->
<template>
    <div>
      <button class="return-button" @click="goBack">Retour</button>
      <TitleComponent :Title="Title" />
      <DiagramComponent
        :format="Format"
        :nodes="nodes"
        :linkData="linkData"
        :componentList="componentList"
      />
    </div>
  </template>
      
  
  
    <script>
  // DON'T TOUCH
  import DiagramComponent from "@/components/Diagram.vue";
  import TitleComponent from "@/components/TitlePage.vue";
  
  export default {
    name: "dadsProcessus", // Nom à changer
    data() {
      return {
        //Texte à remplir
        Title: {
          mainTitle:
            "Trésorerie",
          subTitle: "Ventes",
          subSubTitle: "Processus: Subventions",
          supervisor: "Trésorier",
          derniere_modif: "25/03/2024 par Mathieu CHAILLON",
        },
  
        // Exemple des différents type de nœuds
        nodes: [
          {
            key: "0",
            text: 'Subvention reçue',
            type: "Start", // Met en vert et de forme elliptique (genre ovale)
          },
          {
            key: "1",
            text: "Subvention non commerciale ",
            addDecX : -150
          },
          {
            key: "1",
            text: "Archiver le justificatif de payement et de partenariat ",
            supervisor: "Trésorier ou Vice Trésorier",
          },
          {
            key: "1",
            text: "Mettre en compta",
            supervisor: "Comptable",
            component : 2
          },
          {
            key: "1",
            text: "Subvention Commerciale",
            indPrec : 1,
            direction : "right",
            noLinkauto : true
          },
          {
            key: "3",
            text: "Remplir le DT de Facture de Partenariat",
            supervisor: "Trésorier ou Vice Trésorier",
          },
          { key: "4", 
            text: "Relecture",
            link : "relectureTreso",
            supervisor: "Trésorier ou Vice Trésorier",
        },
          {
            key: "5",
            text: "Renseigner le TS et mettre la Facture de Partenariat sur la DropBox ",
            supervisor: "Trésorier ou Vice Trésorier",
          },
          { key: "6", 
          text: "Mettre en compta" ,
          component : 1,
          supervisor: "Comptable",
          },
          {
            key: "7",
            text: "Subvention traitée",
            type: "End",
            addDecX : -150
          },
        ],
  
        linkData: [
       {
          from : 0 ,
          to : 4
       },
       {
          from : 3 ,
          to : 9
       },
        ],
        
        componentList: [
          {

          },
          {
          number: "1",
          Name: "Rentrée en compta d'une Subvention commerciale",
          images : [require("@/assets/comptaSubCom.png"),
        require("@/assets/comptaEncSub.png"),],
          
        
        },
        {
          number: "1",
          Name: "Rentrée en compta d'une Subvention non commerciale",
          images : [require("@/assets/comptaSubNCom.png"),
        require("@/assets/comptaEncSub.png"),],
          
        
        },
        
      ],
      };
    },
    components: {
    DiagramComponent,
    TitleComponent,
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};

</script> 