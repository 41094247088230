


<template>
    <ListCocheComponent :infos="infos" :Title="Title" @Click-close="goBack" />
  </template>
     
     <script>
  import ListCocheComponent from "@/components/ListeValidation.vue";
  
  export default {
    name: "list_example",
    components: {
      ListCocheComponent,
    },
    data() {
      return {
        Title: {
          mainTitle: "Cadre Légal et Réglementaire",
          subTitle: "Gestion Associative",
          subSubTitle: "Procédure: Archivage (Roue de l'archivage)",
          supervisor: "Secrétaire Général",
          derniere_modif: "27/02/24 par Bastien HUET",
          image :require("@/assets/clr.png")
        },
        infos: {
          items: [
            " Les BV peuvent se conserver sous format papier (2 exemplaires) ou format électronique (1 exemplaire)",
            " Document comptables = les livres et les registres comptables",
            " Pièces justificatives = Bons de Commande, factures Client et fournisseur, notes de frais",
            " Status et RI à conserver par ordre chronologique et à vie",
            " Déclaration en douanes à conserver 3 ans ",
            " Contrat d'assurance (Hors MAIF) à conserver 2 ans après la fin du contrat",
            " Dossier d'avocats à conserver (5 ans à partir de la fin du mandat de l'avocat)",
            " Faire attention au RGPD",
            " Vérifier régulièrement la conformité de la roue ",
          ],
          texte_if_all: "Bravo à toi tu es un génie",
          link_if_all:
            "https://docs.google.com/presentation/d/1Nuk0xAWRt1VpCjENFtEoXKJUuYKVm6btvSVDb-fGhW4/edit?usp=sharing",
          texte_link: "Cliquez ici",
          image :require("@/assets/roue_archivage.png")
        },
      };
    },
    methods: {
      goBack() {
        this.$router.go(-1);
      },
    },
  };
  </script>