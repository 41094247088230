<!-- DON'T TOUCH -->
<template>
    <div>
      <button class="return-button" @click="goBack">Retour</button>
      <TitleComponent :Title="Title" />
      <DiagramComponent
        :format="Format"
        :nodes="nodes"
        :linkData="linkData"
        :componentList="componentList"
      />
    </div>
  </template>
      
  
  
    <script>
  // DON'T TOUCH
  import DiagramComponent from "@/components/Diagram.vue";
  import TitleComponent from "@/components/TitlePage.vue";
  
  export default {
    name: "suivi_intervenantView", // Nom à changer
    data() {
      return {
        //Texte à remplir
        Title: {
          mainTitle:
            "Activité Commerciale",
          subTitle: "Suivi Intervenant",
          subSubTitle: "Processus : Suivi Intervenant",
          supervisor: "Activité Commerciale",
          derniere_modif: "02/04/2024 par Enzo CHAMPAGNE",
        },
  
        // Exemple des différents type de nœuds
        nodes: [
          {
            key: "0",
            text: "Pas d'Intervenant encore recruté",
            type: "Start", // Met en vert et de forme elliptique (genre ovale)
          },
          {
            key: "1",
            text: "Recrutement de l'Intervenant",
            link: "recrutement_intervenant",
            supervisor: "Pôle Activité Commerciale, Secrétaire Général, Trésorier",
            noLinkauto: true,
          },
          {
            key: "2",
            text: "Mettre l'intervenant dans la Base de Données",
            noLinkauto: true,
          },
          {
            key: "3",
            text: "Signature du RM correspondant",
          },
          { key: "4", text: "Etablir une communication régulière afin d'avoir les disponibilités pour les RDV Client et pour connaitre l'avancée de l'étude" },
          {
            key: "5",
            text: "Remplir la Base de Données pour le ressenti sur l'intervenant (à garder ou non)",
            noLinkauto: true,
          },
          { key: "6", text: "Envoyer le Questionnaire de Satisfaction" },
          {
            key: "7",
            text: "Fidélisation de l'Intervenant (Evénements, propositions pour les autres études ...)",
          },
          {
            key: "8",
            text: "Demander les informations nécessaires pour la Trésorerie afin d'effectuer le BV",
            supervisor: "Trésorier",
          },
          { key: "9", 
          text: "Fin du Suivi Intervenant", type: "End", },
  
  
          { key: "10", text: "Signature de l'ARRM", indPrec: 5, direction: "right", noLinkauto: true,},
        ],
  
        linkData: [
          {
            from: "0",
            to: "1",
            label: "Avant Etude",
          },
          {
            from: "1",
            to: "2",
            label: "Pendant Etude",
          },
          {
            from: "4",
            to: "5",
            label: "Fin de l'étude",
          },
          {
            from: "4",
            to: "10",
            label: "En cas d'abandon de l'étude",
          },
          {
            from: "10",
            to: "1",
            fromSpot: "bottom",
            toSpot: "right",
          }
        ],
        componentList: [
        ],
      };
    },
    components: {
      DiagramComponent,
      TitleComponent,
    },
    methods: {
      goBack() {
        this.$router.go(-1);
      },
    },
  };
  </script> 